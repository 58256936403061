import { LocalStorageKeys } from 'config/localStorageKeys';
import { LocalUserSettings } from 'types/localUserSettings';

const get = (): LocalUserSettings | undefined => {
  const data = localStorage.getItem(LocalStorageKeys.LOCAL_USER_SETTING);
  return data ? (JSON.parse(data) as LocalUserSettings) : undefined;
};

const set = (data: LocalUserSettings) =>
  localStorage.setItem(LocalStorageKeys.LOCAL_USER_SETTING, JSON.stringify(data));

const remove = () => localStorage.removeItem(LocalStorageKeys.LOCAL_USER_SETTING);

export const localUserSettings = { get, set, remove };
