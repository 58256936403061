import { SidebarItemPalette } from '@fattureincloud/fic-design-system';
import { colors as defaultPalette } from 'palette/colors';

const sidebarItemPalette: SidebarItemPalette = {
  normal: {
    color: defaultPalette.black[100],
    background: 'transparent',
    hoverColor: defaultPalette.black[100],
    hoverBackground: defaultPalette.grey[100],
    dropdownHover: defaultPalette.grey[400],
    dropdownActive: defaultPalette.grey[600]
  },
  active: {
    color: defaultPalette.white[100],
    background: defaultPalette.blue[500],
    hoverColor: defaultPalette.white[100],
    hoverBackground: defaultPalette.blue[500],
    dropdownHover: 'rgba(255,255,255,0.16)',
    dropdownActive: defaultPalette.blue[700]
  },
  disabled: {
    color: defaultPalette.grey[300],
    background: defaultPalette.grey[100],
    hoverColor: defaultPalette.grey[300],
    hoverBackground: defaultPalette.grey[100],
    dropdownHover: 'transparent',
    dropdownActive: 'transparent'
  }
};

export default sidebarItemPalette;
