import { CreateProvider, Provider } from 'types/provider';
import { providerFiltersQueryString, paginationQueryString, queryStringSearchFilters } from './utils';
import { APIManager } from 'apiManager';
import { GretchOptions } from 'gretchen';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { AdvancedFilters, EntityFilters, Id } from 'types';
import { PAGE_SIZE } from 'config/api';

export const getList = async (page: number, filters: EntityFilters) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, justResponse: true };
  const { searchText, advanced } = filters;
  const pagination = paginationQueryString(page, PAGE_SIZE, 'Descr');
  const baseFilters = queryStringSearchFilters(searchText);
  const fieldFilter = providerFiltersQueryString(advanced);

  const queryString = new URLSearchParams({
    ...Object.fromEntries(pagination),
    ...Object.fromEntries(baseFilters),
    ...Object.fromEntries(fieldFilter)
  });
  const url = `/api/fornitore?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

// Used on activity route
export const getProviders = async (searchQuery: string, advancedFilters: AdvancedFilters = {}, page = 1) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const pagination = paginationQueryString(page, PAGE_SIZE);
  const search = queryStringSearchFilters(searchQuery);
  const filter = providerFiltersQueryString(advancedFilters);
  const queryString = new URLSearchParams({
    ...Object.fromEntries(pagination),
    ...Object.fromEntries(search),
    ...Object.fromEntries(filter)
  });
  const url = `/api/fornitore?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Provider[]>(obj);
};

// just for search query
export const getSearchedProviders = async (searchText: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/fornitore?pageNumber=1&pageSize=${PAGE_SIZE}&searchQuery=${searchText}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Provider[]>(obj);
};

export const getProvider = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/fornitore/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Provider>(obj);
};

export const addProvider = async (newContact: CreateProvider) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newContact) };
  const url = '/api/fornitore';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Provider>(obj);
};

export const updateProvider = async (data: Provider) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const url = `/api/fornitore/${data.id}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Provider>(obj);
};

export const deleteProvider = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/fornitore/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
