import React, { Component } from 'react';
import { ScreenState } from 'types/mobile';
import { Props, connector } from './types';

class ScreenDetectorComponent extends Component<Props, ScreenState> {
  public state = {
    width: window.innerWidth,
    height: window.innerHeight
  };

  public componentDidMount() {
    window.addEventListener('resize', this.handleScreenResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleScreenResize);
  }

  private handleScreenResize = () => this.setState({ height: window.innerHeight, width: window.innerWidth });

  public componentDidUpdate(prevProps: Props, prevState: ScreenState) {
    const { width, height } = this.state;
    if (width !== prevState.width || height !== prevState.height) {
      this.props.ScreenResizeAction({ height, width });
    }
  }

  public render = () => <>{this.props.children}</>;
}

export const ScreenDetector = connector(ScreenDetectorComponent);
