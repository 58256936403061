import { Id } from 'types';
import { APIEnvironmentEnum } from './native';
import { UtenteArchivio } from './condominio';

export type Authorization = AccessToken & AccountVersion & RefreshToken & Username;

export type Username = { username: string };

export type AccessToken = { accessToken: string };
export type RefreshToken = { refreshToken: string };
export type FullCredential = Authorization & RefreshToken;
export type KeepMeLogged = { keepMeLogged: boolean };
export type AuthAPIResponse = { credentials: FullCredential } & KeepMeLogged;

export type LoginCredential = { CID: string; Username: string; Pwd: string };

export type Pid = { pid: number };

export type Operator = { operatore: string };

export type LocalStorageAuthData = Omit<Authorization, 'versione' | 'refreshToken'> &
  Partial<Pick<UtenteArchivio, 'login'>>;

export enum AccountTypeEnum {
  one = 'one',
  pro = 'pro' //mobile view allowed
}

type AccountVersion = { versione: AccountTypeEnum };
export type AccessManagement = { gestioneAccessi: boolean };
export type AccountPermissions = {
  utenteDisattivato: boolean;
  accAllegatiOn: boolean;
  accAttivitaOn: boolean;
  accCondominiOn: boolean;
  accContatoriOn: boolean;
  accContiOn: boolean;
  accExportAgendaContOn: boolean;
  accExportDatosOn: boolean;
  accFornitoriOn: boolean;
  accImpiantiOn: boolean;
  accModComunicOn: boolean;
  accMovNonRateOn: boolean;
  accMovRateOn: boolean;
  accOpzioniOn: boolean;
  accPassConsOn: boolean;
  accPersoneOn: boolean;
  accPianoRateOn: boolean;
  accTabelleOn: boolean;
  accUnitaOn: boolean;
  accVisCondominiOn: boolean;
};
export type AccessManagementData = Id & Pick<UtenteArchivio, 'login' | 'tipo'> & AccountPermissions;

export type AccountData = AccessManagement & AccountVersion & { gestioneAccessiData: AccessManagementData | undefined };
export type MobileLoginData = { token: string; release: APIEnvironmentEnum; sessionID: string };
