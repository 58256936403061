export enum ErrorsCodeEnum {
  INVALID_SELECTED_DB = 100,
  LOGIN_FAILED = 101,
  UNAUTHORIZED_INVALID_DB_PASSWORD = 102,
  UNAUTHORIZED_WRONG_DB_PASSWORD = 103,
  UNAUTHORIZED_INVALID_GESTACC_PASSWORD = 104,
  MUST_UPDATE_DB_REVISION = 105,
  NO_DB_CLAIMS_FOUND = 106,
  CONTROLLER_ERROR = 107,
  MISSING_PARAMS = 108,
  UNEXPECTED_ERROR = 109,
  WRONG_PARAMS = 110,
  UNAUTHENTICATED = 111,
  APPLICATION_ERROR = 112,
  VALIDATION_ERROR = 113,
  UNAUTHORIZED_RELEASE_TYPE = 114,
  BUSINESS_ERROR = 115,
  USER_ALREADY_REGISTERED = 116,
  FORBIDDEN = 120,
  DSPROVIDER_INEXISTENT = 201,
  CLOUD_CONTROLLER_TOKEN_CACHE_RETRIEVAL = 501,
  UNHANDLED_ERROR = 999
}
