import {
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFilePdf,
  faFileImage,
  faFileAlt
} from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  WORD_DOC_EXTENSIONS,
  EXCEL_DOC_EXTENSIONS,
  POWER_POINT_DOC_EXTENSIONS,
  PDF_DOC_EXTENSION,
  IMAGE_EXTENSIONS
} from 'utils/attachment';

//check if string ends with any of array suffixes
const endsWithExtensions = (extensions: string[], value: string) => {
  for (const suffix of extensions) {
    if (value.endsWith(suffix)) {
      return true;
    }
  }
  return false;
};

type ExtensionMapping = { extensions: string[]; icon: IconDefinition };

const EXTENSIONS_ICON_MAPPING: ExtensionMapping[] = [
  { extensions: WORD_DOC_EXTENSIONS, icon: faFileWord },
  { extensions: EXCEL_DOC_EXTENSIONS, icon: faFileExcel },
  { extensions: POWER_POINT_DOC_EXTENSIONS, icon: faFilePowerpoint },
  { extensions: PDF_DOC_EXTENSION, icon: faFilePdf },
  { extensions: IMAGE_EXTENSIONS, icon: faFileImage }
];

export const getAttachmentIcon = (fileName: string): IconDefinition => {
  for (const o of EXTENSIONS_ICON_MAPPING) {
    if (endsWithExtensions(o.extensions, fileName)) {
      return o.icon;
    }
  }
  return faFileAlt;
};
