import { GeneralActionNames } from './actions/general/types';
import { EConnectionStatuses, ConnectionStatus } from './reducers/generalReducer';
import { store } from './router';

export const connectionListener = () => {
  const connectionCallback = () => {
    const condition: ConnectionStatus = navigator.onLine ? EConnectionStatuses.online : EConnectionStatuses.offline;
    store.dispatch({ type: GeneralActionNames.SET_CONNECTION_STATUS, payload: condition });
  };

  window.addEventListener('online', connectionCallback);
  window.addEventListener('offline', connectionCallback);
};
