import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const cyan: color = {
  50: '#e4faf5',
  100: '#c6f5eb',
  200: '#a4efe0',
  300: '#7de8d3',
  400: '#4de0c2',
  500: '#0dd5ad',
  600: '#0bc09c',
  700: '#0aa989',
  800: '#088c72',
  900: '#066552'
};
