import { IconPalette } from '@fattureincloud/fic-design-system/dist/components/icon/iconPalette';
import { colors } from '@fattureincloud/fic-design-system/dist/styles/types';
import { colors as defaultPalette } from 'palette/colors';

const getColoredIcon = (color: colors) => ({
  primary: defaultPalette[color][500],
  secondary: defaultPalette[color][200]
});

const iconPalette: IconPalette = {
  blue: getColoredIcon('blue'),
  red: getColoredIcon('red'),
  green: getColoredIcon('green'),
  yellow: getColoredIcon('yellow'),
  grey: getColoredIcon('grey'),
  orange: getColoredIcon('orange'),
  primary: getColoredIcon('primary'),
  cyan: getColoredIcon('cyan'),
  fuchsia: getColoredIcon('fuchsia'),
  indigo: getColoredIcon('indigo'),
  pink: getColoredIcon('pink'),
  white: {
    primary: defaultPalette.white[100],
    secondary: defaultPalette.white[48]
  },
  black: {
    primary: defaultPalette.black[100],
    secondary: defaultPalette.black[48]
  }
};

export default iconPalette;
