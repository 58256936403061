import { AccountData, Authorization, LocalStorageAuthData } from 'types/account';

/**
 * Action type names
 */
export enum AuthActionNames {
  SET_AUTHORIZATION_DATA = 'SET_AUTHORIZATION_DATA',
  LOGOUT = 'LOGOUT',
  RESET_LOGIN_STATE = 'RESET_LOGIN_STATE',
  SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN',
  SET_ACCOUNT = 'SET_ACCOUNT'
}

/**
 * Action typings
 */
export type SetAuthorizationData = { type: typeof AuthActionNames.SET_AUTHORIZATION_DATA; payload: Authorization };

export type SetLoginToken = { type: typeof AuthActionNames.SET_LOGIN_TOKEN; payload: LocalStorageAuthData };

export type Logout = { type: typeof AuthActionNames.LOGOUT; payload: string };

export type ResetLoginState = { type: typeof AuthActionNames.RESET_LOGIN_STATE };

export type SetAccount = { type: typeof AuthActionNames.SET_ACCOUNT; payload: AccountData };

/**
 * Action output typings aggregator
 */
export type AuthActionTypes = SetAuthorizationData | SetLoginToken | Logout | ResetLoginState | SetAccount;
