import { store } from 'router';
import { RootState } from 'reducers/rootReducer';
import { HeaderConfig } from 'apiManager/types';
import { DeployEnvironmentEnum, deployEnvironment } from './deploy';

export const PAGE_SIZE = 15;

const PROD_URL = 'https://domustudioapi.danea.it';
const BETA_URL = 'https://beta-domustudioapi.danea.it';
const DEV_URL = 'https://dev-domustudioapi.danea.it';

const getUrl = (branch: string) => {
  switch (branch) {
    case DeployEnvironmentEnum.Production:
      return PROD_URL;
    case DeployEnvironmentEnum.Beta:
      return BETA_URL;
    default:
      return DEV_URL;
  }
};

export const DOMUSTUDIOAPI_BASE_PATH = getUrl(deployEnvironment);

// TODO: make parameter mandatory
export const getAuthorizationHeader = (config?: HeaderConfig) => {
  const { accessToken, archiveId, avoidArchiveId, avoidContentTypeApplicationJson, avoidAuthorization } = config ?? {};
  const requestHeaders: { [key: string]: string } = {};
  const {
    authReducer: { accessToken: reduxAccessToken },
    generalReducer: { currentDb: reduxCurrentDb }
  } = store.getState() as RootState;

  if (!avoidAuthorization) {
    requestHeaders['authorization'] = `Bearer ${accessToken ?? reduxAccessToken}`;
  }
  if (archiveId) {
    requestHeaders['x-danea-domustudiodb'] = archiveId;
  }
  if (!avoidArchiveId && reduxCurrentDb) {
    requestHeaders['x-danea-domustudiodb'] = reduxCurrentDb.id;
  }
  if (!avoidContentTypeApplicationJson) {
    requestHeaders['content-type'] = 'application/json';
  }
  return requestHeaders;
};
