import { useEffect } from 'react';

type Props = { cleanup: () => void };

// This is a workaround for call componentWillUnmount when drawer is about to be removed from the DOM.
export const ReduxCleaner = ({ cleanup }: Props) => {
  // just use the "return" on "useEffect"
  useEffect(() => () => cleanup(), [cleanup]);
  return null;
};
