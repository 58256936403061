import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const orange: color = {
  50: '#ffefea',
  100: '#ffded3',
  200: '#ffcab9',
  300: '#ffb49b',
  400: '#ff9978',
  500: '#ff7649',
  600: '#e66a42',
  700: '#ca5d39',
  800: '#a74d2f',
  900: '#783722'
};
