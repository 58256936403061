export enum iPhoneModels {
  notiPhone = 'Not an iPhone',
  Standard13G3GS = 'iPhone 1, 3G or 3GS',
  Standard44s = 'iPhone 4 or 4s',
  Standard55C5sSE66s78Zoom = 'iPhone 5/5C/5s/SE or 6/6s/7 and 8 (display zoom)',
  Standard678 = 'iPhone 6, 6s, 7 or 8',
  Plus678Zoom = 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus (display zoom)',
  Plus678 = 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus',
  X = 'iPhone X',
  newest = 'newest Iphones'
}

export const getiPhoneModel = () => {
  const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  if (iOS) {
    const diff = 0.01;
    // iPhone 6+/6s+/7+ and 8+
    if (Math.abs(window.screen.height / window.screen.width - 736 / 414) < diff && window.devicePixelRatio === 3) {
      return iPhoneModels.Plus678;
    }

    // iPhone 6+/6s+/7+ and 8+ in zoom mode
    if (Math.abs(window.screen.height / window.screen.width - 667 / 375) < diff && window.devicePixelRatio === 3) {
      return iPhoneModels.Plus678Zoom;
    }

    // iPhone 6/6s/7 and 8
    if (Math.abs(window.screen.height / window.screen.width - 667 / 375) < diff && window.devicePixelRatio === 2) {
      return iPhoneModels.Standard678;
    }

    // iPhone 5/5C/5s/SE or 6/6s/7 and 8 in zoom mode
    if (Math.abs(window.screen.height / window.screen.width - 1.775) < diff && window.devicePixelRatio === 2) {
      return iPhoneModels.Standard55C5sSE66s78Zoom;
    }

    // iPhone 4/4s
    if (Math.abs(window.screen.height / window.screen.width - 1.5) < diff && window.devicePixelRatio === 2) {
      return iPhoneModels.Standard44s;
    }

    // iPhone 1/3G/3GS
    if (Math.abs(window.screen.height / window.screen.width - 1.5) < diff && window.devicePixelRatio === 1) {
      return iPhoneModels.Standard13G3GS;
    }

    // iPhone X
    // if (window.screen.height / window.screen.width === 812 / 375 && window.devicePixelRatio === 3) {
    //   return iPhoneModels.X;
    // }
    return iPhoneModels.newest;
  }

  return iPhoneModels.notiPhone;
};
