import { Validator } from 'utils/formValidation/types';
import { Id, PageContext } from 'types';
import { PageStatusEnum } from 'types/helpers';
// import { AttachmentNumber } from './registry';

export enum PersonTypeEnum {
  'contact' = 1,
  'provider'
}

export enum EntityTypeEnum {
  'contact' = 1,
  'provider',
  'activity',
  'condomini',
  'commonParts-Environment',
  'commonParts-Implant',
  'instalment'
}

export type EntityId = { entityId: number | undefined };
export type EntityDetails = { id: number; type: EntityTypeEnum; extrafields?: Record<string, unknown> };

export type PageStatus = { pageStatus: PageStatusEnum };

export type PersonTableData = Id & { descr: string; tel: string; email: string };
// & AttachmentNumber;

export type Note = { note: string };

export type Location = { cap?: string; citta?: string; prov?: string };
export type EntityLocation = { indirizzo: string; nazione?: string } & Required<Location>;

export type Bank = { banca: string; iban: string; bic: string };

export type Description = { descrizione?: string };

/**
 * Entity GET list
 */
export type EntityListWithRouteContext<T> = { list: T[]; pageContext: PageContext };

/**
 * Entity details
 */
export type LastPropertyUpdated = { lastPropertyUpdated: string[] | undefined };

// Utils
export type DataValue = {
  value: string;
  descr: string;
  icon: string;
  validators: Validator[];
};
