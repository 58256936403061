import * as activities from './activitiesSelector';
import { authSelector } from './auth';
import * as commonParts from './commonPartsSelector';
import * as condominiSelector from './condominiSelector';
import * as contactsSelector from './contactsSelector';
import * as generalSelector from './generalSelector';
import * as instalmentSelector from './instalmentSelector';
import { localUserSettings } from './localUserSettings';
import * as providerSelector from './providersSelector';

import { modalSelector } from './modal';

export const selectors = {
  activities,
  authSelector,
  commonParts,
  condominiSelector,
  contactsSelector,
  instalmentSelector,
  generalSelector,
  localUserSettings,
  modalSelector,
  providerSelector
};
