import React from 'react';
import style from './headerDetailsDrawer.module.scss';
import { IconWithBackground } from 'design-component/iconWithBackground';
import { ICON_FORNITORE, ICON_PERSONA } from 'constants/icon';

export const personHeader = (name: string, isContact: boolean) => () =>
  (
    <div className={style.container}>
      <div className="d-flex align-items-center">
        <IconWithBackground
          icon={isContact ? ICON_PERSONA : ICON_FORNITORE}
          color="blue"
          size="1x"
          overrideBackgroundIntensity={100}
        />

        <p className="ml-2 font--grey">{name}</p>
      </div>
    </div>
  );
