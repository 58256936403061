/* eslint-disable @typescript-eslint/camelcase */

const translations = {
  it: {
    newVersionAvailable: 'Nuova versione disponibile!',
    newVersionAvailableContent: 'Clicca per aggiornare Domustudio Cloud GO alla nuova versione.',
    update: 'Aggiorna',
    somethingWentWrong: 'Qualcosa è andato storto nella visualizzazione della pagina. Riprova tra qualche istante.',
    tryResetError: "Prova a ripristinare l'errore",

    //Login
    checkYourEmail: 'Controlla la tua casella di posta',
    noRegisteredEmailFound: "L'indirizzo email che hai inserito non ha account collegati.",
    keepLogin: 'Resta connesso',
    // Home
    archive: 'Archivio',
    archivePassword: "Password dell'archivio:",
    newArchive: 'Nuovo Archivio',
    dbNotUpdated: 'Archivio non aggiornato',
    dbNotUpdatedMessage:
      "L'attuale versione dell'archivio non è aggiornato all'ultima versione.\nEffettua l'aggiornamento per continuare ad utilizzare l'archivio selezionato.",
    inCaseOfDbNotUpdatedTip: 'In caso di archivio non aggiornato',
    loginSessionExpired: 'Sessione di autenticazione scaduta',

    // Activity
    activities: 'Attività',
    createActivity: 'Crea Attività',
    titleDetailsModalActivity: 'Dettagli attività',
    invalidDate: 'La data inserita non è valida',
    noPastDateAllowed: 'La data non può essere nel passato',
    noFutureDateAllowed: 'La data non può essere nel futuro',
    toastErrorActivityCreation: "Errore durante la creazione dell'attività",
    toastErrorActivityDelete: "Errore durante l'eliminazione dell'attività",
    toastSuccessActivityDelete: 'Attività eliminata con successo',
    toastErrorActivityAttachments: "C'è stato un problema e non è stato possibile recuperare gli allegati",
    selectApplicantFirst: 'Devi prima selezionare il richiedente',
    selectCondominiumn: 'Seleziona un condominio',
    management: 'Gestione',
    details: 'Dettagli',
    events: 'Eventi',
    intervention: 'Intervento',
    systemEventInformation: 'il <strong>{0}</strong> alle <strong>{1}</strong>',
    noOneCondominiumConnected: 'Nessun condominio',
    condominiumsConnected: '{0} condomini',
    editEventWaringBlockTitle: 'Stai aggiungendo informazioni nuove?',
    editEventWaringBlockContent:
      "Se si, ti consigliamo di creare una nuova nota: sarà più facile tenere traccia dell'evoluzione dell'attività",
    createNewNote: 'Crea una nuova nota',
    sector: 'Settore',
    searchSector: 'Cerca settore',
    providerOfSector: 'Fornitore del settore {0}',
    addAnotherIntervention: 'Aggiungi un altro intervento',
    facility: 'Parte comune',
    remindInAgenda: 'Ricordamelo in agenda',
    noContactsProvider: 'Non ci sono contatti per questo fornitore.',
    providersContacts: 'Contatti',
    ActivityStateOpen: 'Pratica aperta',
    ActivityStateClosed: 'Pratica chiusa',
    ActivityStateSuspended: 'Pratica sospesa',
    lastUpdateInterventionDate: 'Ultimo agg. interventi',
    referent: 'Referente',
    selectProviderEmailForAssignment: "Seleziona l'indirizzo email per l'invio dell'incarico al fornitore:",
    noProviderEmail: 'Il fornitore non ha indirizzi email inseriti',
    warningNoProviderEmail:
      'Senza un indirizzo email, non sarà possibile inviare la richiesta di incarico al fornitore.',
    // Common parts
    commonParts: 'Parti comuni',
    implantDetails: 'Dettagli impianto',
    // Condomini
    condominiums: 'Condomini',
    // Contact
    contacts: 'Persone',
    searchForContact: 'Cerca contatto',
    createContact: 'Crea persona',
    createNewContact: 'Crea una nuova persona',
    titleDetailsModalContact: 'Dettagli persona',
    createNewContactConfirmMessage: 'Vuoi creare questo nuovo contatto?',
    toastErrorContactCreation: 'Errore durante la crezione del contatto',
    toastErrorContactDelete: "Errore durante l'eliminazione del contatto",
    toastSuccessContactDelete: 'Persona eliminata con successo',
    toastErrorGeneralInlineEdit: "C'è stato un problema e non è stato possibile salvare la modifica",

    // Contact form
    contactGeneralData: 'Dati generali della persona',

    // Provider
    providers: 'Fornitori',
    provider: 'Fornitore',
    searchForProvider: 'Cerca fornitore',
    noOneProvider: 'Non ci sono fornitori',
    createNewProvider: 'Crea fornitore',
    titleDetailsModalProvider: 'Dettagli fornitore',
    toastErrorProviderCreation: 'Errore durante la creazione del fornitore',
    toastErrorProviderDelete: "Errore durante l'eliminazione del fornitore",
    toastSuccessProviderDelete: 'Fornitore eliminato con successo',
    holderOrLegalRepresentative: 'Titolare o legale rappresentante',
    contactMioCondominioActive:
      "MioCondominio è <strong>attivo</strong> per <strong>{0}</strong>, con l'email <strong>{1}</strong>",
    contactMioCondominioDisabled: 'MioCondominio è <strong>disattivo</strong> per <strong>{0}</strong>',
    MioCondominioActive: 'MioCondominio è <strong>attivo</strong>',
    MioCondominioDisabled: 'MioCondominio è <strong>disattivo</strong>',
    addProvider: 'Aggiungi fornitore',

    // Provider form
    includeACUnico: 'Includere nel quadro AC/Unico - K730',
    withHoldingTaxUsage: "Applicazione ritenuta d'acconto (cod. tributo)",
    causalSumsPaid: 'Causale somme erogate (mod CU/770)',
    termsOfPayment: 'Modalità di pagamento',
    taxDomicile: 'Domicilio fiscale',
    notSetted: 'Non impostato',
    durcMessageValidWillExpire: 'Presente, scade il {0}',
    durcMessageValidExpired: 'Presente, ma scaduto il {0}',
    durcMessageMissing: 'Assente',
    durcMessageRequired: 'Richiesto il {0}',
    durcMessageExpired: 'Presente con scadenza',
    providerActivityType: 'Tipo di attività svolta',
    exportGoogleOutlookEmail: 'Abilita esportazione su Google/MS Outlook',
    editExportGoogleOutlook:
      'Attivando questa opzione, il fornitore <strong>{0}</strong> verrà incluso nella funzione "Esporta Fornitori"',
    enableAutomaticInclusion: 'Attiva inclusione automatica',
    mioCondominioNoFieldsInserted: '- nessun campo inserito -',
    mioCondominioNoNoteInserted: '- nessuna nota inserita -',

    // Interface
    delete: 'Elimina',
    denomination: 'Denominazione',
    telephone: 'Telefono',
    cell: 'Cellulare',
    email: 'Email',
    nobody: 'Nessuno',
    open: 'Aperto',
    condominium: 'Condominio',
    object: 'Oggetto',
    priority: 'Priorità',
    started: 'Data apertura',
    name: 'Nome',
    pec: 'PEC',
    fieldFax: 'Fax',
    fax: 'Fax',
    durc: 'DURC',
    manage: 'Gestione',
    other: 'Altro',
    chooseDatabase: "Seleziona l'archivio di lavoro:",
    noDatabase: 'Non ci sono archivi di lavoro',
    returnToDomuToAddArchives:
      "Usa Domustudio Cloud per creare un archivio di lavoro. Una volta creato, ritorna e goditi tutte le funzionalità offerte dall'app.",
    goToCloud: 'Vai al Cloud',
    registeredMail: 'Raccomandata',
    priorityMail: 'Prioritaria',
    payments: 'Pagamenti',
    generalData: 'Dati generali',
    otherData: 'Altri dati',
    attachment: 'Allegato',
    attachments: 'Allegati',
    seeAttachments: 'Vedi allegati',
    createDossier: 'Crea fascicolo',
    businessName: 'Ragione sociale',
    address: 'Indirizzo',
    correspondenceAddress: 'Indirizzo di corrispondenza',
    present: 'Presente',
    absent: 'Assente',
    required: 'Richiesto',
    expiresOn: 'scade il',
    expiredOn: 'scaduto il',
    onDate: 'in data',
    empty: 'Vuoto',
    problem: 'Problema',
    attachmentRemoved: 'Allegato rimosso',
    attachmentErrorWhileRemoved: "Errore durante l'eliminazione dell'allegato",
    uploadAttachment: 'Carica allegato',
    uploadAttachments: 'Carica allegati',
    chooseAttachments: 'Scegli gli allegati da caricare',
    takeAPicture: 'Scatta foto',
    message: 'Messaggio',
    insertMessage: 'Inserisci il testo del messaggio',
    operator: 'Gestore',
    reduce: 'Riduci',
    expand: 'Espandi',

    // Field label
    fieldNumeroPratica: 'Numero pratica',
    fieldCondGenID: 'Condominio',
    fieldPriorita: 'Priorità',
    fieldTitolo: 'Titolo',
    fieldStato: 'Stato',
    fieldDescr: 'Denominazione',
    fieldIndirizzo: 'Indirizzo',
    fieldLinguaComunicazioni: 'Lingua',
    fieldCap: 'Cap',
    fieldCitta: 'Città',
    fieldProv: 'Provincia',
    fieldNazione: 'Nazione',
    fieldCodFisc: 'Codice fiscale',
    fieldPiva: 'Partita IVA',
    fieldTel1: 'Tel1',
    fieldTel2: 'Tel2',
    fieldTel3: 'Tel3',
    fieldEmail: 'Email',
    fieldPec: 'Pec',
    fieldIban: 'Iban',
    fieldBic: 'Bic',
    fieldBank: 'Banca',
    fieldFornitoreReferente: 'Fornitore',
    fieldIndirizzoWeb: 'Indirizzo web',
    fieldSpedIndirizzo: 'Indirizzo comunicazioni',
    fieldSpedDesc: 'Recapito',
    fieldCategoriaSpediz: 'Categoria spedizione',
    fieldPreferenzaPagamentoRate: 'Preferenza pagamento rate',
    fieldEsportaGoogleOutlook: 'Sincronizzazione email',
    fieldRichiedenteID: 'Richiedente',
    fieldOggetto: 'Titolo',
    fieldDescrizione: 'Descrizione',
    fieldGestore: 'Operatore di gestione',
    fieldTipoSegnalazID: 'Tipologia',
    fieldDataApertura: 'Data presentazione problema',

    // Placeholders
    descrPlaceholder: 'Cognome Nome',
    descrProviderPlaceholder: 'Ragione sociale',
    indirizzoPlaceholder: 'Via/Vicolo/Piazza/etc...',
    titoloPlaceholder: "Es. Infiltrazione d'acqua",
    descrizionePlaceholder: 'Breve descrizione del problema',

    // telephone options
    telOffice: 'Ufficio',
    telWork: 'Lavoro',
    telHome: 'Casa',
    telShop: 'Negozio',

    // Empty sets
    noFilterResultsFoundTitle: 'La ricerca non ha prodotto risultati',
    noSearchResultsFoundText: 'Non ci sono risultati per "{0}".',
    noFilterResults: 'Non ci sono risultati con i filtri selezionati...',
    cleanAllFilters: 'Cancella filtri e ricerca',
    cleanAdvancedFilters: 'Cancella tutti i filtri',
    cleanSearchFilter: 'Cancella la ricerca',
    // contacts
    contactsEmptySetTitle: 'Crea la tua prima persona',
    contactsEmptySetText:
      'Inserendo le persone relative ai condomini, Domustudio potrà iniziare subito ad aiutarti nel tuo lavoro di amministratore di condominio',
    contactsEmptySetActionText: 'Crea persona',
    // providers
    providersEmptySetTitle: 'Crea il tuo primo fornitore',
    providersEmptySetText:
      'Inserendo le persone relative ai condomini, Domustudio potrà iniziare subito ad aiutarti nel tuo lavoro di amministratore di condominio',
    providersEmptySetActionText: 'Crea fornitore',
    // activities
    activitiesEmptySetTitle: 'Crea subito la tua prima attività',
    openActivitiesEmptySetTitle: 'Non c’è alcuna attività aperta',
    closedActivitiesEmptySetTitle: 'Non c’è alcuna attività chiusa',
    suspendedActivitiesEmptySetTitle: 'Non c’è alcuna attività sospesa',
    activitiesEmptySetText:
      'Con le attività potrai gestire facilmente tutte le operazioni per i tuoi condomini, registrare tutte le informazioni utili e tenere traccia degli interventi dei fornitori.',
    closedActivitiesEmptySetText:
      'Quando un’attività viene risolta, puoi chiuderla in modo da tenere tutto più ordinato. Non preoccuparti, la conserveremo qui per te nel caso ne avessi bisogno!',
    suspendedActivitiesEmptySetText:
      'Quando un’attività non può essere portata avanti, puoi sospenderla. Le attività sospese possono essere riprese in ogni momento!',
    // filterActivitiesEmptySetText: 'Non ci sono risultati con i filtri selezionati tra le attività...',
    // searchTextActivitiesEmptySetText: 'Non ci sono risultati per "{0}" tra le attività...',
    activitiesEmptySetActionText: 'Crea attività',
    openedActivities: 'Attività aperte',
    closedActivities: 'Attività chiuse',
    suspendedActivities: 'Attività sospese',
    backToOpenActivities: 'Torna alle attività aperte',
    activitiesToBeConcludedBy: 'Attività da concludere entro',
    endDate: 'Concludere entro',

    notices: 'Avvisi',
    withNotices: 'Con avvisi',
    withoutNotices: 'Senza avvisi',
    withoutNoticiesActivity: 'Attività senza avvisi',
    filterBycondominium: 'Filtra per condominio',
    datesFromTo: 'Filtra per ',
    filterBypriority: 'Filtra per priorità',
    filterByprovider: 'Filtra per fornitore',
    filterBytypology: 'Filtra per tipologia',
    filterByapplicant: 'Filtra per richidente',
    filterBymanager: 'Filtra per operatore',
    filterBydatesFromTo: 'Filtra data apertura',
    filterBynotice: 'Filtra avvisi',
    addActivityProviderWarning:
      "Aggiungendo il fornitore verrà creato anche il suo primo intervento. Puoi decidere di inviare già la richiesta al fornitore. Non preoccuparti, potrai contattarlo anche in seguito per decidere la data in cui effettuerà il lavoro oppure cancellare l'intervento.",
    providerPortalState_0: 'Incarico da assegnare',
    providerPortalState_1: 'Richiesta di incarico inviata',
    providerPortalState_2: 'Incarico accettato',
    providerPortalState_3: 'Incarico rifiutato',
    providerPortalState_4: 'Incarico annullato',
    providerPortalState_5: 'Incarico concluso positivamente',
    providerPortalState_6: 'Incarico concluso non risolutivo',

    // activities intervention
    interventionTitleInTime: 'Intervento previsto',
    interventionTitleNoPrediction: ' Intervento non definito',
    interventionTitleLate: 'Intervento in ritardo',
    interventionTitleDone: 'Intervento eseguito',
    interventionSubTitleInTime: 'Tra {0} giorni • {1}',
    interventionSubTitleInTimeLastDay: 'Oggi • {0}',
    interventionSubTitleNoPrediction: "Non c'è una data prevista",
    interventionSubTitleLate: 'Previsto {0} giorni fa • {1}',
    interventionSubTitleDone: '{0} giorni fa • {1}',
    interventionSubTitleDoneToday: 'Oggi • {0}',
    interventionSubTitleDoneYesterday: 'Ieri • {0}',
    markAsDone: 'Segna come svolto',
    doneDate: 'Data svolgimento',
    expectedDate: 'Data prevista intervento',
    newExpectedDate: 'Nuova data prevista',
    originalExpectedDate: 'Data prevista attuale',
    changeDateCause: 'Causa cambio data',
    setExpectedDate: 'Imposta data prevista',
    addIntervention: 'Aggiungi intervento',
    editIntervention: 'Modifica intervento',
    searchForActivities: 'Cerca attività',
    interventionRelatingNote: "Note relative all'intervento",
    changeInterventionDate: 'Cambia data prevista',
    changeInterventionDateRelatingNote: "Causa che motiva il cambio data dell'intervento",
    viewTheActivity: 'Visualizza l’attività →',
    xHasBeenCreatedButIsNotVisible:
      '{0} è stata creata, ma non è visibile in questa lista a causa dello stato e/o dei filtri attivati.',
    activityHasBeenCreated: "L'attività {0} è stata creata",
    sendAssignmentSentToProvider: "Richiesta d'incarico da inviare al fornitore",
    sendAssignment: 'Invia richiesta incarico',
    cancelAssignment: 'Annulla incarico',
    sollecitAssignment: 'Sollecita incarico',
    replayAssignment: 'Riproponi incarico',
    sendAssignmentIntegration: 'Invia Integrazione',
    sendAssignmentIntegrationToProvider: "Invia la richiesta d'incarico al fornitore",
    assignmentTitle: 'Titolo incarico',
    assignmentDescription: 'Descrizione incarico',
    motivation: 'Motivazione',
    cancelAssignmentRelatingNote: "Causa che motiva l'annulamento dell'incarico",
    sollecitAssignmentRelatingNote: "Nota di sollecito dell'incarico",

    // Intervention messages
    interventionMessagesLostList: 'Elenco degli interventi spediti ai fornitori non presenti in archivio',
    // Providers table
    customizationsTableColumns: 'Personalizza le colonne',

    // Common
    admin: 'Amministratore',
    noneF: 'Nessuna',
    noneM: 'Nessuno',
    text: 'Testo',
    login: 'Accedi',
    loginAs: 'Accedi con utente:',
    CID: 'CID',
    user: 'Utente',
    username: 'Nome utente',
    password: 'Password',
    confirmPassword: 'Ripeti la password',
    didYouForgetPassword: 'Hai dimenticato la password?',
    forgotPassword: 'Password dimenticata?',
    accountMissing: 'Non hai un account?',
    signUp: 'Registrati',
    registrationEmail: 'Con quale email sei registrato?',
    resetPasswordInstructions: 'Ti invieremo a questo indirizzo le istruzioni per ripristinare la password.',
    resetPassword: 'Ripristina la password',
    backToLogin: 'Torna al login',
    alreadyHaveAccount: 'Ho già un account',
    cancel: 'Annulla',
    remove: 'Rimuovi',
    cancelFilter: 'Annulla filtro',
    emptyDatePlaceholder: 'gg/mm/aaaa',
    lookingFor: 'Ricerca: {0}',
    // Specific cancelFilter translations
    cancelFilterMobilecondominium: 'Tutti i condomini',
    accessNotAllowed: 'Accesso non consentito',
    notAllowedToUseApp:
      'Il tuo account è di tipo: "{0}".\nL\'accesso a questa funzione è riservata agli account Domustudio Cloud Pro.',
    cancelSelection: 'Annulla selezione',
    applyFilter: 'Applica filtro',
    registerNow: 'Registrati subito!',
    signUpContent:
      'Verrai indirizzato nel nostro Cloud per effettuare la registrazione.\nTorna poi a trovarci nel nostro nuovo Domustudio Cloud Go.',
    lastAccess: 'ultimo accesso ',
    noAccess: 'Nessun accesso ',
    setAsDefault: 'Imposta come default',
    exit: 'Esci',
    utilize: 'Utilizza',
    use: 'Usa',
    step: 'Passo',
    of: 'di',
    next: 'Avanti',
    previous: 'Indietro',
    create: 'Crea',
    search: 'Cerca',
    communications: 'Comunicazioni',
    preferentialSending: 'Invio preferenziale',
    sendCommunicationsTo: 'Invia comunicazioni presso',
    sendIfCommunicationAddressIsDifferentFromMainAddress:
      'Invia comunicazioni ad indirizzo differente da quello principale.',
    telephones: 'Telefoni',
    today: 'Oggi',
    yesterday: 'Ieri',
    save: 'Salva',
    backToResearch: 'Torna alla ricerca',
    isAlsoShippingAddress: 'È anche indirizzo di corrispondenza',
    isAlsoFiscalAddress: 'È anche domicilio fiscale',
    fiscal: 'Fiscale',
    backToGeneralData: 'Torna a dati generali',
    number: 'Numero',
    label: 'Etichetta',
    surname: 'Cognome',
    bornDate: 'Data di nascita',
    birthPlace: 'Comune (o stato estero) di nascita',
    italianMunicipalities: 'Comuni italiani',
    foreignStates: 'Stati esteri',
    web: 'Web',
    error: 'Errore',
    edit: 'Modifica',
    communicationAddress: 'Indirizzo di corrispondenza',
    fiscalAddress: 'Indirizzo di domicilio fiscale',
    andOneMore: 'e un altro',
    andXMore: 'e altri {0}',
    shippingCatGenerals: 'Generali',
    shippingCatFault: 'Guasti',
    shippingCatMeetings: 'Assemblee',
    betaVersion: 'Versione Beta',
    backToStandardVersion: 'Torna alla versione normale',
    changeArchive: 'Cambio archivio',
    furtherInformation: 'Ulteriori informazioni',
    noMatchFound: 'Nessuna corrispondenza trovata',
    news: 'Novità',
    noAttachments: 'Non ci sono allegati',
    typology: 'Tipologia',
    fromDate: 'Dal',
    toDate: 'Al',
    photos: 'Fotografie',
    otherLessRecentPhotos: 'Altre {0} immagini meno recenti',
    documentsAndOtherFiles: 'Documenti e altri file',
    download: 'Scarica',
    actionNotAvailable: 'Azione non disponibile',
    close: 'Chiudi',
    editDone: 'Modifica effettuata',
    editFailed: 'Modifica annullata',
    changeDate: 'Cambio data',
    preview: 'Anteprima',
    useThisAttachment: 'Usa questo allegato',
    freeFields: 'Campi liberi',

    // Modal
    pidNotUpdatedMsg: "Per usare questa funzione è necessario che l'archivio sia pubblicato su MioCondominio!",
    tooltipPidNotUpdatedMsg: "Per usare questa funzione è necessario che l'archivio sia pubblicato su MioCondominio!",
    // Toast
    toastErrorDownloadAttachment: "Errore durante il download dell'allegato",
    toastErrorPreviewAttachment: "Errore durante il caricamento dell'anteprima",
    toastSuccessDownloadAttachment: "Download dell'allegato avviato correttamente",
    toastGenericSuccessMsg: 'Operazione effettuata con successo',
    toastGenericFailureMsg: 'Operazione non effettuata',
    toastSuccessMsg_putActivity: 'Modifica effettuata',
    toastSuccessMsg_putActivityManager: 'Operatore modificato',
    toastSuccessMsg_putActivityState: "Stato dell'attività modificato",
    toastSuccessMsg_addActivityEvent: 'Nota aggiunta',
    toastSuccessMsg_putActivityEvent: 'Nota modificata',
    toastSuccessMsg_deleteActivityEvent: 'Nota eliminata',
    toastSuccessMsg_addInterventionAndSendToProvider: 'Intervento aggiunto ed inviato al fornitore',
    toastSuccessMsg_addIntervention: 'Intervento aggiunto',
    toastSuccessMsg_putActivityIntervention: 'Intervento modificato',
    toastSuccessMsg_deleteActivityIntervention: 'Intervento eliminato',
    toastSuccessMsg_setActivityInterventionDone: 'Intervento completato',
    toastSuccessMsg_putActivityInitialDescription: 'Descrizione iniziale modificata',
    toastSuccessMsg_changeActivityInterventionDate: 'Data intervento cambiata',
    toastSuccessMsg_setActivityInterventionData: "Data dell'intervento aggiunta",
    toastSuccessMsg_sendActivityAssignment: 'Richiesta di incarico inviata',
    toastSuccessMsg_cancelActivityAssignment: 'Richiesta di incarico annullata',
    toastSuccessMsg_sollecitActivityAssignment: 'Incarico sollecitato',
    toastSuccessMsg_replayActivityAssignment: "Stato dell'incarico ripristinato",
    toastSuccessMsg_sendActivityAssignmentIntegration: 'Messaggio inviato',

    // CommonParts
    contract: 'Contratto',
    periodicIntervention: 'Intervento periodico',
    notes: 'Note',

    // Details
    detailsPaymentPreferenceRate: 'Preferenza pagamento rate',
    detailsHeadlineTitleCommunications: 'Titolo intestazione comunicazioni',
    detailsReceiveCommunicationsWithLabel: 'Ricevi comunicazioni con etichetta',
    detailsRecommendedCommunications: 'Comunicazioni raccomandate',
    detailsSendCommunicationsTo: 'Invia comunicazioni presso',
    detailsPriorityCommunications: 'Comunicazioni prioritarie',
    mioCondominio: 'MioCondominio',
    activateMioCondominio: 'Attiva MioCondominio',
    providerMioCondominioActive:
      'MioCondominio è <strong>attivo</strong> per il fornitore <strong>{0}</strong>, i tuoi condòmini lo vedranno su MioCondominio',
    providerMioCondominioDisabled:
      'MioCondominio è <strong>disattivo</strong> per il fornitore <strong>{0}</strong>, i tuoi condòmini non lo vedranno su MioCondominio',
    resendCredentials: 'Reinvia credenziali',
    tooltipResendCredentials: "{0} riceverà una email con le istruzioni per reimpostare l'account.",
    missingCodFiscMessageTitle: 'Codice fiscale non inserito',
    fieldErrorWrongCodFisc: 'Il codice fiscale non corrisponde',
    spedDesc: 'Descrizione',
    spedIndirizzo: 'Indirizzo',
    spedCap: 'CAP',
    spedCitta: 'Città',
    spedProv: 'Provincia',
    spedNazione: 'Nazione',
    holder: 'Titolare o legale rappresentante',
    exportGoogleOutlookActive: 'Esportazione su Google/MS Outlook attivata',
    exportGoogleOutlookDeactivate: 'Esportazione su Google/MS Outlook disattivata',
    howToPay: 'Come pagare',
    syncActiveTooltipMessage: 'Il fornitore {0} verrà sincronizzato tra i contatti Google/MS Outlook',
    fieldsShown: 'Campi mostrati',
    notesShown: 'Note mostrate',
    excludedFromACUnicoTitle: 'Escluso dal quadro AC/Unico - K730',
    entityIncludedInFromACUnicoTitle: '{0} è incluso nella compilazione del quadro AC/Unico - K730',
    excludedFromACUnicoText: 'Questo fornitore verrà escluso nella compilazione del quadro AC/Unico - K730',
    reasonForPaymentModCU770Title: 'Causale somme erogate (mod. CU/770)',
    withholdingTaxApplicationTitle: 'Applicazione ritenuta d’acconto',
    dataForPayments: 'Dati per i pagamenti',
    bankName: 'Nome della banca',
    includeInK730Title: 'Includi nel quadro AC/Unico - K730',
    includeInK730Message:
      'Semplifica il tuo lavoro con <strong>la compilazione automatica del quadro AC/Unico - K730. {0}</strong> verrà inclusa nella compilazione del modello Unico e nel quadro K del modello 730',

    // Error mapping
    error_101: 'Credenziali errate.\nAccedi con le tue attuali credenziali Domustudio Cloud Pro.',
    error_108: 'Credenziali obbligatorie mancanti',
    error_109: 'Errore server, riprova tra qualche istante',
    error_114: "Il tuo tipo di account, non è compatibile con l'attuale piattaforma",
    error_117: 'Per accedere devi avere un account Domustudio Cloud Pro',
    error_401: 'La password è errata',
    error_403: 'Account non autorizzato',
    error_999: 'Abbiamo riscontrato un errore inaspettato, riprova tra qualche istante',

    // Validation Error
    errorMessageEmail: 'Indirizzo email non valido',
    errorMessageEmailLenght: 'Indirizzo email ha raggiunto il limite di caratteri',
    errorMessagePEC: 'Indirizzo PEC non valido',
    errorMessageUrl: 'Indirizzo web non valido',
    errorMessageCID: 'CID non valido',
    errorMessageCellPhone: 'Numero di telefono non valido',
    errorMessageVatNumber: 'Numero di partita iva non valido',
    errorMessageFiscCode: 'Codice fiscale non valido',
    errorMessageCAP: 'CAP non valido',
    errorMessageProvinceAcronym: 'Sigla di provincia non valida',
    errorMessageFormHasMissingRequired: 'Manca un campo obbligatorio',
    errorMessageMaxLength: 'Il messaggio non può superare i {0} caratteri',
    errorMessageISODate: 'Formato della data inserita non valido',

    // Tooltips message
    usePecAddess: 'Usa indirizzo PEC',
    useEmailAddess: 'Usa indirizzo email',
    addEndUsePecAddess: 'Aggiungi e usa indirizzo PEC',
    addEndUseEmailAddess: 'Aggiungi e usa indirizzo email',
    shipmentPreferenceRegisteredMailTooltip:
      'Puoi inviare la posta raccomandata via PEC, risparmiando così sia in termini economici che di tempo',
    shipmentPreferencePriorityMailTooltip:
      'Puoi inviare la posta prioritaria via mail, risparmiando così sia in termini economici che di tempo',
    pecChoiceOnRegisteredMailTooltip: 'Questo indirizzo PEC è lo stesso inserito nei dati generali.',
    emailChoiceOnRegisteredMailTooltip: 'Questo indirizzo email è lo stesso inserito nei dati generali.',
    preferentialSendTooltipMessage:
      'Seleziona le tipologie di comunicazioni da inviare a {0}.\n\nPer esempio, selezionando "assemblee" riceverà le comunicazioni con quell\'etichetta',
    shipmentPreferenceTooltipMessage:
      'Puoi specificare il mezzo di recapito preferito per le comunicazioni.\nSe per esempio si sceglie "PEC" per le comunicazioni raccomandate, {0} riceverà una PEC invece che la raccomandata.',
    isAlsoShippingAddressTooltip:
      'Se vuoi, puoi modificare l’indirizzo al quale verrà inviata la corrispondenza, ma nella quasi totalità dei casi i due indirizzi corrispondono.',
    isAlsoFiscalAddressTooltip:
      'Se vuoi, puoi modificare il domicilio fiscale, ma nella quasi totalità dei casi corrisponde all’indirizzo.',
    providerActivityTooltip:
      'La selezione di una tipologia di attività ti permetterà di trovare più velocemente questo fornitore quando dovrai inserire un intervento o più in generale nelle ricerche.',
    moduleACUnicoTooltip: 'Serve per generare automaticamente i moduli AC/Unico - K730',
    f24CodTributeTooltipMessage:
      "Se specifichi la ritenuta d'acconto per questo fornitore le sue fatture verranno incluse nel quadro unico - K/730 dell'anno successivo rispetto a quello di emissione della fattura",
    useDurcTooltip:
      'Puoi caricare il DURC su Domustudio in modo da avere questo documento sempre a portata di mano.\n\nRicorda che questo documento ti è richiesto per effettuare pagamenti ad un fornitore.',
    // Mobile
    whichNumberYouWantCall: 'Quale numero vuoi chiamare?',

    // Connection
    toastMessageOffline: 'Sei offline...',
    toastMessageOnline: 'Sei di nuovo online',

    // Activities
    openDayHourFrom: '<span>{0}</span>, gestita da <strong>{1}</strong>',
    ongoing: 'In corso',
    activitiesFilterStatusOpen: 'Aperte',
    suspended: 'Sospese',
    closed: 'Chiuse',
    clearAllFilters: 'Cancella tutti i filtri',
    openingDate: 'Data di apertura',
    deleteFilter: 'Elimina filtro',
    applicant: 'Richiedente',
    activityManager: 'Operatore',
    changeActivityManager: 'Cambia operatore',
    manager: 'Operatore',
    pauseBusiness: 'Sospendi attività',
    closeBusiness: 'Chiudi attività',
    reopenBusiness: 'Riapri attività',
    resumeBusiness: 'Riprendi attività',
    note: 'Nota',
    addNote: 'Aggiungi nota',
    editNote: 'Modifica nota',
    editInitialNote: 'Modifica descrizione iniziale',
    textOfTheNote: 'Testo della nota',
    dragTheFilesHere: 'Trascina qui i file',
    orChooseAFileToUpload: 'Oppure <span>scegli un file</span> da caricare',
    dropTheFilesHereToUploadThem: 'Rilascia qui i file per caricarli',
    imUploadingYourFiles: 'Sto caricando i tuoi file...',
    uploadedXFilesOnX: 'Caricati {0} file su {1}',
    iWasUnableToUploadXFiles: 'Non sono riuscito a caricare {0} file',
    xFilesNotUploaded: '{0} file non caricati',
    allTheFilesHaveBeenUploaded: 'Tutti i file sono stati caricati',
    uploadOthers: 'Carica altri',
    thereWasAnErrorInTheUpload: 'C’è stato un errore nel caricamento',
    afterClosingShowFor: 'Dopo la chiusura, mostra per',
    allTheCondos: 'tutti i condòmini',
    showActivitiesTo: 'Mostra attività a',
    notesToShow: 'Note da mostrare',
    daysOfStay7: 'Una settimana',
    daysOfStay30: 'Un mese (30 giorni)',
    daysOfStay90: 'Tre mesi (90 giorni)',
    daysOfStay180: 'Sei mesi (180 giorni)',
    daysOfStay365: 'Un anno',
    daysOfStayAlways: 'Sempre',
    noEvents: 'Non ci sono eventi',
    createActivityTitleTooltipsText: `Il titolo ti permetterà di riconoscere un’attività. <br><br>
    Ricordati di: <br>
    • essere conciso, <br>
    • utilizzare parole chiave`,
    createActivityDescriptionTooltipsText:
      'Riporta le informazioni che ti ha dato un condòmino, un fornitore o le osservazioni che hai fatto durante un sopralluogo.',
    activityTypeTooltip: 'Tipologia: {0}',
    activityPriorityTooltip: 'Priorità: {0}',
    enterAtLeast3CharactersToSearch: 'Inserisci almeno 3 caratteri per effettuare la ricerca',
    acivityApplicant: 'Richiedente',
    acivityApplicantContact: 'Contatto del richiedente',
    selectFirstApplicant: 'Seleziona prima il richiedente',
    enterNewApplicantContact: 'Inserisci il contatto del referente',

    // Form Element
    selectLoadingMessage: 'Sto cercando...',

    // ConfirmationModal
    confirmDeleteAttachmentConfirmTitle: 'Eliminare l’allegato?',
    confirmDeleteAttachmentConfirmBtnText: 'Elimina il file',
    confirmDeleteAttachmentConfirmMessage:
      'Se lo elimini non ti sarà più possibile visualizzare questo file. <br/><br/> <strong>Vuoi comunque eliminarlo?</strong>',
    confirmExitIncompleteUploadConfirmTitle: 'Uscire senza aver caricato i file?',
    confirmExitIncompleteUploadConfirmBtnText: 'Esci comunque',
    confirmExitIncompleteUploadConfirmMessage: 'Stai ancora caricando dei file, se esci ora non verranno salvati.',

    // Dossier
    dossier: 'Fascicolo',
    dossiers: 'Fascicoli',

    // Rate
    instalment: 'Rata',
    instalments: 'Rate'
  }
};

export type TLocale = keyof typeof translations;
export type Tkey = keyof typeof translations.it;

let currentLocale: TLocale = 'it';

type TSetLocale = (newLocale: TLocale) => void;
export const setLocale: TSetLocale = (newLocale: TLocale) => {
  currentLocale = newLocale;
};

type TTranslate = (key: Tkey, ...args: unknown[]) => string;
export const translate: TTranslate = (key, ...args) => {
  let translation = translations[currentLocale][key];
  if (translation) {
    args.forEach((param, index) => {
      translation = translation.replace(`{${index}}`, `${param}`);
    });
  }
  return translation;
};

type THasKey = (key: Tkey) => boolean;
export const hasTranslation: THasKey = (key) => key in translations[currentLocale];
