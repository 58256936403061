import { APIManager } from 'apiManager';
import { EntityFilters, Id } from 'types';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { CommonParts, CreateCommonParts, Environment, Implant, UpdateImplant } from 'types/commonParts';
import * as commonPartsService from './service';
import { GretchOptions } from 'gretchen';
import { queryStringObjVersion } from 'api/utils';

export const service = commonPartsService;

export const getList = async (condominiumId: Id['id'], { searchText }: EntityFilters) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const queryString = new URLSearchParams();
  searchText && queryString.set('searchQuery', searchText);
  const url = `/api/condominio/${condominiumId}/parteComune?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<CommonParts[]>(obj);
};

// Implant
export const getImplant = async (condominiumId: Id['id'], id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condominiumId}/parteComune/impianto/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Implant>(obj);
};

export const addImplant = async (condominiumId: Id['id'], implant: CreateCommonParts) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const url = `/api/condominio/${condominiumId}/parteComune/impianto`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(implant) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const updateImplant = async (condominiumId: Id['id'], id: Id['id'], implant: UpdateImplant) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const url = `/api/condominio/${condominiumId}/parteComune/impianto/${id}`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(implant) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Implant>(obj);
};

// Environment
export const getEnvironment = async (condominiumId: Id['id'], id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condominiumId}/parteComune/ambiente/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Environment>(obj);
};

export const addEnvironment = async (condominiumId: Id['id'], environment: CreateCommonParts) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const url = `/api/condominio/${condominiumId}/parteComune/ambiente`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(environment) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const updateEnvironment = async (condominiumId: Id['id'], id: Id['id'], environment: Environment) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const url = `/api/condominio/${condominiumId}/parteComune/ambiente/${id}`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(environment) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Environment>(obj);
};

export const removeCommonParts = async (condominiumId: Id['id'], id: Id['id'], objVersion: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const queryString = queryStringObjVersion(objVersion);
  const url = `/api/condominio/${condominiumId}/parteComune/${id}?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
