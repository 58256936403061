import { APIError, APIManagerResponse, APIRequestObj } from 'apiManager/types';
import { accountManager } from 'utils/accountManager';
import { gretch, GretchOptions } from 'gretchen';
import { getGretchOptions } from 'config/gretchen';
import { api } from 'api';
import { ErrorsCodeEnum } from 'config/apiErrorCodes';
import { translate } from 'translations';
import { parsePagination } from './utils';

const getFetchOptions = (APIObj: APIRequestObj) => {
  const { requestConfig, additionalOpts } = APIObj;
  const options: GretchOptions = { ...getGretchOptions(requestConfig), ...additionalOpts };
  return options;
};

export const APIManager = async <T>(APIObj: APIRequestObj): Promise<APIManagerResponse<T>> => {
  const options = getFetchOptions(APIObj);
  const { response, status } = await gretch<T, APIError>(APIObj.url, options).flush();
  try {
    const { alreadyTriedToRefresh, justResponse } = APIObj.requestConfig;
    if (response.ok) {
      const pagination = parsePagination(response);
      // T must be to Response type if the option is enabled
      const data: T = justResponse ? response : await response.json();
      return { data, pagination };
    }
    const authorization = response.headers.get('authorization');
    const error = (await response.json()) as APIError;

    // try to get new access token for API with authorization
    if (!alreadyTriedToRefresh && authorization) {
      if (status === 401 || ('code' in error && error.code === ErrorsCodeEnum.UNAUTHENTICATED)) {
        const refreshToken = accountManager.cookie.refreshToken.get();
        const newAccessToken = refreshToken
          ? await api.authorization.getNewAccessTokenByRefreshToken(refreshToken)
          : undefined;
        if (newAccessToken) {
          accountManager.updateAccessTokenInWholeApplication(newAccessToken);
          // update APIObj with new accessToken
          const newAPIObj = { ...APIObj };
          newAPIObj.requestConfig.headerConfig = {
            ...newAPIObj.requestConfig.headerConfig,
            accessToken: newAccessToken
          };
          newAPIObj.requestConfig.alreadyTriedToRefresh = true;
          return APIManager<T>(newAPIObj);
        }
        return { error: { code: ErrorsCodeEnum.UNAUTHENTICATED, message: translate('loginSessionExpired'), status } };
      }
    }

    if ('code' in error) {
      return { error: { ...error, status } };
    }

    throw Error;
  } catch (e) {
    return { error: { code: ErrorsCodeEnum.UNHANDLED_ERROR, message: translate('error_999'), status } };
  }
};
