import { useEffect, useState } from 'react';
import { MessagingInstanceDefinition } from 'types/firebase';
import firebase from 'firebase/app';
import { firebaseConfig } from 'config/firebase';
import 'firebase/messaging';
import { IS_NATIVE_APP } from 'utils/mobile';
import { enableProductionServices } from 'utils/route';
import { HookProps } from '../types';

const ENABLE_FIREBASE_ON_WEBAPP = enableProductionServices() && !IS_NATIVE_APP;

export const useFirebaseDesktop = ({ accessToken, refreshToken }: HookProps) => {
  const [messagingInstance, setMessagingInstance] = useState<MessagingInstanceDefinition>(undefined);

  useEffect(() => {
    if (ENABLE_FIREBASE_ON_WEBAPP) {
      const isLogged = Boolean(accessToken && refreshToken);
      if (isLogged && firebase.messaging.isSupported() && 'Notification' in window) {
        if (!firebase.apps.length) {
          const firebaseApp = firebase.initializeApp(firebaseConfig);
          const messaging = firebaseApp.messaging();
          setMessagingInstance(messaging);
        }
      } else {
        messagingInstance ? messagingInstance.deleteToken() : setMessagingInstance(undefined);
      }
    }
  }, [messagingInstance, accessToken, refreshToken]);

  return messagingInstance;
};
