import React, { FC, lazy } from 'react';
import { RootState } from 'reducers/rootReducer';
import { connect, ConnectedProps } from 'react-redux';
import { MODAL_IDS } from 'config/modals';
import { SuspenseWrapper } from 'components/suspenseWrapper';
import { ModalAttachmentPreview } from 'routes/attachmentPreviewer/modalAttachmentPreview';
import { ReportModal } from 'components/reportModal';
import { EntityDetails } from 'components/entityDetails';
import { FinancialMovementModal } from 'components/financialMovementModal';

const LazyDBNotUpdatedModal = lazy(() => import('components/dbNotUpdatedModal'));
const LazyPidNotEnabled = lazy(() => import('components/pidNotEnabledModal'));

const mapStateToProps = ({
  authReducer: { accessToken },
  modalsReducer: { modalIdsStatus, reportModalData }
}: RootState) => ({
  accessToken,
  modalIdsStatus,
  reportModalData
});
const connector = connect(mapStateToProps, {});
type Props = ConnectedProps<typeof connector>;

const GlobalModalsComponent: FC<Props> = ({ modalIdsStatus, reportModalData, accessToken }) => (
  <SuspenseWrapper>
    <>
      {accessToken ? <ModalAttachmentPreview /> : null}
      {accessToken ? <EntityDetails /> : null}
      {accessToken ? <FinancialMovementModal /> : null}
      <ReportModal modalIdsStatus={modalIdsStatus} reportModalData={reportModalData} />
      {modalIdsStatus[MODAL_IDS.DB_NOT_UPDATED] ? <LazyDBNotUpdatedModal /> : null}
      {modalIdsStatus[MODAL_IDS.PID_NOT_ENABLED] ? <LazyPidNotEnabled /> : null}
    </>
  </SuspenseWrapper>
);

const GlobalModals = connector(GlobalModalsComponent);

export default GlobalModals;
