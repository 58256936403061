export const ATTIVITA_FILTER_KEYS = [
  'condominio',
  'fornitore',
  'priorita',
  'tipo',
  'richiedente',
  // Format YYYY-MM-DD:YYYY-MM-DD, first is from, last is to
  'intervalloDate',
  'gestore',
  'avvisi',
  'servizio'
];

export const FORNITORI_FILTER_KEYS = ['attivita', 'attivi', 'datiIncompleti', 'eserciziID'];

export const PERSONE_FILTER_KEYS = ['condGendID', 'filtroSubentri'];

export const CONDOMINI_FILTER_KEYS = ['orderBy', 'condominiGestiti'];
