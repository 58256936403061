import { Modal } from '@fattureincloud/fic-design-system';
import { setFinancialMovementAction } from 'actions/modals';
import { SuspenseWrapper } from 'components/suspenseWrapper';
import React, { FC, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';

const LazyInvoiceListImpl = lazy(() => import('./components/invoiceList'));

export const FinancialMovementModal: FC = () => {
  const financialMovementsData = useSelector(selectors.modalSelector.getFinancialMovementsData);
  const isMobileScreen = useSelector(selectors.generalSelector.isMobileScreen);
  const dispatch = useDispatch();
  const showModal = Boolean(financialMovementsData);
  return (
    <Modal
      isOpen={showModal}
      minHeight={isMobileScreen ? window.innerHeight * 0.75 : undefined}
      header={() => <p>Lista Movimenti</p>}
      onActionClose={() => dispatch(setFinancialMovementAction(undefined))}
    >
      <SuspenseWrapper>
        <>{financialMovementsData && <LazyInvoiceListImpl currentFinancialMovement={financialMovementsData} />}</>
      </SuspenseWrapper>
    </Modal>
  );
};
