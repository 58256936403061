import { IconProps, PageEmptySet } from '@fattureincloud/fic-design-system';
import React, { FC } from 'react';
import { faSync } from '@fortawesome/pro-light-svg-icons';

type Props = { icon: IconProps; action?: () => void };

export const ErrorWhileLoading: FC<Props> = ({ icon, action }) => (
  <div className="h-100 d-flex align-items-center">
    <PageEmptySet
      title="Errore"
      text="Ooops!...Qualcosa è andato storto durante il caricamento."
      icon={icon}
      action={action ? { text: 'Riprova', icon: { icon: faSync }, action } : undefined}
    />
  </div>
);
