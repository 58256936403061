import { ActivitySyncMessages, ActivityPageContext } from 'reducers/activitiesReducer/types';
import { ManageModalOutput } from '../modals/types';
import { Activity, StatusEnum } from 'types/activity';
import { ResetState } from 'actions/general/types';
import { AdvancedFilterPayload } from 'types';
import { Logout } from 'actions/auth/types';

/**
 * Action type names
 */
export enum ActivitiesActionNames {
  SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST',
  SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY',
  // Properties
  SET_ACTIVITIES_FILTER_STATUS = 'SET_ACTIVITIES_FILTER_STATUS',
  SET_ACTIVITIES_ADVANCED_FILTER = 'SET_ACTIVITIES_ADVANCED_FILTER',
  CLEAR_ACTIVITIES_ADVANCED_FILTERS = 'CLEAR_ACTIVITIES_ADVANCED_FILTERS',
  // Interventions messages
  SET_SYNC_INTERVENTIONS_MESSAGES = 'SET_SYNC_INTERVENTIONS_MESSAGES',
  // Reset current activity details
  RESET_ACTIVITY_DETAILS = 'RESET_ACTIVITY_DETAILS',
  // Last activity created
  UPDATE_LAST_ACTIVITY_CREATED = 'UPDATE_LAST_ACTIVITY_CREATED'
}

export type AcivityListResponse = ActivitySyncMessages & { attivita: Activity[] };

export type ActivitiesSuccessPayload = {
  activitiesList: Activity[];
  pageContext: ActivityPageContext;
  interventionMessagesStatus: ActivitySyncMessages;
};

export type InterventionMessagesSuccessResponse = ActivitySyncMessages & { aggiornato: boolean };

/**
 * Action typings
 */
export type SetActivityList = {
  type: typeof ActivitiesActionNames.SET_ACTIVITY_LIST;
  payload: ActivitiesSuccessPayload;
};

export type SetCurrentActivity = { type: typeof ActivitiesActionNames.SET_CURRENT_ACTIVITY; payload: Activity };

export type UpdateLastActivityCreated = {
  type: ActivitiesActionNames.UPDATE_LAST_ACTIVITY_CREATED;
  payload?: Activity;
};

// Interventions messages
export type SetSyncInterventionMessages = {
  type: typeof ActivitiesActionNames.SET_SYNC_INTERVENTIONS_MESSAGES;
  payload: ActivitySyncMessages;
};

// Filters
type SetActivitiesFilterStatus = {
  type: typeof ActivitiesActionNames.SET_ACTIVITIES_FILTER_STATUS;
  payload: StatusEnum;
};

type SetActivitiesAdvancedFilter = {
  type: typeof ActivitiesActionNames.SET_ACTIVITIES_ADVANCED_FILTER;
  payload: AdvancedFilterPayload;
};

type ClearActivitiesAdvancedFilters = { type: typeof ActivitiesActionNames.CLEAR_ACTIVITIES_ADVANCED_FILTERS };

// Reset activity
export type ResetActivityDetails = { type: typeof ActivitiesActionNames.RESET_ACTIVITY_DETAILS };

/**
 * Action output typings aggregator
 */
export type ActivitiesTypes =
  | ResetState
  | Logout
  | SetActivityList
  | SetCurrentActivity
  | SetActivitiesFilterStatus
  | SetActivitiesAdvancedFilter
  | ManageModalOutput
  | ClearActivitiesAdvancedFilters
  | ResetActivityDetails
  | UpdateLastActivityCreated
  | SetSyncInterventionMessages;
