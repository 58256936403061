import { AuthReducer } from 'reducers/authReducer/types';

export const isAttachmentsEnabled = (
  gestioneAccessi: AuthReducer['gestioneAccessi'],
  permissions: AuthReducer['permissions']
) => Boolean(!permissions && !gestioneAccessi) || Boolean(gestioneAccessi && permissions?.accAllegatiOn);

export const isActivityEditEnabled = (
  gestioneAccessi: AuthReducer['gestioneAccessi'],
  permissions: AuthReducer['permissions']
) => Boolean(!permissions && !gestioneAccessi) || Boolean(gestioneAccessi && permissions?.accAttivitaOn);

export const isCondominioEditEnabled = () => false;

export const isCommonPartsEditEnabled = (
  gestioneAccessi: AuthReducer['gestioneAccessi'],
  permissions: AuthReducer['permissions']
) => Boolean(!permissions && !gestioneAccessi) || Boolean(gestioneAccessi && permissions?.accImpiantiOn);

export const isContactEditEnabled = (
  gestioneAccessi: AuthReducer['gestioneAccessi'],
  permissions: AuthReducer['permissions']
) => Boolean(!permissions && !gestioneAccessi) || Boolean(gestioneAccessi && permissions?.accPersoneOn);

export const isProviderEditEnabled = (
  gestioneAccessi: AuthReducer['gestioneAccessi'],
  permissions: AuthReducer['permissions']
) => Boolean(!permissions && !gestioneAccessi) || Boolean(gestioneAccessi && permissions?.accFornitoriOn);
