import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const blue: color = {
  50: '#e0eaf9',
  100: '#b3cbf0',
  200: '#80a9e6',
  300: '#4d86db',
  400: '#266cd4',
  500: '#0052cc',
  600: '#004bc7',
  700: '#0041c0',
  800: '#0038b9',
  900: '#0028ad'
};
