import { APIManager } from 'apiManager';
import { Id } from 'types';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { CreateService, Service } from 'types/commonParts';
import { GretchOptions } from 'gretchen';
import { queryStringObjVersion } from 'api/utils';

export const get = async (condominiumId: Id['id'], commonPartsId: Id['id'], serviceId: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condominiumId}/parteComune/${commonPartsId}/servizio/${serviceId}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Service>(obj);
};

export const add = async (condominiumId: Id['id'], commonPartsId: Id['id'], newService: CreateService) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = `/api/condominio/${condominiumId}/parteComune/${commonPartsId}/servizio`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(newService) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Service>(obj);
};

export const edit = async (
  condominiumId: Id['id'],
  commonPartsId: Id['id'],
  serviceId: Id['id'],
  updatedService: CreateService
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const url = `/api/condominio/${condominiumId}/parteComune/${commonPartsId}/servizio/${serviceId}`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(updatedService) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Service>(obj);
};

export const remove = async (
  condominiumId: Id['id'],
  commonPartsId: Id['id'],
  serviceId: Id['id'],
  objVersion: number
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const queryString = queryStringObjVersion(objVersion);
  const url = `/api/condominio/${condominiumId}/parteComune/${commonPartsId}/servizio/${serviceId}?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
