import { MOBILE_WIDTH_BREAKPOINT } from 'config/mobile';
import { MobileState, ScreenState } from 'types/mobile';
import { getiPhoneModel } from 'utils/phoneDetection';
import { GeneralActionNames, GeneralActionsTypes } from 'actions/general/types';
import { ArchiveInfo, MioCondominioInfo } from 'types/archive';
import { LocalUserSettings } from 'types/localUserSettings';
import { accountManager } from 'utils/accountManager';
import { CondominioReadViewModel, Esercizio } from 'types/condominio';
import { AuthActionNames } from 'actions/auth/types';

export enum EConnectionStatuses {
  online = 'online',
  offline = 'offline'
}

export type ConnectionStatus = EConnectionStatuses.online | EConnectionStatuses.offline;

type ViewportInfo = ScreenState & MobileState;

export type GeneralReducer = {
  currentDb?: ArchiveInfo;
  currentCondominium?: CondominioReadViewModel;
  currentEsercizio?: Esercizio;
  connectionStatus?: ConnectionStatus;
  phoneModel: string;
  viewportInfo: ViewportInfo;
  refreshRouteData: number;
  refreshEntityDetailsData: number;
  localUserSettings: LocalUserSettings;
  MioCondominioInfo: MioCondominioInfo;
  changelogReady: boolean;
};

const initialState: GeneralReducer = {
  currentDb: undefined,
  currentCondominium: undefined,
  connectionStatus: EConnectionStatuses.online,
  phoneModel: getiPhoneModel(),
  viewportInfo: {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobileScreen: window.innerWidth < MOBILE_WIDTH_BREAKPOINT
  },
  refreshRouteData: 0,
  refreshEntityDetailsData: 0,
  localUserSettings: { appereance: { sidebarExpanded: false } },
  MioCondominioInfo: { pid: 0, mioCondominioPremiumAbilitato: false },
  changelogReady: false
};

const resetAfterLogout = {
  currentDb: undefined,
  currentCondominium: undefined,
  refreshRouteData: 0,
  MioCondominioInfo: { pid: 0, mioCondominioPremiumAbilitato: false }
};

export const generalReducer = (state: GeneralReducer = initialState, action: GeneralActionsTypes): GeneralReducer => {
  switch (action.type) {
    case GeneralActionNames.SET_CURRENT_DB:
      return { ...state, currentDb: action.payload };

    case GeneralActionNames.UNSET_CURRENT_DB:
      return {
        ...state,
        currentDb: undefined,
        currentCondominium: undefined,
        currentEsercizio: undefined,
        MioCondominioInfo: initialState.MioCondominioInfo
      };

    case GeneralActionNames.SET_CURRENT_CONDOMINIUM:
      return { ...state, currentCondominium: action.payload };

    case GeneralActionNames.SET_CURRENT_ESERCIZIO:
      return { ...state, currentEsercizio: action.payload };

    case GeneralActionNames.SET_CONNECTION_STATUS: {
      return { ...state, connectionStatus: action.payload };
    }

    case GeneralActionNames.SET_PHONE_MODEL: {
      return { ...state, phoneModel: action.payload };
    }

    case GeneralActionNames.SET_CHANGELOG: {
      return { ...state, changelogReady: action.payload };
    }

    case GeneralActionNames.SCREEN_RESIZE:
      return {
        ...state,
        viewportInfo: {
          ...action.payload,
          isMobileScreen: action.payload.width < MOBILE_WIDTH_BREAKPOINT
        }
      };

    case GeneralActionNames.REFRESH_ROUTE_DATA: {
      return { ...state, refreshRouteData: Date.now() };
    }
    case GeneralActionNames.REFRESH_ENTITY_DETAILS_DATA: {
      return { ...state, refreshEntityDetailsData: Date.now() };
    }

    case GeneralActionNames.UPDATE_DB_LAST_ACCESS: {
      const { pid, mioCondominioPremiumAbilitato } = action.payload;
      return {
        ...state,
        MioCondominioInfo: { pid, mioCondominioPremiumAbilitato: Boolean(mioCondominioPremiumAbilitato) }
      };
    }

    case GeneralActionNames.SET_LOCAL_USER_SETTINGS: {
      accountManager.localStorage.localUserSettings.set(action.payload);
      return { ...state, localUserSettings: { ...action.payload } };
    }

    case AuthActionNames.LOGOUT:
      return { ...state, ...resetAfterLogout };

    default:
      return state;
  }
};
