import { defaultPalette } from 'palette';
import styled, { css, keyframes } from 'styled-components';

export type SpinnerProps = { size: 'small' | 'medium' };

const rotationAnimation = keyframes`
  from{ transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Spinner = styled.div<SpinnerProps>`
  border-top: 3px solid ${defaultPalette.red[500]};
  border-right: 3px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation-name: ${rotationAnimation};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  ${({ size }) => css`
    width: ${size === 'small' ? '32px' : '64px'};
    height: ${size === 'small' ? '32px' : '64px'};
  `};
`;
