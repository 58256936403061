/**
 * This variable can assume this values when it will be compiled:
 * - Development
 * - Beta
 * - Production
 */
// eslint-disable-next-line prefer-const
export let deployEnvironment = '#{DEPLOY_ENV}#';

export enum DeployEnvironmentEnum {
  Development = 'Development',
  Beta = 'Beta',
  Production = 'Production'
}
