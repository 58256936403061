import { ScreenState } from 'types/mobile';
import { ArchiveInfo, MioCondominioInfo } from 'types/archive';
import { GeneralActionNames, ResetState, SetChangelog, SetEsercizio, SetLocalUserSettings } from './types';
import { LocalUserSettings } from 'types/localUserSettings';
import { CondominioReadViewModel, Esercizio } from 'types/condominio';

export const setCurrentDbAction = (archive: ArchiveInfo) => ({
  type: GeneralActionNames.SET_CURRENT_DB,
  payload: archive
});

export const unsetCurrentDbAction = () => ({ type: GeneralActionNames.UNSET_CURRENT_DB });

export const setCondominiumAction = (data: CondominioReadViewModel | undefined) => ({
  type: GeneralActionNames.SET_CURRENT_CONDOMINIUM,
  payload: data
});

export const setEsercizioAction = (data: Esercizio | undefined): SetEsercizio => ({
  type: GeneralActionNames.SET_CURRENT_ESERCIZIO,
  payload: data
});

export const updateLastAccessDbAction = (data: MioCondominioInfo) => ({
  type: GeneralActionNames.UPDATE_DB_LAST_ACCESS,
  payload: data
});

export const ScreenResizeAction = (size: ScreenState) => ({
  type: GeneralActionNames.SCREEN_RESIZE,
  payload: size
});

export const refreshRouteDataAction = () => ({ type: GeneralActionNames.REFRESH_ROUTE_DATA });

export const refreshEntityDetailsDataAction = () => ({ type: GeneralActionNames.REFRESH_ENTITY_DETAILS_DATA });

export const resetPrivateRoutesState = (): ResetState => ({ type: GeneralActionNames.RESET_STATE });

export const SetLocalUserSettingsAction = (data: LocalUserSettings): SetLocalUserSettings => ({
  type: GeneralActionNames.SET_LOCAL_USER_SETTINGS,
  payload: data
});

export const setChangelogAction = (value: boolean): SetChangelog => ({
  type: GeneralActionNames.SET_CHANGELOG,
  payload: value
});
