import { translate } from 'translations';
import { CreateActivity } from 'types/activity';
import moment from 'moment';
import { EntityFilters, PageContext } from 'types';

export const formatCAP = (cap: string) => cap.substring(0, 5);
export const formatProvince = (cap: string) => cap.substring(0, 2).toUpperCase();

export const getEmptyNewActivity = (operator: string): CreateActivity => ({
  richiedenteID: -1, // set administrator to default
  intestazioneCondominio: '',
  gestore: '',
  tipoSegnalazID: 2,
  tipoSegnalazDescr: translate('problem'),
  titolo: '',
  priorita: 2,
  descrizione: '',
  dataApertura: moment().toISOString(true),
  operatore: operator,
  dataConcludereEntro: ''
});

export function initialPageContext<F extends EntityFilters>(
  additionalFilters?: Omit<F, keyof EntityFilters>
): PageContext<F> {
  return {
    filters: { searchText: '', advanced: {}, ...additionalFilters } as F,
    currentPage: 1,
    totalCount: 0
  };
}
