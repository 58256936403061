import React, { FC, useState } from 'react';
import firebase from 'firebase';
import { NotificationBanner } from './banner';
import { useFirebaseNotificationDispatcher } from './use-dispatcher';

/**
 * PRE: this componend is rendered if met this conditions:
 * - isLogged
 * - accessToken
 * - firebase.messaging.isSupported()
 * - 'Notification' in window
 */

export type ControllerProps = { messaging: firebase.messaging.Messaging };

export const FirebaseNotificationController: FC<ControllerProps> = ({ messaging }) => {
  const [enableFirebase, setEnableFirebase] = useState(Notification.permission === 'granted');

  useFirebaseNotificationDispatcher({ messaging, enable: enableFirebase });

  return Notification.permission === 'default' ? (
    <NotificationBanner enableFirebase={(value: boolean) => setEnableFirebase(value)} />
  ) : null;

  // switch (Notification.permission) {
  //   case 'denied':
  //     return null;
  //   case 'granted':
  //     return enableFirebase ? <></> : null;
  //   case 'default':
  //     return ;
  // }
};
