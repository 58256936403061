import { ContactsActionNames, ContactsTypes } from 'actions/contacts/types';
import { Contact } from 'types/contact';
import { PageContext } from 'types';
import { ModalsActionNames } from 'actions/modals/types';
import { GeneralActionNames } from 'actions/general/types';
import { clearedEntityAdvancedFilters, setEntityAdvancedFilters } from 'utils/entityFilters';
import { INITIAL_PAGE_CONTEXT } from 'constants/page';
import { AuthActionNames } from 'actions/auth/types';

export type ContactsReducer = { list: Contact[]; currentContact?: Contact; pageContext: PageContext };

export const initialState: ContactsReducer = { list: [], pageContext: INITIAL_PAGE_CONTEXT };

export const contactsReducer = (state: ContactsReducer = initialState, action: ContactsTypes): ContactsReducer => {
  switch (action.type) {
    case ContactsActionNames.SET_CONTACT_LIST:
      return { ...state, list: action.payload.list, pageContext: action.payload.pageContext };

    case ContactsActionNames.SET_CURRENT_CONTACT:
      return { ...state, currentContact: action.payload };

    case ModalsActionNames.OPEN_MODAL:
    case ContactsActionNames.RESET_CONTACT_DETAILS:
      return { ...state, currentContact: undefined };

    case ContactsActionNames.SET_CONTACTS_ADVANCED_FILTER: {
      const advanced = setEntityAdvancedFilters(state.pageContext.filters.advanced, action.payload);
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, advanced }
        }
      };
    }

    case ContactsActionNames.CLEAR_CONTACTS_ADVANCED_FILTERS: {
      const advanced = clearedEntityAdvancedFilters(state.pageContext.filters.advanced);
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, advanced }
        }
      };
    }

    case GeneralActionNames.RESET_STATE:
    case AuthActionNames.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
