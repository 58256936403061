import React, { FC, useEffect, useState } from 'react';
import style from './style.module.scss';
import source from 'assets/notification.png';
import { Button } from '@fattureincloud/fic-design-system';
import { useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';
import { accountManager } from 'utils/accountManager';

type Props = { enableFirebase: (value: boolean) => void };

export const NotificationBanner: FC<Props> = ({ enableFirebase }) => {
  const [show, setShow] = useState<boolean>(false);
  const currentDb = useSelector(selectors.generalSelector.currentDb);

  const closeBanner = () => setShow(false);

  const remindMeInTheNextFewDay = () => {
    closeBanner();
    accountManager.cookie.firebaseNotification.set(2);
  };

  const askPermission = async () => {
    closeBanner();
    const NotificationPermission = await Notification.requestPermission();
    return enableFirebase(NotificationPermission === 'granted');
  };

  useEffect(() => {
    // Let's check if the browser supports notifications
    const checkBrowserSupport = Boolean('Notification' in window);
    if (checkBrowserSupport) {
      const canAskToEnableNotification = accountManager.cookie.firebaseNotification.get();
      window.setTimeout(
        () => setShow(Boolean(Notification.permission === 'default' && !canAskToEnableNotification && currentDb)),
        1000
      );
    }
  }, [currentDb]);

  return (
    <div className={`${style.container} ${show ? style.open : ''}`}>
      <div className={style.title}>rimani sempre aggiornato</div>
      <div className={style.content}>
        <img src={source} alt="Logo notifica" className={style.logo} />
        <span className={style.divider} />
        <p className="text--light font--grey">
          Attiva le notifiche per ricevere i tuoi aggiornamenti in tempo reale!
          <br />
          Per attivare le notifiche devi dare il consenso.
        </p>
      </div>

      <div className="d-flex justify-content-end">
        <Button text="Non ora" type="text" color="red" className="pr-3" onClick={remindMeInTheNextFewDay} />
        <Button text="Attiva" color="red" onClick={askPermission} />
      </div>
    </div>
  );
};
