import { setLoginTokenAction } from 'actions/auth';
import { store } from 'router';
import { cookie } from './cookies';
import { localStorage } from './localStorage';

const removeAllLoginData = () => {
  cookie.refreshToken.remove();
  localStorage.archive.remove();
  localStorage.credentials.remove();
};

const updateAccessTokenInWholeApplication = (accessToken: string) => {
  const credentials = localStorage.credentials.get();
  if (credentials) {
    const updatedCredentials = { ...credentials, accessToken };
    localStorage.credentials.remove();
    localStorage.credentials.set(updatedCredentials);
    store.dispatch(setLoginTokenAction(updatedCredentials));
  }
};

export const accountManager = {
  cookie,
  localStorage,
  updateAccessTokenInWholeApplication,
  removeAllLoginData
};
