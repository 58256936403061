import { useEffect } from 'react';
import { IS_NATIVE_APP } from 'utils/mobile';
import { getEnvironment } from 'utils/route';
import { reactNativePostMessage } from 'utils/native';
import { HookProps } from '../types';

export const useFirebaseMobile = ({ accessToken, refreshToken }: HookProps) => {
  useEffect(() => {
    if (IS_NATIVE_APP) {
      if (accessToken && refreshToken) {
        const environment = getEnvironment();
        // emit to react-native to enable firebase on
        reactNativePostMessage({ accessToken, refreshToken, environment });
      }
    }
  }, [accessToken, refreshToken]);

  return null;
};
