import { CID_COOKIE } from 'config/cookie';
import { DEFAULT_SET_OPTIONS } from './constant';
import { cookies } from './index';

export const set = (value: string) => cookies.set(CID_COOKIE, value, DEFAULT_SET_OPTIONS);

export const get = () => {
  const cid = cookies.get(CID_COOKIE);
  return cid ?? '';
};

export const remove = () => cookies.remove(CID_COOKIE);
