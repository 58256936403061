import { setCurrentActivityAction } from 'actions/activities';
import { setCurrentEnvironmentAction, setCurrentImplantAction } from 'actions/commonParts';
import { setCurrentContactAction } from 'actions/contacts';
import { setEntityDetailsAction } from 'actions/modals';
import { setCurrentProviderAction } from 'actions/providers';
import { setCurrentInstalmentAction } from 'actions/instalment';
import { setCurrentCondominioAction } from 'actions/condomini';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { WithAPILoadingInjectedProps } from 'HOC/withAPILoading';
import { getCurrentEntity, getEntityAction } from './utils';
import { ReactElement } from 'react';
import { EntityDetails } from 'types/entity';

const mapStateToProps = (state: RootState, { entityDetailsByProps }: ComponentProps) => {
  const { modalsReducer, generalReducer } = state;
  const type = entityDetailsByProps?.customEntityDetails?.type ?? modalsReducer?.entityDetails?.type;
  const currentEntity = getCurrentEntity(type, state);
  return {
    entityDetails: entityDetailsByProps?.customEntityDetails ?? modalsReducer.entityDetails,
    modalIdsStatus: modalsReducer.modalIdsStatus,
    currentCondominium: generalReducer.currentCondominium,
    refreshEntityDetailsData: generalReducer.refreshEntityDetailsData,
    currentEntity
  };
};

const mapDispatchToProps = {
  setEntityDetailsAction,
  setCurrentContactAction,
  setCurrentProviderAction,
  setCurrentActivityAction,
  setCurrentEnvironmentAction,
  setCurrentImplantAction,
  setCurrentInstalmentAction,
  setCurrentCondominioAction
};

type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ComponentProps) => {
  const setCurrentEntity = getEntityAction(stateProps?.entityDetails?.type, dispatchProps);
  const { setEntityDetailsAction } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    setCurrentEntity,
    setEntityDetailsAction: ownProps?.entityDetailsByProps?.unsetCustomEntityDetails ?? setEntityDetailsAction
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type DrawerDetailsInjectedProps = { reloadData: () => void };

type ComponentProps = {
  entityDetailsByProps?: { customEntityDetails: EntityDetails; unsetCustomEntityDetails: () => void };
  children: (props: DrawerDetailsInjectedProps) => ReactElement<unknown>;
};

export type EntityDetailsProps = Omit<ComponentProps, 'children'>;
export type Props = ComponentProps & ConnectedProps<typeof connector> & WithAPILoadingInjectedProps;
export type State = { APIError: boolean };
