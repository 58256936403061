/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Route } from 'types';
import { PrivateRoute } from 'components/privateRoute';

// Lazy render
const LazyLogin = lazy(() => import('routes/login'));
const LazyMobileLogin = lazy(() => import('routes/login/mobile-login'));
const LazyForgotPassword = lazy(() => import('routes/forgotPassword'));
// const LazySignUp = lazy(() => import('routes/signUp'));
const LazyActivities = lazy(() => import('routes/activities'));
const LazyInstalments = lazy(() => import('routes/instalments'));
const LazyCondomini = lazy(() => import('routes/condomini'));
const LazyCommonParts = lazy(() => import('routes/commonParts'));
const LazyContacts = lazy(() => import('routes/contacts'));
const LazyProviders = lazy(() => import('routes/providers'));
const LazyAttachmentPreviewer = lazy(() => import('routes/attachmentPreviewer'));

export enum publicRoutesNames {
  attachmentPreview = '/attachmentPreview',
  forgotPassword = '/forgotPassword',
  login = '/login',
  mobileLogin = '/mobileLogin'
  // signup = 'signup'
}

export enum privateRoutesNames {
  // group A
  condominiums = '/condominiums',
  instalments = '/instalments',
  // group B
  activities = '/activities',
  commonParts = '/commonParts',
  contacts = '/contacts',
  providers = '/providers'
}

export const HOME_PATH = '/';

export const navRoute = Object.keys({ ...privateRoutesNames });
const navRouteGroupA = ['condominiums', 'instalments'];
const navRouteGroupB = ['activities', 'commonParts', 'contacts', 'providers'];
export const navRouteGrupped = [navRouteGroupA, navRouteGroupB];

export const routeNames = { ...publicRoutesNames, ...privateRoutesNames, home: HOME_PATH };

export const publicRoutes: Route<typeof publicRoutesNames>[] = [
  {
    name: 'attachmentPreview',
    path: publicRoutesNames.attachmentPreview,
    element: <LazyAttachmentPreviewer />
  },
  {
    name: 'forgotPassword',
    path: publicRoutesNames.forgotPassword,
    element: <LazyForgotPassword />
  },
  { name: 'login', path: publicRoutesNames.login, element: <LazyLogin /> },
  { name: 'mobileLogin', path: publicRoutesNames.mobileLogin, element: <LazyMobileLogin /> }
  // { name: 'signup', path: pubblicRoutesNames.signup), element: LazySignUp }
];

export const privateRoutes: Route<typeof privateRoutesNames>[] = [
  {
    name: 'activities',
    path: privateRoutesNames.activities,
    element: <PrivateRoute component={LazyActivities} />
  },
  {
    name: 'instalments',
    path: privateRoutesNames.instalments,
    element: <PrivateRoute component={LazyInstalments} />
  },
  {
    name: 'condominiums',
    path: privateRoutesNames.condominiums,
    element: <PrivateRoute component={LazyCondomini} />
  },
  {
    name: 'commonParts',
    path: privateRoutesNames.commonParts,
    element: <PrivateRoute component={LazyCommonParts} />
  },
  {
    name: 'contacts',
    path: privateRoutesNames.contacts,
    element: <PrivateRoute component={LazyContacts} />
  },
  {
    name: 'providers',
    path: privateRoutesNames.providers,
    element: <PrivateRoute component={LazyProviders} />
  }
];
