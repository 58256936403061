import { PaginationProps } from '@fattureincloud/fic-design-system/dist/components/pagination/types';
import { PAGE_SIZE } from 'config/api';
import { ActivityFilter } from 'reducers/activitiesReducer/types';
import { StatusEnum } from 'types/activity';
import { initialPageContext } from 'utils/entity';

export const INITIAL_PAGE_CONTEXT = initialPageContext();
export const ACTIVITY_INITIAL_PAGE_CONTEXT = {
  ...initialPageContext<ActivityFilter>({ activityStatus: StatusEnum.Aperta }),
  paginationTotalCount: 0,
  totalOpenCount: 0,
  totalSuspendedCount: 0,
  totalClosedCount: 0
};

export const PAGINATION_TEXTS: Pick<PaginationProps, 'nextText' | 'prevText'> = {
  prevText: 'Indietro',
  nextText: 'Avanti'
};
export const PAGINATION_PAGE_SIZE: Pick<PaginationProps, 'pageSize'> = { pageSize: PAGE_SIZE };
export const PAGINATION_COMMON_PROPS: Pick<PaginationProps, 'pageSize' | 'nextText' | 'prevText'> = {
  ...PAGINATION_TEXTS,
  ...PAGINATION_PAGE_SIZE
};
