import { MicroTagPalette } from '@fattureincloud/fic-design-system/dist/components/microTag/microTagPalette';
import { colors as defaultPalette } from 'palette/colors';

const microTagPalette: MicroTagPalette = {
  error: {
    backgroundColor: defaultPalette.red[50],
    color: defaultPalette.red[500]
  },
  info: {
    backgroundColor: defaultPalette.blue[50],
    color: defaultPalette.blue[500]
  },
  standard: {
    backgroundColor: defaultPalette.grey[50],
    color: defaultPalette.grey[500]
  },
  success: {
    backgroundColor: defaultPalette.green[50],
    color: defaultPalette.green[500]
  },
  warning: {
    backgroundColor: defaultPalette.yellow[50],
    color: defaultPalette.yellow[500]
  }
};

export default microTagPalette;
