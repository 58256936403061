import { AuthActionNames } from 'actions/auth/types';
import { GeneralActionNames } from 'actions/general/types';
import { InstalmentActionNames, InstalmentTypes } from 'actions/instalment/types';
import { gruppedTypeFilterOptions } from 'routes/instalments/components/filters/components/gruppedTypeFilter';
import { InstalmentDetail, InstalmentDetailIDS, InstalmentFilter } from 'types/instalment';

export type InstalmentReducer = {
  filters: InstalmentFilter;
  currentInstalment?: InstalmentDetail;
  currentInstalmentsIDS: InstalmentDetailIDS;
};

export const initialState: InstalmentReducer = {
  filters: {
    searchText: '',
    gruppedTypeFilter: gruppedTypeFilterOptions[0].key,
    expiredImportFilter: false,
    advanced: {}
  },
  currentInstalmentsIDS: {}
};

export const instalmentReducer = (
  state: InstalmentReducer = initialState,
  action: InstalmentTypes
): InstalmentReducer => {
  switch (action.type) {
    case InstalmentActionNames.SET_FILTERS:
      return { ...state, filters: action.payload };

    case InstalmentActionNames.SET_CURRENT_INSTALMENT:
      return {
        ...state,
        currentInstalment: action.payload,
        currentInstalmentsIDS: action.payload ? state.currentInstalmentsIDS : {}
      };

    case InstalmentActionNames.SET_CURRENT_INSTALMENT_IDS:
      return { ...state, currentInstalmentsIDS: action.payload };

    case GeneralActionNames.RESET_STATE:
    case AuthActionNames.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};
