import { Button, InlineMessage, InputText, TextArea } from '@fattureincloud/fic-design-system';
import { closeReportModalAction } from 'actions/modals';
import { CloseAction } from 'components/closeAction';
import { UploadFileButton } from 'components/uploadFile';
import { LoadedAttachment } from 'components/uploadFile/components/loadedAttachment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalReportData, ModalReportEnumType } from 'types/report';
import { validateField, validators } from 'utils/formValidation';
import { FieldErrorMessage } from 'utils/formValidation/types';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { withAPILoading, WithAPILoadingInjectedProps } from 'HOC/withAPILoading';
import LoadingSpinner from 'design-component/loadingSpinner';
import { api } from 'api';
import { showErrorToast } from 'utils/toast';
import { SuccessSend } from './successSend';

type Props = Pick<ModalReportData, 'type'> & WithAPILoadingInjectedProps;

const ModalContentComponent: FC<Props> = ({ type, handleAPICall, isLoading }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<FieldErrorMessage | undefined>(undefined);
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState<File | undefined>(undefined);
  const [id, setId] = useState(0);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const isBug = type === ModalReportEnumType.bug;

  const closeModal = () => dispatch(closeReportModalAction());

  useEffect(() => setEmailError(validateField(email, [validators.isValidEmail])), [email]);

  const submitReport = async () => {
    const call = () => api.report[isBug ? 'sendBug' : 'sendSuggestion'](email, message, attachment);
    const { data, error } = await handleAPICall(call);
    if (data) {
      setId(data.id);
      setShow(true);
    }
    error && showErrorToast(isBug ? "Errore nell'invio della segnalazione" : "Errore nell'invio del suggerimento");
  };

  return (
    <div className="pos-r">
      {isLoading && <LoadingSpinner position="absolute" />}
      <SuccessSend show={show} closeModal={closeModal} isBug={isBug} id={id} />
      <div className="p-sm-4 p-5">
        <CloseAction onClick={closeModal} disabled={show} />
        <h6>{isBug ? 'Segnalazione' : 'Suggerimento'}</h6>
        <p className="caption">
          {isBug
            ? "Descrivi il problema che hai riscontrato durante l'utilizzo.\nTi invitiamo di essere più esaustivo possibile, così da poterci aiutare più in fretta!"
            : 'Inviaci un suggerimento così da porterti offrire un servizio migliore. Ogni idea è molto apprezzata!'}
        </p>
        {isBug && (
          <div className="my-4">
            <InlineMessage
              type="info"
              icon={{ icon: faInfoCircle }}
              content={`Apriremo automaticamente una segnalazione con le informazioni da te compilate, per poterti aiutare al più presto.\n"Riceverai il codice di riferimento all'indirizzo email indicato.`}
            />
          </div>
        )}
        <div className={isBug ? '' : 'mt-4'} style={{ width: '250px' }}>
          <InputText
            label={'Email'}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            helper={emailError}
            autoFocus
            required
          />
        </div>
        <div className="my-3">
          <TextArea
            label={'Descrizione'}
            // rows={5}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            required
          />
        </div>

        {attachment ? (
          <LoadedAttachment fileName={attachment.name} unsetAttachment={() => setAttachment(undefined)} />
        ) : (
          <UploadFileButton setFiles={(files: File[]) => setAttachment(files[0])} maxFiles={1} />
        )}

        <div className="d-flex w-100 justify-content-end mt-4">
          <Button type="text" text="Annulla" onClick={closeModal} className="mr-3" />
          <Button
            type="primary"
            text="Invia"
            onClick={submitReport}
            color="green"
            // isDisabled={Boolean(emailError || !email || !message)}
          />
        </div>
      </div>
    </div>
  );
};

export const ModalContent = withAPILoading(ModalContentComponent);
