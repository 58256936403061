import { GretchOptions, GretchResponse } from 'gretchen';
import { PaginationType } from 'types/helpers';

export type GenericSelectInfo = { id: number; testo: string };

export enum HeaderTypeEnum {
  'fullLogin',
  'withPassedArchiveId',
  'attachment',
  'noArchiveId',
  'justContentType'
}

export enum FetchMethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export type HeaderConfig = {
  accessToken?: string;
  archiveId?: string;
  avoidAuthorization?: boolean;
  avoidArchiveId?: boolean;
  avoidContentTypeApplicationJson?: boolean;
};

export type APIRequestConfig = {
  method: FetchMethodEnum;
  headerConfig?: HeaderConfig;
  justResponse?: boolean;
  alreadyTriedToRefresh?: boolean;
};

export type APIError = {
  code: number;
  message: string;
  data?: unknown;
};

export type APIManagerError = APIError & Pick<Response, 'status'>;

export type APIRequestObj = {
  url: string;
  requestConfig: APIRequestConfig;
  additionalOpts?: GretchOptions;
};

export type APIInstance<T> = (obj: APIRequestObj) => Promise<GretchResponse<T, APIManagerError>>;

export type APIManagerResponse<T> = { data?: T; error?: APIManagerError; pagination?: PaginationType };
