import { CommonPartsCategoryEnum, CommonPartsTypeEnum } from 'types/commonParts';
import {
  faDatabase,
  faSatelliteDish,
  faTvRetro,
  faFireExtinguisher,
  faTachometerAlt,
  faPhoneIntercom,
  faFlower,
  faPlug,
  faSortCircle,
  faSwimmingPool,
  faTemperatureFrigid,
  faTemperatureHot,
  faDoorOpen,
  faSmoke,
  faSolarPanel,
  faChartNetwork,
  faDigitalTachograph,
  faSun,
  faRaindrops,
  faArrowAltDown,
  faParachuteBox,
  faPuzzlePiece,
  faTreeAlt,
  faDoorClosed,
  faStairs
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { IconWithBackground } from 'design-component/iconWithBackground';
import { FontSize } from 'types/designSystem';
import { isImplant } from 'routes/commonParts/utils';

const getImpiantoIcon = (type: CommonPartsTypeEnum) => {
  switch (type) {
    case CommonPartsTypeEnum.Addolcitore:
      return faDatabase;
    case CommonPartsTypeEnum['Antenna sat']:
      return faSatelliteDish;
    case CommonPartsTypeEnum['Antenna tv']:
      return faTvRetro;
    case CommonPartsTypeEnum.Antincendio:
      return faFireExtinguisher;
    case CommonPartsTypeEnum.Autoclave:
      return faTachometerAlt;
    case CommonPartsTypeEnum.Citofono:
      return faPhoneIntercom;
    case CommonPartsTypeEnum.Fognatura:
      return faFlower;
    case CommonPartsTypeEnum['Impianto elettrico']:
      return faPlug;
    case CommonPartsTypeEnum.Ascensore:
      return faSortCircle;
    case CommonPartsTypeEnum.Piscina:
      return faSwimmingPool;
    case CommonPartsTypeEnum.Raffrescamento:
      return faTemperatureFrigid;
    case CommonPartsTypeEnum.Riscaldamento:
      return faTemperatureHot;
    case CommonPartsTypeEnum['Cancello/accesso']:
      return faDoorOpen;
    case CommonPartsTypeEnum['Canna fumaria']:
      return faSmoke;
    case CommonPartsTypeEnum['Pannelli fotovoltaici']:
      return faSolarPanel;
    case CommonPartsTypeEnum['Rete acqua']:
    case CommonPartsTypeEnum['Rete gas-metano']:
      return faChartNetwork;
    case CommonPartsTypeEnum.IM_GENERICO:
      return faDigitalTachograph;
    case CommonPartsTypeEnum['Pannelli solari']:
      return faSun;
    case CommonPartsTypeEnum['Pompa sommersa']:
      return faRaindrops;
    case CommonPartsTypeEnum['Scarico verticale']:
      return faArrowAltDown;
    case CommonPartsTypeEnum['Sistema anticaduta']:
      return faParachuteBox;
    default:
      return faPuzzlePiece;
  }
};
const getAmbienteIcon = (type: CommonPartsTypeEnum) => {
  switch (type) {
    case CommonPartsTypeEnum.Giardino:
      return faTreeAlt;
    case CommonPartsTypeEnum.Portineria:
      return faDoorClosed;
    case CommonPartsTypeEnum.Scale:
      return faStairs;
    default:
    case CommonPartsTypeEnum.AM_GENERICO:
    case CommonPartsTypeEnum.Edificio:
      return faPuzzlePiece;
  }
};
export const getPartiComuniIcon = (type: CommonPartsTypeEnum, category: CommonPartsCategoryEnum) =>
  isImplant(category) ? getImpiantoIcon(type) : getAmbienteIcon(type);

export const partiComuniIconWithBackground = (
  type: CommonPartsTypeEnum,
  category: CommonPartsCategoryEnum,
  iconSize?: FontSize
) => (
  <IconWithBackground
    icon={getPartiComuniIcon(type, category)}
    color={isImplant(category) ? 'fuchsia' : 'cyan'}
    size={iconSize ?? '1x'}
    overrideBackgroundIntensity={100}
  />
);

export const ICON_PARTI_COMUNI = { getPartiComuniIcon, partiComuniIconWithBackground };
