import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { Pid } from 'types/account';
import { SendAssignmentRequestBody } from 'types/activity';

export const sendAssignment = async (assignmentInfo: SendAssignmentRequestBody) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(assignmentInfo) };
  const url = `/api/interventoFornitore`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const cancelAssignment = async (pid: Pid['pid'], interventionId: number, motivation: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ motivazione: motivation }) };
  const url = `/api/interventoFornitore/${pid}/${interventionId}/annulla`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const sollecitAssignment = async (pid: Pid['pid'], interventionId: number, motivation: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ motivazione: motivation }) };
  const url = `/api/interventoFornitore/${pid}/${interventionId}/sollecita`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const replayAssignment = async (pid: Pid['pid'], interventionId: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const url = `/api/interventoFornitore/${pid}/${interventionId}/riproponi`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const sendIntegrationWithFileForm = async (pid: number, interventionId: number, formData: FormData) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidContentTypeApplicationJson: true },
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: formData };
  const url = `/api/interventoFornitore/${pid}/${interventionId}/integrazioneFileForm`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export type IntegrationWithFileKeyBody = {
  messaggio: string;
  operatore: string;
  chiaveDownload: string;
};

export const sendIntegrationWithFileKey = async (
  pid: number,
  interventionId: number,
  bodyReq: IntegrationWithFileKeyBody
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(bodyReq) };
  const url = `/api/interventoFornitore/${pid}/${interventionId}/integrazioneChiaveFile`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};
