import React, { FC } from 'react';
import moment from 'moment';
import { faChevronRight, faFolderOpen, faFile } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import { defaultPalette } from 'palette';
import { Icon } from '@fattureincloud/fic-design-system';

type TRow = { onClick: () => void };
type Props = { title: string; subtitle?: string; length: number; type: 'origin' | 'dossier' } & TRow;

export const DossierRow: FC<Props> = ({ title, subtitle, length, type, onClick }) => (
  <Row onClick={onClick}>
    <div className="d-flex flex-column" style={{ maxWidth: `calc(80% - 8px)` }}>
      <p className="ellipsisText">{title}</p>
      {subtitle && <p className="caption font--grey">Data creazione:{moment(subtitle).format('L')}</p>}
    </div>
    <div className="d-flex align-items-center justify-content-between gap-3" style={{ maxWidth: `calc(20% - 8px)` }}>
      <div className="d-flex align-items-center gap-2">
        <Icon icon={type === 'origin' ? faFolderOpen : faFile} color="black" />
        <p>{length}</p>
      </div>
      <Icon icon={faChevronRight} color="black" />
    </div>
  </Row>
);

const Row = styled.div<TRow>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 16px;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.24), 0px 1px 3px rgba(51, 51, 51, 0.12);
  background-color: ${defaultPalette.white[100]};

  &:hover {
    background: ${defaultPalette['blue'][50]};
    border: 1px solid ${defaultPalette['blue'][300]};
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.1), 0px 2px 4px rgba(51, 51, 51, 0.15);
  }
`;
