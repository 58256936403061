import { ATTIVITA_FILTER_KEYS, FORNITORI_FILTER_KEYS } from 'constants/filters';
import { AdvancedFilters } from 'types';
import { StatusEnum } from 'types/activity';

export const paginationQueryString = (page: number, pageSize: number, orderBy?: string) => {
  const paginationFilter = new URLSearchParams();
  paginationFilter.set('pageNumber', page.toString());
  paginationFilter.set('pageSize', pageSize.toString());
  orderBy && paginationFilter.set('OrderBy', orderBy);
  return paginationFilter;
};

export const queryStringSearchFilters = (searchText: string) => {
  const searchFilter = new URLSearchParams();
  searchText && searchFilter.set('searchQuery', encodeURIComponent(searchText));
  return searchFilter;
};

export const queryStringObjVersion = (objVersion: number) => {
  const queryString = new URLSearchParams({ objVersion: objVersion.toString() });
  return queryString;
};

// Activities
export const activitiesStatusQueryString = (status: StatusEnum) => {
  const statusFilter = new URLSearchParams({ stato: status.toString() });
  return statusFilter;
};

export const createActivitiesQueryString = (filters: AdvancedFilters) => {
  const queryFilter = new URLSearchParams();
  ATTIVITA_FILTER_KEYS.forEach((key) => {
    if (filters[key]) {
      if (filters[key]?.value) {
        if (key === 'gestore' && filters['gestore']?.label) {
          queryFilter.set(key, String(filters[key]?.label));
        } else if (key === 'avvisi') {
          queryFilter.set('avvisi', String(filters[key]?.value === '0'));
        } else if (key === 'intervalloDate' && filters[key]?.label) {
          const dates = filters[key]?.value?.split(':');
          if (dates) {
            queryFilter.set('dataDa', dates[0]);
            queryFilter.set('dataA', dates[1]);
          }
        } else {
          queryFilter.set(key, String(filters[key]?.value));
        }
      }
    }
  });
  return queryFilter;
};

export const providerFiltersQueryString = (filters: AdvancedFilters) => {
  const queryFilter = new URLSearchParams();
  FORNITORI_FILTER_KEYS.forEach((key) => {
    if (filters[key]) {
      if (key === 'attivita') {
        queryFilter.set(key, String(filters[key]?.label));
      } else {
        queryFilter.set(key, String(filters[key]?.value));
      }
    }
  });

  return queryFilter;
};

export const URLSearchParamsByAdvancedFilters = (filters: AdvancedFilters) => {
  const queryFilter = new URLSearchParams();
  const keys = Object.keys(filters);
  keys.forEach((key) => {
    filters[key] && queryFilter.set(key, String(filters[key]?.value));
  });
  return queryFilter;
};
