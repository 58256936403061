export enum TipoUtente {
  'Admin' = 0,
  'User'
}

export type UtenteArchivio = {
  utenteID: number;
  login: string;
  tipo: TipoUtente;
  tempPasswordOn: boolean;
  email: string;
};
