import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { DOSSIER_PER_PAGE } from 'components/dossiers/components/dossierList';
import { GretchOptions } from 'gretchen';
import { Id } from 'types';
import { AttachmentPreview } from 'types/attachments';
import {
  DeleteDossierInfo,
  Dossier,
  DossierOrigin,
  EditDossierInfo,
  NewDossierAPIData,
  OriginTypeEnum
} from 'types/dossier';

export const getDossier = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/allegati/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Dossier>(obj);
};

export const getDossiers = async (id: Id['id'], type: OriginTypeEnum, currentOrigin: OriginTypeEnum, page: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/allegati?RiferimentoAllegato.ID=${id.toString()}&RiferimentoAllegato.Tipo=${type.toString()}&TipoOrigine=${currentOrigin.toString()}&PageNumber=${page.toString()}&PageSize=${DOSSIER_PER_PAGE}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Dossier[]>(obj);
};

export const createDossier = async (data: NewDossierAPIData) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/allegati';
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Dossier>(obj);
};

export const removeDossier = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/allegati/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const getOriginList = async (id: Id['id'], type: OriginTypeEnum) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/allegati/elencoOrigini?Id=${id.toString()}&Tipo=${type.toString()}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<DossierOrigin[]>(obj);
};

export const editDossierInfo = async (id: Id['id'], data: EditDossierInfo) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const url = `/api/allegati/${id.toString()}`;
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Dossier>(obj);
};

/**
 * @deprecated Abbiamo modificato la content disposition da AWS, quindi non scarichiamo più il blob
 */
export const downloadFile = async (id: Id['id'], downloadKey: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, justResponse: true };
  const url = `/api/allegati/${id.toString()}/file?nomeFile=${downloadKey}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const deleteFile = async (id: Id['id'], { chiaveDownload, codice, anno }: DeleteDossierInfo) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/allegati/${id.toString()}/file?ChiaveDownload=${chiaveDownload}&Codice=${codice}&Anno=${anno}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const uploadFile = async (id: Id['id'], file: File) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidContentTypeApplicationJson: true },
    justResponse: true
  };
  const url = `/api/allegati/${id.toString()}/file`;
  const additionalOpts: GretchOptions = { body: formData };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const getPreviewLink = async (id: Id['id'], chiaveDownload: string, downloadRequest: boolean) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const search = new URLSearchParams({ nomeFile: chiaveDownload });
  search.set('download', String(downloadRequest));
  const url = `/api/allegati/${id.toString()}/file/link?${search}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<AttachmentPreview>(obj);
};
