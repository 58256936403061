import { PageContext } from 'types';
import { ModalsActionNames } from 'actions/modals/types';
import { ProvidersActionNames, ProvidersTypes } from 'actions/providers/types';
import { Provider } from 'types/provider';
import { GeneralActionNames } from 'actions/general/types';
import { clearedEntityAdvancedFilters, setEntityAdvancedFilters } from 'utils/entityFilters';
import { INITIAL_PAGE_CONTEXT } from 'constants/page';
import { AuthActionNames } from 'actions/auth/types';

export type ProvidersReducer = {
  list: Provider[];
  currentProvider?: Provider;
  pageContext: PageContext;
};

export const initialState: ProvidersReducer = { list: [], pageContext: INITIAL_PAGE_CONTEXT };

export const providersReducer = (state: ProvidersReducer = initialState, action: ProvidersTypes): ProvidersReducer => {
  switch (action.type) {
    case ProvidersActionNames.SET_PROVIDER_LIST: {
      return { ...state, list: action.payload.list, pageContext: action.payload.pageContext };
    }
    case ProvidersActionNames.SET_CURRENT_PROVIDER:
      return { ...state, currentProvider: action.payload };

    case ProvidersActionNames.RESET_PROVIDER_DETAILS:
    case ModalsActionNames.OPEN_MODAL:
      return { ...state, currentProvider: undefined };

    case ProvidersActionNames.SET_PROVIDERS_ADVANCED_FILTER: {
      const advanced = setEntityAdvancedFilters(state.pageContext.filters.advanced, action.payload);
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, advanced }
        }
      };
    }

    case ProvidersActionNames.CLEAR_PROVIDERS_ADVANCED_FILTERS: {
      const advanced = clearedEntityAdvancedFilters(state.pageContext.filters.advanced);
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, advanced }
        }
      };
    }

    case GeneralActionNames.RESET_STATE:
    case AuthActionNames.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
