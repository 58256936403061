import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const red: color = {
  50: '#fcefee',
  100: '#f5c5c1',
  200: '#ef9f98',
  300: '#e97d74',
  400: '#e45f54',
  500: '#df4437',
  600: '#d32f22',
  700: '#ba291e',
  800: '#a4241a',
  900: '#902017'
};
