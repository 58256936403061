import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { CreaGruppo, CreaPalazzina, Gruppo, Palazzina } from 'types/condominio';

export const getPalazzine = async (condGenID: number, esercizioID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Palazzina[]>(obj);
};

export const getPalazzina = async (condGenID: number, esercizioID: number, palazzinaID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Palazzina>(obj);
};

export const addPalazzina = async (condGenID: number, esercizioID: number, palazzina: CreaPalazzina) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(palazzina) };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Palazzina>(obj);
};

export const editPalazzina = async (
  condGenID: number,
  esercizioID: number,
  palazzinaID: number,
  palazzina: CreaPalazzina
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(palazzina) };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Palazzina>(obj);
};

export const removePalazzina = async (condGenID: number, esercizioID: number, palazzinaID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const getGruppo = async (condGenID: number, esercizioID: number, palazzinaID: number, gruppoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}/gruppo/${gruppoID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Gruppo>(obj);
};

export const addGruppo = async (condGenID: number, esercizioID: number, palazzinaID: number, gruppo: CreaGruppo) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(gruppo) };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}/gruppo`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Gruppo>(obj);
};

export const editGruppo = async (
  condGenID: number,
  esercizioID: number,
  palazzinaID: number,
  gruppoID: number,
  gruppo: CreaGruppo
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(gruppo) };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}/gruppo/${gruppoID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Gruppo>(obj);
};

export const removeGruppo = async (condGenID: number, esercizioID: number, palazzinaID: number, gruppoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/palazzina/${palazzinaID}/gruppo/${gruppoID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
