import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { enableProductionServices } from 'utils/route';

const GTM_ID = 'GTM-NL7TWN';
const enableGTM = enableProductionServices() && window?.nativeInfo?.platform !== 'ios';

const tagManagerArgs: TagManagerArgs = { gtmId: GTM_ID };

export const initializeGTM = () => {
  if (enableGTM) {
    TagManager.initialize(tagManagerArgs);
  }
};
