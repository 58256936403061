import { AttachmentData } from 'components/attachmentData';
import React, { FC, PureComponent } from 'react';
import { faTrash, faFile } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner, faTimes, faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { Tkey, translate } from 'translations';
import { Button, Icon } from '@fattureincloud/fic-design-system';
import { api } from 'api';
import { Id } from 'types';
import { IconWithBackground } from 'design-component/iconWithBackground';
import { ColorsList } from 'types/designSystem';

enum UploadStatus {
  ready,
  start,
  success,
  error
}

enum FileStatus {
  wait,
  loading,
  done,
  fail
}

type UploadFile = { file: File; status: FileStatus };
type Props = {
  dossierID: Id['id'];
  onChangeFiles?: (value: boolean) => void;
  callbackBeforeUpload?: () => void;
  callbackAfterUpload?: () => void;
  callbackReturnReady?: () => void;
};
type State = { files: UploadFile[]; uploadStatus: UploadStatus; totalFilesUploaded: number };

export class UploadFileList extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { files: [], uploadStatus: UploadStatus.ready, totalFilesUploaded: 0 };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { onChangeFiles } = this.props;
    const { files } = this.state;
    if (onChangeFiles && files.length !== prevState.files.length) {
      onChangeFiles(Boolean(files.length));
    }
  }
  private uploadFiles = () => {
    const { callbackBeforeUpload, callbackAfterUpload } = this.props;
    const { files } = this.state;
    this.setState({ uploadStatus: UploadStatus.start, totalFilesUploaded: 0 }, async () => {
      callbackBeforeUpload && callbackBeforeUpload();
      for (let i = 0; i < files.length; i++) {
        await this.uploadFile(files[i].file, i);
      }
      this.setState((state) => ({
        uploadStatus: state.totalFilesUploaded === files.length ? UploadStatus.success : UploadStatus.error
      }));
      callbackAfterUpload && callbackAfterUpload();
    });
  };

  // At the moment the API is fixed
  private uploadFile = async (file: File, index: number) => {
    const { dossierID } = this.props;
    this.updateFileStatus(index, FileStatus.loading);
    const call = () => api.attachment.uploadFile(dossierID, file);
    const { data } = await call();
    this.updateFileStatus(index, data?.ok ? FileStatus.done : FileStatus.fail);
    data?.ok && this.setState((state) => ({ totalFilesUploaded: state.totalFilesUploaded + 1 }));
  };

  public setFiles = (files: File[]) => {
    const { files: oldFiles } = this.state;
    const currentFiles = files.map((f) => ({ file: f, status: FileStatus.wait }));
    this.setState({
      files: [...(oldFiles ?? []), ...currentFiles]
    });
  };

  private retryUpload = () => {
    const { files: oldFiles } = this.state;
    const failedFile = oldFiles.filter((f) => f.status !== FileStatus.done);
    this.setState({ files: failedFile }, () => this.uploadFiles());
  };

  private removeFile = (file: File) =>
    this.setState((state) => ({ files: state.files?.filter((o) => o.file !== file) }));

  private updateFileStatus = (index: number, status: FileStatus) => {
    const { files } = this.state;
    if (files && index >= 0 && index < files.length) {
      const tmp = [...files];
      tmp[index].status = status;
      this.setState({ files: tmp });
    }
  };

  private restoreFileToInitialState = () => {
    const { callbackReturnReady } = this.props;
    this.setState({ files: [], uploadStatus: UploadStatus.ready }, () => callbackReturnReady && callbackReturnReady());
  };

  render() {
    const { files, uploadStatus } = this.state;
    const isBtnDisabled = Boolean(uploadStatus !== UploadStatus.ready || files === undefined || files.length === 0);

    return uploadStatus === UploadStatus.ready ? (
      <div className="d-flex flex-column">
        {files?.map((o, i) => (
          <AttachmentData
            key={i}
            attachment={{ nomeFile: o.file.name, chiaveDownload: '', dimensione: o.file.size }}
            actions={[{ icon: faTrash, tooltip: translate('remove'), onClick: () => this.removeFile(o.file) }]}
          />
        ))}
        <div className="d-flex align-items-center justify-content-end my-2 ml-auto">
          <Button
            type="text"
            text="Rimuovi tutti"
            onClick={() => this.setState({ files: [] })}
            isDisabled={isBtnDisabled}
          />
          <Button text="Carica" onClick={this.uploadFiles} isDisabled={isBtnDisabled} />
        </div>
      </div>
    ) : (
      <UploadViewProcess {...this.state} loadOthers={this.restoreFileToInitialState} retryUpload={this.retryUpload} />
    );
  }
}

type UploadViewProcessProps = State & { loadOthers: () => void; retryUpload: () => void };

const UploadViewProcess: FC<UploadViewProcessProps> = (props) => {
  const { files, totalFilesUploaded, uploadStatus } = props;
  const { loadOthers, retryUpload } = props;

  const mapFileStatusToIcon = {
    [FileStatus.wait]: { icon: faFile, color: 'grey' as ColorsList },
    [FileStatus.loading]: { icon: faSpinner, color: 'blue' as ColorsList, spin: true },
    [FileStatus.done]: { icon: faCheck, color: 'green' as ColorsList },
    [FileStatus.fail]: { icon: faTimes, color: 'red' as ColorsList }
  };

  const mapStatusToTitleKey: { [key: number]: Tkey } = {
    [UploadStatus.start]: 'imUploadingYourFiles',
    [UploadStatus.error]: 'xFilesNotUploaded',
    [UploadStatus.success]: 'allTheFilesHaveBeenUploaded'
  };

  const mapStatusToIcon = {
    [UploadStatus.ready]: { icon: faSpinner, color: 'blue' as ColorsList, spin: true },
    [UploadStatus.start]: { icon: faSpinner, color: 'blue' as ColorsList, spin: true },
    [UploadStatus.success]: { icon: faCheck, color: 'green' as ColorsList },
    [UploadStatus.error]: { icon: faTimes, color: 'red' as ColorsList }
  };

  return (
    <div className="row no-gutters flex-row flex-sm-column">
      <div className="col-6 col-sm-12 d-flex flex-column align-items-center p-md-2 text--center">
        <IconWithBackground {...mapStatusToIcon[uploadStatus]} size="2x" />
        <h6>{translate(mapStatusToTitleKey[uploadStatus])}.</h6>
        <p>{translate('uploadedXFilesOnX', totalFilesUploaded.toString(), files.length.toString())}</p>
        {uploadStatus === UploadStatus.success && (
          <Button type="text" text={translate('uploadOthers')} onClick={loadOthers} />
        )}
        {uploadStatus === UploadStatus.error && (
          <div className="d-flex">
            <Button type="text" text="Riprova" onClick={retryUpload} className="mr-2" />
            <Button type="text" text={translate('uploadOthers')} onClick={loadOthers} />
          </div>
        )}
      </div>
      <div className="col-6 col-sm-12">
        {files.map((f, i) => (
          <div key={i} className="d-flex align-items-center p-2">
            <Icon {...mapFileStatusToIcon[f.status]} />
            <p className="ml-2 ellipsisText">{f.file.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
