import { InstalmentDetail, InstalmentDetailIDS, InstalmentFilter } from 'types/instalment';
import { InstalmentActionNames, SetInstalmentFilters, SetCurrentInstalment, SetCurrentInstalmentID } from './types';

export const setInstalmentFiltersAction = (data: InstalmentFilter): SetInstalmentFilters => ({
  type: InstalmentActionNames.SET_FILTERS,
  payload: data
});

export const setCurrentInstalmentAction = (instalment: InstalmentDetail | undefined): SetCurrentInstalment => ({
  type: InstalmentActionNames.SET_CURRENT_INSTALMENT,
  payload: instalment
});

export const setCurrentInstalmentIDSAction = (ids: InstalmentDetailIDS): SetCurrentInstalmentID => ({
  type: InstalmentActionNames.SET_CURRENT_INSTALMENT_IDS,
  payload: ids
});
