import { Icon, IconProps } from '@fattureincloud/fic-design-system';
import React, { FC, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { convertSize } from 'design-component/iconWithBackground/utils';
import { ColorsList, ColorValues, FontSize } from 'types/designSystem';
import { defaultPalette } from 'palette';
import { IsDisabled } from 'types/utils';

type Disabled = Partial<IsDisabled>;
type StyleProps = {
  size: FontSize;
  color?: ColorsList;
  overrideBackground?: ColorsList;
  overrideBackgroundIntensity?: ColorValues;
} & Disabled;

export type Props = IconProps & StyleProps;

const ContentWrapper = styled.div<StyleProps>`
  ${({ size, color, overrideBackground, overrideBackgroundIntensity, disabled }) => {
    const convertedSize = convertSize(size);
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(2 * ${convertedSize});
      min-width: calc(2 * ${convertedSize});
      height: calc(2 * ${convertedSize});
      width: calc(2 * ${convertedSize});
      border-radius: 50%;
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      &:hover {
        background: ${disabled
          ? 'unset'
          : defaultPalette[overrideBackground ?? color ?? 'grey'][overrideBackgroundIntensity ?? 50]};
      }
      ${disabled &&
      css`
        & > svg {
          cursor: not-allowed;
        }
      `}
    `;
  }}
`;

export const IconWithHoverEffect: FC<Props> = ({
  overrideBackground,
  overrideBackgroundIntensity,
  disabled,
  onClick,
  ...props
}) => (
  <ContentWrapper
    size={props.size}
    color={props.color}
    overrideBackground={overrideBackground}
    overrideBackgroundIntensity={overrideBackgroundIntensity}
    onClick={disabled ? undefined : (onClick as unknown as MouseEventHandler<HTMLDivElement>)}
    disabled={disabled}
  >
    <Icon {...props} color={disabled ? 'grey' : props.color} isDisabled={disabled} />
  </ContentWrapper>
);
