import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';

const getGeneralReducer = (state: RootState) => state.generalReducer;

export const generalReducer = createSelector(getGeneralReducer, (res) => res);

export const currentDb = createSelector(getGeneralReducer, (res) => res.currentDb);

export const currentCondominium = createSelector(getGeneralReducer, (res) => res.currentCondominium);

export const currentEsercizio = createSelector(getGeneralReducer, (res) => res.currentEsercizio);

export const isMobileScreen = createSelector(getGeneralReducer, (res) => res.viewportInfo.isMobileScreen);

export const phoneModel = createSelector(getGeneralReducer, (res) => res.phoneModel);

export const pid = createSelector(getGeneralReducer, (res) => res.MioCondominioInfo.pid);

export const changelogReady = createSelector(getGeneralReducer, (res) => res.changelogReady);

export const localUserSettings = createSelector(getGeneralReducer, (res) => res.localUserSettings);
