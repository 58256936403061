import { ActivitySyncMessages } from 'reducers/activitiesReducer/types';
import {
  ActivitiesActionNames,
  ResetActivityDetails,
  SetActivityList,
  ActivitiesSuccessPayload,
  SetCurrentActivity,
  UpdateLastActivityCreated,
  SetSyncInterventionMessages
} from './types';
import { Activity, StatusEnum } from 'types/activity';
import { Filter } from 'types';

export const setActivityListAction = (data: ActivitiesSuccessPayload): SetActivityList => ({
  type: ActivitiesActionNames.SET_ACTIVITY_LIST,
  payload: data
});

export const setCurrentActivityAction = (activity: Activity): SetCurrentActivity => ({
  type: ActivitiesActionNames.SET_CURRENT_ACTIVITY,
  payload: activity
});

// Activity filters
export const setActivitiesStatusFilter = (status: StatusEnum) => ({
  type: ActivitiesActionNames.SET_ACTIVITIES_FILTER_STATUS,
  payload: status
});

export const setActivitiesAdvancedFilter = (filterKey: string, filter: Filter | undefined) => ({
  type: ActivitiesActionNames.SET_ACTIVITIES_ADVANCED_FILTER,
  payload: { key: filterKey, filter }
});

export const clearActivitiesAdvancedFilters = () => ({ type: ActivitiesActionNames.CLEAR_ACTIVITIES_ADVANCED_FILTERS });

export const updateLastActivityCreatedAction = (newActivity?: Activity): UpdateLastActivityCreated => ({
  type: ActivitiesActionNames.UPDATE_LAST_ACTIVITY_CREATED,
  payload: newActivity
});

export const resetActivityDetailsAction = (): ResetActivityDetails => ({
  type: ActivitiesActionNames.RESET_ACTIVITY_DETAILS
});

// Sync interventions messages
export const setSyncIntegrationsMessagesAction = (payload: ActivitySyncMessages): SetSyncInterventionMessages => ({
  type: ActivitiesActionNames.SET_SYNC_INTERVENTIONS_MESSAGES,
  payload
});
