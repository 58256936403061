import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { PAGE_SIZE } from 'config/api';
import { EntityFilters } from 'types';
import { Condominio, CondominioBase } from 'types/condominio';
import { paginationQueryString, queryStringSearchFilters, URLSearchParamsByAdvancedFilters } from '../utils';

import * as assicurazione from './assicurazione';
import * as dati_catastali from './dati-catastali';
import * as esercizio from './esercizio';
import * as incarico from './incarico';
import * as registro_amministratore from './registro-amministratore';
import * as risorsa from './risorsa';

export const getList = async (page: number, filters: EntityFilters) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const pagination = paginationQueryString(page, PAGE_SIZE);
  const search = queryStringSearchFilters(filters.searchText);
  const advanced = URLSearchParamsByAdvancedFilters(filters.advanced);
  const queryString = new URLSearchParams({
    ...Object.fromEntries(pagination),
    ...Object.fromEntries(search),
    ...Object.fromEntries(advanced)
  });
  const url = `/api/condominio/lista?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<CondominioBase[]>(obj);
};

export const getDetails = async (id: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Condominio>(obj);
};

export { assicurazione, dati_catastali, esercizio, incarico, registro_amministratore, risorsa };
