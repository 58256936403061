import { Toast } from '@fattureincloud/fic-design-system';
import React from 'react';
import { translate } from 'translations';

export const showSuccessToast = (message?: string) => {
  Toast.success(<span>{message ?? translate('toastGenericSuccessMsg')}</span>);
};

export const showErrorToast = (message?: string) => {
  message ? Toast.error(<span>{message}</span>) : Toast.error(<span>{translate('toastGenericFailureMsg')}</span>);
};
