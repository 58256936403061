import { Id, BaseItem } from 'types';
import { Note } from 'types/entity';

export type IncaricoCondomino = Id &
  Note & {
    anagraficaIncarico: BaseItem;
    tipoIncarico: BaseItem;
  };

export type EditIncaricoCondomino = Partial<Note> & {
  anagraficaIncaricoId: number;
  tipoIncaricoId: number;
};

export type CreaIncaricoCondomino = Partial<EditIncaricoCondomino>;

export enum TipoAnagraficaEnum {
  AnagraficaCondominio = 1,
  Persona,
  Fornitore
}

export type AnagraficaIncarico = BaseItem & { tipoAnagrafica: TipoAnagraficaEnum };
