import { MOBILE_BOTTOMBAR_HEIGHT, TOPBAR_HEIGHT } from 'config/styleVariables';
import styled, { css } from 'styled-components';

type Props = { showMobileBottombar: boolean };

export const StyledMobileLayout = styled.div<Props>`
  ${({ showMobileBottombar }) => css`
    height: calc(100vh - ${TOPBAR_HEIGHT}px - ${showMobileBottombar ? MOBILE_BOTTOMBAR_HEIGHT : 0}px);
    max-height: calc(100vh - ${TOPBAR_HEIGHT}px - ${showMobileBottombar ? MOBILE_BOTTOMBAR_HEIGHT : 0}px);
    width: 100%;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
  `}
`;
