import React, { FC } from 'react';
import { AttachmentPreview } from 'types/attachments';
import { PageEmptySet } from '@fattureincloud/fic-design-system';
import { FileViewer } from './components/fileViewer';
import { faUnlink } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { closeAttachmentPreviewModalAction } from 'actions/modals';

export type AttachmentPreviewerProps = { source?: AttachmentPreview };

const AttachmentPreviewer: FC<AttachmentPreviewerProps> = ({ source }) => {
  const dispatch = useDispatch();

  return source ? (
    <FileViewer source={source} />
  ) : (
    <div className="d-flex align-items-center h-100">
      <PageEmptySet
        title="Errore"
        text="Ooops!...Qualcosa è andato storto durante il recupero dell'allegato."
        icon={{ icon: faUnlink }}
        action={{
          text: 'Chiudi anteprima',
          action: () => dispatch(closeAttachmentPreviewModalAction())
        }}
      />
    </div>
  );
};

export default AttachmentPreviewer;
