/**
 * RegExp
 */
/**
 * Vat regexp
 */
const onlyVatNumberRegExp = /^(\d{7,12})$/;
const startsWithLetterVatRegexp = /^([A-Z]{1}\d{8})$/;
const netherlandsVatRegexp = /^(\d{9}[B]\d{2})$/;
const endsWithLetterVatRegExp = /^(\d{8}[A-Z]{1})$/;
const franceVatRegExp = /^([A-HJ-NP-Z0-9]{2}[0-9]{8})$/;
const startsWithTwoAlphaNumericCharacthersVatRegExp = /^([A-Z0-9]{2}\d{9})$/;
const irelandRegExp = /^(([A-Z]?\d{7}[A-Z])|(\d[A-Z]\d{5}[A-Z]))$/;
export const vatNumberRegExp = new RegExp(
  `(${onlyVatNumberRegExp.source})|(${startsWithLetterVatRegexp.source})|(${netherlandsVatRegexp.source})|(${endsWithLetterVatRegExp.source})|(${franceVatRegExp.source})|(${startsWithTwoAlphaNumericCharacthersVatRegExp.source})|(${irelandRegExp.source})`
);
export const mailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegExp =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const onlyNumberRegExp = /^[0-9]*$/;
export const fiscCodeRegExp = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$|^(\d{11})$/;
export const capRegExp = /^[0-9]{5}$/;
export const provinceAcronymRegExp = /^([A-Z]{2})$/;
export const providerFiscCodeRegExp = new RegExp(`(${vatNumberRegExp.source})|(${fiscCodeRegExp.source})`);
export const codiceComuneRegexp = /^([A-Z]{1}\d{3})$/;
