import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { BaseItem } from 'types';
import { CreaRegistroAmministratoreCondomino, RegistroAmministratoreCondomino } from 'types/condominio';

export const getList = async (condGenID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/registroAmministratori`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<RegistroAmministratoreCondomino[]>(obj);
};

export const getDetails = async (condGenID: number, registroAmmistratoreID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/registroAmministratori/${registroAmmistratoreID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<RegistroAmministratoreCondomino>(obj);
};

export const add = async (condGenID: number, registroAmmistratore: CreaRegistroAmministratoreCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(registroAmmistratore) };
  const url = `/api/condominio/${condGenID}/registroAmministratori`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<RegistroAmministratoreCondomino>(obj);
};

export const edit = async (
  condGenID: number,
  registroAmmistratoreID: number,
  registroAmmistratore: CreaRegistroAmministratoreCondomino
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(registroAmmistratore) };
  const url = `/api/condominio/${condGenID}/registroAmministratori/${registroAmmistratoreID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<RegistroAmministratoreCondomino>(obj);
};

export const remove = async (condGenID: number, registroAmmistratoreID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/registroAmministratori/${registroAmmistratoreID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const getEventoRegistroAmministratore = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/eventoRegistroAmministratore';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<BaseItem[]>(obj);
};
