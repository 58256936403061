import { Toast } from '@fattureincloud/fic-design-system';
import { ModalsActionNames } from 'actions/modals/types';
import { MODAL_IDS } from 'config/modals';
import { ErrorsCodeEnum } from 'config/apiErrorCodes';
import { store } from 'router';
import { manageModalAction } from 'actions/modals';
import { APIManagerError } from 'apiManager/types';
import React from 'react';
import { translate } from 'translations';
import { logoutAction } from 'actions/auth';
import { unsetCurrentDbAction } from 'actions/general';
import { routeNames } from 'config/routesInMenu';
import { redirect } from 'react-router-dom';

export const genericAPIErrorNotify = (message?: string) =>
  Toast.error(<span>{translate('error')}</span>, { content: <span>{message ?? translate('error_999')}</span> });

export const showDBNotUpdatedModal = () =>
  store.dispatch(manageModalAction(ModalsActionNames.OPEN_MODAL, MODAL_IDS.DB_NOT_UPDATED));

export const apiErrorHandler = (error: APIManagerError) => {
  const { code, message, status } = error;
  switch (code) {
    case ErrorsCodeEnum.UNAUTHORIZED_INVALID_DB_PASSWORD:
    case ErrorsCodeEnum.UNAUTHORIZED_INVALID_GESTACC_PASSWORD: {
      if (status === 401) {
        store.dispatch(unsetCurrentDbAction());
        redirect(routeNames.home);
      } else {
        genericAPIErrorNotify(message);
      }
      break;
    }
    case ErrorsCodeEnum.MUST_UPDATE_DB_REVISION:
      return showDBNotUpdatedModal();
    case ErrorsCodeEnum.UNAUTHENTICATED:
      return store.dispatch(logoutAction('Logout - NOT Authenticated'));
    default:
      genericAPIErrorNotify(message);
  }
};
