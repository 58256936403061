import { validateField } from '.';
import { FieldErrorMessage, Validator } from './types';

export type Values = {
  value: string;
  validators: Validator[];
  isRequired?: boolean;
};

export type TValidatorOutput = FieldErrorMessage | undefined;

type TValidateForm = (values: Values[]) => TValidatorOutput;

const validateForm: TValidateForm = (values) => {
  const checkResult: TValidatorOutput[] = [];
  values.forEach((o) => {
    const error = validateField(o.value, o.validators, o.isRequired);
    if (error) {
      checkResult.push(error);
    }
  });
  return checkResult.length ? checkResult[0] : undefined;
};

export default validateForm;

type ValidationRule<T> = Omit<Values, 'value'> & { field: keyof T };
export type ValidationRules<T> = ValidationRule<T>[];
