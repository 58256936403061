import { RadioButtonGroup } from '@fattureincloud/fic-design-system';
import { setInstalmentFiltersAction } from 'actions/instalment';
import { setEntityDetailsAction } from 'actions/modals';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButtonGroupOnClick, RadioButtonGroupOptions } from 'types/designSystem';
import { GruppedTypeEnum } from 'types/instalment';
import { selectors } from 'utils/reduxSelector';

export const gruppedTypeFilterOptions: RadioButtonGroupOptions = [
  { key: GruppedTypeEnum.GroupedByAnagr, label: 'Anagrafica' },
  { key: GruppedTypeEnum.GroupedByUnita, label: 'Unità' }
];

export const GruppedTypeFilter: FC = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectors.instalmentSelector.instalmentFilters);
  const entityDetails = useSelector(selectors.modalSelector.entityDetails);
  const { gruppedTypeFilter } = filter;

  const handleOnClick: RadioButtonGroupOnClick = ({ key }) => {
    entityDetails && dispatch(setEntityDetailsAction(undefined));
    dispatch(setInstalmentFiltersAction({ ...filter, gruppedTypeFilter: key }));
  };

  return (
    <RadioButtonGroup options={gruppedTypeFilterOptions} value={gruppedTypeFilter} onClick={handleOnClick} inline />
  );
};
