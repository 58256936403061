import { FieldErrorMessage, ValidatorField } from './types';
import { translate } from 'translations';
import { TValidatorOutput, ValidationRules } from './validateForm';

const validateField: ValidatorField = (value, validators, required) => {
  let checkResult: FieldErrorMessage | undefined = undefined;

  if (!value) {
    if (required) {
      return { status: 'error', message: translate('errorMessageFormHasMissingRequired') };
    }
    return undefined;
  }

  validators.forEach((validator) => {
    const errorMessage = validator(value);
    if (errorMessage) {
      checkResult = errorMessage;
    }
  });
  return checkResult;
};

export default validateField;

export function fieldsValidatorHelper<T extends {}>(validationRules: ValidationRules<T>) {
  const list: Record<string, { validate: (value: string) => TValidatorOutput }> = {};
  validationRules.forEach((v) =>
    Object.assign(list, {
      [v.field]: { validate: (value: string) => validateField(value, v.validators, v.isRequired) }
    })
  );
  return list;
}
