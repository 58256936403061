import { SelectPalette } from '@fattureincloud/fic-design-system';
import { colors as defaultPalette } from 'palette/colors';

const selectPalette: SelectPalette = {
  normal: {
    background: defaultPalette.white[100],
    color: defaultPalette.black[100],
    iconColor: defaultPalette.black[100],
    borderColor: defaultPalette.grey[500]
  },
  normalFocused: {
    background: defaultPalette.white[100],
    color: defaultPalette.black[100],
    iconColor: defaultPalette.black[100],
    borderColor: defaultPalette.blue[300]
  },
  success: {
    background: defaultPalette.green[50],
    color: defaultPalette.black[100],
    iconColor: defaultPalette.green[900],
    borderColor: defaultPalette.green[300]
  },
  warning: {
    background: defaultPalette.yellow[50],
    color: defaultPalette.black[100],
    iconColor: defaultPalette.yellow[900],
    borderColor: defaultPalette.yellow[300]
  },
  error: {
    background: defaultPalette.red[50],
    color: defaultPalette.black[100],
    iconColor: defaultPalette.red[900],
    borderColor: defaultPalette.red[300]
  },
  disabled: {
    background: defaultPalette.grey[100],
    color: defaultPalette.black[16],
    iconColor: defaultPalette.black[16],
    borderColor: 'transparent'
  }
};

export default selectPalette;
