import { AuthActionNames, Logout, SetAccount, SetAuthorizationData, SetLoginToken } from './types';
import { AccountData, Authorization, LocalStorageAuthData } from 'types/account';

export const setAuthorizationDataAction = (data: Authorization): SetAuthorizationData => ({
  type: AuthActionNames.SET_AUTHORIZATION_DATA,
  payload: data
});

export const logoutAction = (payload: string): Logout => ({ type: AuthActionNames.LOGOUT, payload });

export const resetLoginStateAction = () => ({ type: AuthActionNames.RESET_LOGIN_STATE });

export const setAccountAction = (account: AccountData): SetAccount => ({
  type: AuthActionNames.SET_ACCOUNT,
  payload: account
});

export const setLoginTokenAction = (loginData: LocalStorageAuthData): SetLoginToken => ({
  type: AuthActionNames.SET_LOGIN_TOKEN,
  payload: loginData
});
