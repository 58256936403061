import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';

const getAuthReducer = (state: RootState) => state.authReducer;

const accessToken = createSelector(getAuthReducer, (res) => res.accessToken);
const refreshToken = createSelector(getAuthReducer, (res) => res.refreshToken);
const versione = createSelector(getAuthReducer, (res) => res.versione);
const permissions = createSelector(getAuthReducer, (res) => res.permissions);
const gestioneAccessi = createSelector(getAuthReducer, (res) => res.gestioneAccessi);
const authReducer = createSelector(getAuthReducer, (res) => res);
const username = createSelector(getAuthReducer, (res) => res.username);
const operator = createSelector(getAuthReducer, (res) => res.permissions?.login || res.username);

export const authSelector = {
  authReducer,
  accessToken,
  gestioneAccessi,
  operator,
  permissions,
  refreshToken,
  versione,
  username
};
