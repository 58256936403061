import { URLSearchParamsByAdvancedFilters, paginationQueryString, queryStringSearchFilters } from './utils';
import { APIManager } from 'apiManager';
import { GretchOptions } from 'gretchen';
import { EntityFilters, Id } from 'types';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { Contact, CreateContact } from 'types/contact';
import { Attachment } from 'types/attachments';
import { PAGE_SIZE } from 'config/api';

export const getList = async (page: number, filters: EntityFilters) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, justResponse: true };
  const { searchText, advanced } = filters;
  const pagination = paginationQueryString(page, PAGE_SIZE, 'descr');
  const baseFilters = queryStringSearchFilters(searchText);
  const fieldFilter = URLSearchParamsByAdvancedFilters(advanced);

  const queryString = new URLSearchParams({
    ...Object.fromEntries(pagination),
    ...Object.fromEntries(baseFilters),
    ...Object.fromEntries(fieldFilter)
  });
  const url = `/api/persona?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
// used for select
export const getContacts = async (searchText: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const pagination = paginationQueryString(1, PAGE_SIZE, 'descr');
  const baseFilters = queryStringSearchFilters(searchText);
  const fieldFilter = URLSearchParamsByAdvancedFilters({});

  const queryString = new URLSearchParams({
    ...Object.fromEntries(pagination),
    ...Object.fromEntries(baseFilters),
    ...Object.fromEntries(fieldFilter)
  });
  const url = `/api/persona?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Contact[]>(obj);
};

export const getContact = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/persona/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Contact>(obj);
};

export const getListBySearchQuery = async (input: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/persona?SearchQuery=${input}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Contact[]>(obj);
};

export const addContact = async (newContact: CreateContact) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newContact) };
  const url = '/api/persona';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Contact>(obj);
};

export const updateContact = async (data: Contact) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const url = `/api/persona/${data.id}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Contact>(obj);
};

export const deleteContact = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/persona/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const getAttachments = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, justResponse: true };
  const url = `/api/persona/${id}/allegati`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Attachment[]>(obj);
};
