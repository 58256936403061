import React, { lazy, PureComponent } from 'react';
import { Navigate } from 'react-router';
import { LoadingSpinner } from 'design-component/loadingSpinner';
import { accountManager } from 'utils/accountManager';
import { connector, Props, State, PrivateRouteCredentials } from './types';
import { api } from 'api';
import { publicRoutesNames } from 'config/routesInMenu';
import { withAPILoading } from 'HOC/withAPILoading';
import { isAllowedToUseApp } from 'utils/route';
import { AccountData, LocalStorageAuthData } from 'types/account';
import { IS_NATIVE_APP } from 'utils/mobile';
import { isEqual } from 'lodash';

const LazyAppUseNotAllowed = lazy(() => import('components/appUseNotAllowed'));
class AuthenticationWrapper extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { isAuth: undefined };
  }

  componentDidMount = async () => await this.checkAuth();

  componentWillUnmount = () => {
    this.props.resetPrivateRoutesState();
  };

  private checkAuth = async () => {
    const credentials = this.getCredentials();
    const isAuth = await this.isAuthenticated(credentials);
    this.setState({ isAuth }, () => !isAuth && accountManager.removeAllLoginData());
  };

  private getCredentials = (): PrivateRouteCredentials | undefined => {
    const refreshToken = accountManager.cookie.refreshToken.get();
    const credentials = accountManager.localStorage.credentials.get();
    if (!refreshToken || !credentials) {
      return undefined;
    }
    return { ...credentials, refreshToken };
  };

  private isAuthenticated = async (credentials: PrivateRouteCredentials | undefined) => {
    if (!credentials) {
      return false;
    }
    const call = () => api.authorization.checkIsLogged(credentials.accessToken);
    const { data: account } = await this.props.handleAPICall(call);
    if (account) {
      this.updateAccountIfNeeded(account);
      return true;
    }
    return false;
  };

  private updateAccountIfNeeded = (account: AccountData) => {
    const {
      authReducer: { versione, gestioneAccessi, permissions },
      setAccountAction
    } = this.props;
    if (
      versione !== account.versione ||
      gestioneAccessi !== account.gestioneAccessi ||
      (account.gestioneAccessi && !isEqual(permissions, account.gestioneAccessiData))
    ) {
      setAccountAction(account);
      this.updateCredential(account);
    }
  };

  private updateCredential = (account: AccountData) => {
    if (account.gestioneAccessiData?.login) {
      const {
        authReducer: { accessToken, username }
      } = this.props;
      const updatedCredentials: LocalStorageAuthData = {
        accessToken,
        username,
        login: account.gestioneAccessiData.login
      };
      accountManager.localStorage.credentials.set(updatedCredentials);
    }
  };

  public render = () => {
    const { authReducer, component: Component, isLoading } = this.props;
    const { isAuth } = this.state;

    const canUseApp = isAllowedToUseApp(authReducer);

    if (isAuth === undefined || isLoading) {
      return <LoadingSpinner position="fixed" />;
    }

    if (!isAuth) {
      return <Navigate to={IS_NATIVE_APP ? publicRoutesNames.mobileLogin : publicRoutesNames.login} />;
    }

    return canUseApp.allowed ? <Component /> : <LazyAppUseNotAllowed authCheck={canUseApp} />;
  };
}

export const PrivateRoute = withAPILoading(connector(AuthenticationWrapper));
