import { Id } from 'types';
import { DateTime } from './utils';

type Common = { descrizione?: string; numProgrStr?: string; importo?: number; pagato: boolean; dataPagamento?: string };

export type RitenutaAcconto = Id & Common;

export type FinancialMovement = Id &
  Common & {
    data?: DateTime;
    dataFatturazione?: DateTime;
    dataScadenza?: DateTime;
    detrazione: boolean;
    ritenutaAcconto?: RitenutaAcconto;
  };

export enum ReferenceTypeEnum {
  Servizio = 12
}

export type FinancialMovementsData = Id & { referenceType: ReferenceTypeEnum };
