import { APIManager } from 'apiManager';
import { GretchOptions } from 'gretchen';
import { ActivityIntervention, InterventionChangeDataInfo, SendAssignmentRequestBody } from 'types/activity';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { Id } from 'types';
import { sendAssignment } from './assignment';

export const add = async (id: Id['id'], providerId: number, newIntervention: ActivityIntervention) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newIntervention) };
  const url = `/api/attivita/${id}/fornitore/${providerId}/intervento`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

// specific api for just one use case in addIntervention modal
export const addInterventionAndSendAssignment = async (
  id: Id['id'],
  providerId: number,
  newIntervention: ActivityIntervention,
  assignmentInfo: SendAssignmentRequestBody
) => {
  const firstResponse = await add(id, providerId, newIntervention);
  const newInterventionPosted = firstResponse?.data
    ? ((await firstResponse.data.json()) as ActivityIntervention)
    : undefined;
  if (newInterventionPosted) {
    // now sent assignment with corrret data (current id is 0)
    const assignmentBindedToNewIntervention: SendAssignmentRequestBody = {
      ...assignmentInfo,
      interventoID: newInterventionPosted.id
    };
    return await sendAssignment(assignmentBindedToNewIntervention);
  }
  return firstResponse;
};

export const edit = async (
  id: Id['id'],
  providerId: number,
  interventionId: number,
  editedIntervention: ActivityIntervention
) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.PUT,
    headerConfig: {},
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify(editedIntervention) };
  const url = `/api/attivita/${id}/fornitore/${providerId}/intervento/${interventionId}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const deleteIntervention = async (
  id: Id['id'],
  providerId: number,
  interventionId: number,
  operator: string
) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.DELETE,
    headerConfig: {},
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ operatore: operator }) };
  const url = `/api/attivita/${id}/fornitore/${providerId}/intervento/${interventionId}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const setAsDone = async (
  id: Id['id'],
  providerId: number,
  interventionId: number,
  date: string,
  operator: string
) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.PUT,
    headerConfig: {},
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ operatore: operator, dataEsecuzione: date }) };
  const url = `/api/attivita/${id}/fornitore/${providerId}/intervento/${interventionId}/svolgi`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const changeInterventionData = async (
  id: Id['id'],
  providerId: number,
  interventionId: number,
  interventionChangeDataInfo: InterventionChangeDataInfo
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {}, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(interventionChangeDataInfo) };
  const url = `/api/attivita/${id}/fornitore/${providerId}/intervento/${interventionId}/dataPrevista`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};
