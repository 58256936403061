import { combineReducers, Action } from 'redux';
import { authReducer } from './authReducer';
import { AuthReducer } from './authReducer/types';
import { ProvidersReducer, providersReducer } from './providersReducer';
import { ModalsReducer, modalsReducer } from './modalsReducer';
import { GeneralReducer, generalReducer } from './generalReducer';
import { ContactsReducer, contactsReducer } from './contactsReducer';
import { activitiesReducer } from './activitiesReducer';
import { AuthActionNames } from 'actions/auth/types';
import { ActivitiesReducer } from './activitiesReducer/types';
import { commonPartsReducer, CommonPartsReducer } from './commonPartsReducer';
import { instalmentReducer, InstalmentReducer } from './instalmentReducer';
import { condominiReducer, CondominiReducer } from './condominiReducer';

export type RootState = {
  activitiesReducer: ActivitiesReducer;
  authReducer: AuthReducer;
  condominiReducer: CondominiReducer;
  contactsReducer: ContactsReducer;
  commonPartsReducer: CommonPartsReducer;
  generalReducer: GeneralReducer;
  instalmentReducer: InstalmentReducer;
  modalsReducer: ModalsReducer;
  providersReducer: ProvidersReducer;
};

const allReducers = combineReducers({
  activitiesReducer,
  authReducer,
  contactsReducer,
  condominiReducer,
  commonPartsReducer,
  generalReducer,
  instalmentReducer,
  modalsReducer,
  providersReducer
});

/**
 * Reset all reducer to initial state after logout action
 */
export const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === AuthActionNames.LOGOUT) {
    state = undefined;
  }
  return allReducers(state, action);
};
