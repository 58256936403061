import React, { FC } from 'react';
import style from './style.module.scss';
import { Icon } from '@fattureincloud/fic-design-system';
import { translate } from 'translations';
import Dropzone, { DropzoneOptions, FileRejection } from 'react-dropzone';
import { faUpload } from '@fortawesome/pro-duotone-svg-icons';

type Props = {
  setFile?: (value: File) => void;
  setFiles?: (value: File[]) => void;
  config?: Omit<DropzoneOptions, 'maxFiles'>;
};

export const UploadFileArea: FC<Props> = ({ config, setFile, setFiles }) => {
  const handleDrop = (acceptedFiles: File[], rejectedFile: FileRejection[]) => {
    if (setFile) {
      if (!acceptedFiles.length && rejectedFile.length) {
        // case try to add more than 1 file
        setFile(rejectedFile[0].file);
      }
      if (acceptedFiles.length) {
        setFile(acceptedFiles[0]);
      }
    }
  };

  return (
    <Dropzone {...config} maxFiles={setFiles ? 0 : 1} onDrop={setFiles ?? handleDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div className={style.container} {...getRootProps()}>
          <div className={`${style.dropArea} ${isDragActive ? style.active : ''}`}>
            <div className={style.content}>
              {/* Content */}
              <div>
                <Icon icon={faUpload} color={isDragActive ? 'grey' : 'blue'} size="3x" className={style.icon} />
                <p className={style.title}>
                  {isDragActive ? 'Rilascia qui il file per caricarlo' : 'Trascina qui il file'}
                </p>
                {!isDragActive && (
                  <p
                    className="font--grey text--italic"
                    dangerouslySetInnerHTML={{ __html: translate('orChooseAFileToUpload') }}
                  />
                )}
              </div>
            </div>
          </div>
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
};
