import {
  activitiesStatusQueryString,
  createActivitiesQueryString,
  paginationQueryString,
  queryStringSearchFilters
} from '../utils';
import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { Id } from 'types';
import { Activity, CreateActivity, StatusEnum } from 'types/activity';
import * as activityAssignment from './assignment';
import * as activityIntervention from './intervention';
import * as activityEvent from './event';
import { Operator, Pid } from 'types/account';
import { InterventionMessagesSuccessResponse } from 'actions/activities/types';
import { ActivityFilter } from 'reducers/activitiesReducer/types';
import { PAGE_SIZE } from 'config/api';

export const assignment = activityAssignment;
export const intervention = activityIntervention;
export const event = activityEvent;

export const getList = async (page: number, filters: ActivityFilter) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, justResponse: true };
  const { searchText, advanced, activityStatus } = filters;
  const pagination = paginationQueryString(page, PAGE_SIZE);
  const searchFilter = queryStringSearchFilters(searchText);
  const activityFilter = createActivitiesQueryString(advanced);
  const statusFilter = activitiesStatusQueryString(activityStatus);
  const queryString = new URLSearchParams({
    ...Object.fromEntries(pagination),
    ...Object.fromEntries(searchFilter),
    ...Object.fromEntries(activityFilter),
    ...Object.fromEntries(statusFilter)
  });
  const url = `/api/attivita?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const getActivity = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const url = `/api/attivita/${id}/open`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Activity>(obj);
};

export const addActivity = async (activity: CreateActivity) => {
  const newActivity = { ...activity, dataApertura: new Date() };
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newActivity) };
  const url = `/api/attivita`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Activity>(obj);
};

export const updateActivity = async (activity: Activity & Operator) => {
  const { id, ...rest } = activity;
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(rest) };
  const url = `/api/attivita/${id}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Activity>(obj);
};

export const deleteActivity = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/attivita/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const changeActivityState = async (id: Id['id'], activityState: StatusEnum, operator: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ stato: activityState, operatore: operator }) };
  const url = `/api/attivita/${id}/stato`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const changeActivityManager = async (id: Id['id'], activityManger: string, operator: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ gestore: activityManger, operatore: operator }) };
  const url = `/api/attivita/${id}/gestore`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const syncIntegrationsMessages = async (pid: Pid['pid']) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.GET,
    headerConfig: { avoidContentTypeApplicationJson: true }
  };
  const url = `/api/interventoFornitore/${pid}/messaggi`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<InterventionMessagesSuccessResponse>(obj);
};
