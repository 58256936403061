import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const pink: color = {
  50: '#fbeaf6',
  100: '#f7d2ec',
  200: '#f2b7e0',
  300: '#ec96d2',
  400: '#e569bf',
  500: '#d50d99',
  600: '#c00b8a',
  700: '#a80a79',
  800: '#8c0864',
  900: '#640648'
};
