import { ProvidersActionNames, SetCurrentProvider, SetProviderList } from './types';
import { Provider } from 'types/provider';
import { EntityListWithRouteContext } from 'types/entity';
import { Filter } from 'types';

export const setProviderListAction = (data: EntityListWithRouteContext<Provider>): SetProviderList => ({
  type: ProvidersActionNames.SET_PROVIDER_LIST,
  payload: data
});

export const setCurrentProviderAction = (contact: Provider): SetCurrentProvider => ({
  type: ProvidersActionNames.SET_CURRENT_PROVIDER,
  payload: contact
});

export const setProvidersAdvancedFilter = (filterKey: string, filter: Filter | undefined) => ({
  type: ProvidersActionNames.SET_PROVIDERS_ADVANCED_FILTER,
  payload: { key: filterKey, filter }
});

export const clearProvidersAdvancedFilters = () => ({ type: ProvidersActionNames.CLEAR_PROVIDERS_ADVANCED_FILTERS });
