import React, { FC } from 'react';

import { AttachmentPreviewerProps } from '..';

type Props = Required<AttachmentPreviewerProps>;

export const FileViewer: FC<Props> = ({ source: { link } }) => (
  <iframe
    src={link}
    title="preview"
    id="previewID"
    frameBorder={0}
    marginHeight={0}
    marginWidth={0}
    height="100%"
    width="100%"
  />
);
