import { Button, Modal, ThemeProvider } from '@fattureincloud/fic-design-system';
import React, { Component } from 'react';
import { translate } from 'translations';
import { Router } from './router';
import { register, unregister } from './serviceWorker';
import { theme } from './palette';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { enableProductionServices, isProductionVersion } from 'utils/route';
import { initializeGTM } from 'googleTagManager';

type State = { showUpdateModal: boolean; waitingServiceWorker: ServiceWorker | null };

type ServiceWorkerEvent = Event & { target: (Partial<ServiceWorker> & EventTarget) | null };

// this class manage the service worker
export class App extends Component<{}, State> {
  private enableProductionServices: boolean;
  constructor(props: {}) {
    super(props);
    this.state = { showUpdateModal: false, waitingServiceWorker: null };
    this.enableProductionServices = enableProductionServices();
  }

  private preventDefault = (event: Event) => {
    event.preventDefault();
    return null;
  };

  private removeNoIndex = () => {
    const metaTag = document.querySelector(`meta[name="robots"]`);
    metaTag && metaTag.remove();
  };

  componentDidMount() {
    initializeGTM();
    // Remove index on production
    isProductionVersion() && this.removeNoIndex();
    if (this.enableProductionServices) {
      window.addEventListener('beforeinstallprompt', this.preventDefault);
      register({ onUpdate: this.onServiceWorkerUpdate });
      this.keepWatchingSW();
    } else {
      unregister();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.preventDefault);
  }

  // every hour check for a new app version
  private keepWatchingSW = () => {
    window.setInterval(() => {
      navigator?.serviceWorker?.getRegistrations().then((registrationsArray) => registrationsArray[0].update());
    }, 1000 * 60 * 60);
  };

  private onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    this.setState(
      {
        waitingServiceWorker: registration?.waiting,
        showUpdateModal: true
      },
      () => {
        const { waitingServiceWorker } = this.state;
        waitingServiceWorker?.addEventListener('statechange', (event: ServiceWorkerEvent) => {
          if (event?.target?.state === 'activated') {
            window.location.reload();
          }
        });
      }
    );
  };

  private updateServiceWorker = () => {
    const { waitingServiceWorker } = this.state;
    waitingServiceWorker?.postMessage({ type: 'SKIP_WAITING' });
    this.setState({ showUpdateModal: false });
  };

  render() {
    const { showUpdateModal } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Modal isOpen={showUpdateModal} isSmall className="new-Version-Modal">
          <h6>{translate('newVersionAvailable')}</h6>
          <p className="mb-3">{translate('newVersionAvailableContent')}</p>
          <Button
            iconRight={{ icon: faArrowUp }}
            text={translate('update')}
            color="blue"
            onClick={() => this.updateServiceWorker()}
            fullWidth
            type="secondary"
          />
        </Modal>
        <Router />
      </ThemeProvider>
    );
  }
}
