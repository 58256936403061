import { MOBILE_WIDTH_BREAKPOINT } from 'config/mobile';
import { generalListener } from 'middlewares/generalListener';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { accountManager } from 'utils/accountManager';
import { authListener } from './middlewares/authListener';
import { RootState, rootReducer } from './reducers/rootReducer';
import { authorizationInitialState } from 'reducers/authReducer';
import { isDevelopmentVersion } from 'utils/route';
import { initialState as activityInitialState } from 'reducers/activitiesReducer/types';
import { initialState as condominiInitialState } from 'reducers/condominiReducer';
import { initialState as commonPartsInitialState } from 'reducers/commonPartsReducer';
import { initialState as contactInitialState } from 'reducers/contactsReducer';
import { initialState as instalmentInitialState } from 'reducers/instalmentReducer';
import { initialState as modalsInitialState } from 'reducers/modalsReducer';
import { initialState as providersInitialState } from 'reducers/providersReducer';

const composeEnhancers = composeWithDevTools({ trace: isDevelopmentVersion(), traceLimit: 25 });

export default function configureStore(preloadedState: RootState): Store {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(generalListener, authListener))
  );

  return store;
}

export const getInitialData = () => {
  const archive = accountManager.localStorage.archive.get();
  const credentials = accountManager.localStorage.credentials.get();
  const refreshToken = accountManager.cookie.refreshToken.get();
  const localUserSettings = accountManager.localStorage.localUserSettings.get();
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isMobileScreen = width < MOBILE_WIDTH_BREAKPOINT;

  const initialData: RootState = {
    authReducer: authorizationInitialState(credentials?.accessToken, refreshToken, credentials?.username),
    generalReducer: {
      currentDb: archive,
      viewportInfo: { width, height, isMobileScreen },
      localUserSettings: localUserSettings ?? { appereance: { sidebarExpanded: false } },
      phoneModel: '',
      refreshRouteData: 0,
      refreshEntityDetailsData: 0,
      changelogReady: false,
      MioCondominioInfo: { pid: 0, mioCondominioPremiumAbilitato: false }
    },
    activitiesReducer: activityInitialState,
    commonPartsReducer: commonPartsInitialState,
    condominiReducer: condominiInitialState,
    contactsReducer: contactInitialState,
    instalmentReducer: instalmentInitialState,
    modalsReducer: modalsInitialState,
    providersReducer: providersInitialState
  };
  return initialData;
};
