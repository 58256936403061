import { PageContext } from 'types';
import { CommonParts, Environment, Implant } from 'types/commonParts';
import { CommonPartsActionNames, CommonPartsTypes } from 'actions/commonParts/types';
import { AuthActionNames } from 'actions/auth/types';
import { INITIAL_PAGE_CONTEXT } from 'constants/page';
import { GeneralActionNames } from 'actions/general/types';

export type CommonPartsReducer = {
  currentCommonParts?: CommonParts;
  implant?: Implant;
  environment?: Environment;
} & Pick<PageContext, 'filters'>;

export const initialState: CommonPartsReducer = { filters: INITIAL_PAGE_CONTEXT.filters };

export const commonPartsReducer = (
  state: CommonPartsReducer = initialState,
  action: CommonPartsTypes
): CommonPartsReducer => {
  switch (action.type) {
    case CommonPartsActionNames.SET_CURRENT_COMMON_PARTS:
      return { ...state, currentCommonParts: action.payload };

    case CommonPartsActionNames.SET_FILTERS:
      return { ...state, filters: action.payload };

    case CommonPartsActionNames.SET_ENVIRONMENT:
      return { ...state, environment: action.payload, implant: undefined };

    case CommonPartsActionNames.SET_IMPLANT:
      return { ...state, implant: action.payload, environment: undefined };

    case GeneralActionNames.RESET_STATE:
    case AuthActionNames.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
