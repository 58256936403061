import { TooltipPalette } from '@fattureincloud/fic-design-system';
import { colors as defaultPalette } from 'palette/colors';

const tooltipPalette: TooltipPalette = {
  light: {
    color: defaultPalette.black[100],
    background: defaultPalette.white[100]
  },
  dark: {
    color: defaultPalette.white[100],
    background: defaultPalette.grey[900]
  }
};

export default tooltipPalette;
