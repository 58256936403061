import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { defaultPalette } from 'palette';
import { Button, Icon, MicroTag } from '@fattureincloud/fic-design-system';
import { faLightbulbOn, faDebug } from '@fortawesome/pro-regular-svg-icons';
import { Id } from 'types';

type Props = { closeModal: () => void; isBug: boolean; show: boolean } & Id;

const slideInEllipticBottomFwd = keyframes`
0%{transform:translateY(600px) rotateX(30deg) scale(0);transform-origin:50% 100%;opacity:0}
100%{transform:translateY(0) rotateX(0) scale(1);transform-origin:50% -1400px;opacity:1}}
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(204, 204, 204, 0.6);
  z-index: 1;
`;

const Container = styled.div`
  position: absolute;
  min-height: 180px;
  min-width: 180px;
  max-width: 240px;
  margin: 0;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
  background-color: ${defaultPalette.white[100]};
  border-radius: 16px;
  border: 1px solid ${defaultPalette.grey[200]};
  z-index: 1;
  animation-name: ${slideInEllipticBottomFwd};
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const SuccessSend: FC<Props> = ({ id, show, isBug, closeModal }) =>
  show ? (
    <Overlay>
      <Container>
        <div>
          <h6>{isBug ? 'Segnalazione creata!' : 'Grazie per il tuo suggerimento!'}</h6>
          {isBug && (
            <>
              <p className="caption my-1">È stata inviata un&apos;e-mail con il riepilogo delle informazioni.</p>
              <MicroTag type="info" text={`Codice di riferimento: ${id}`} />
            </>
          )}
        </div>
        <Icon icon={isBug ? faDebug : faLightbulbOn} color="blue" size="4x" />
        <Button text="Chiudi" onClick={closeModal} />
      </Container>
    </Overlay>
  ) : null;
