import { AttachmentPreview } from 'types/attachments';
import { ModalDossierData } from 'types/dossier';
import { EntityDetails } from 'types/entity';
import { FinancialMovementsData } from 'types/financialMovements';
import { ModalReportData } from 'types/report';
import { ModalsActionNames, ModalsActionsTypes } from '../actions/modals/types';
import { MODAL_IDS } from '../config/modals';

export type ModalIDSStatus = { [key in MODAL_IDS]: boolean };
export type ModalsReducer = {
  modalIdsStatus: ModalIDSStatus;
  entityDetails?: EntityDetails;
  dossierModalData?: ModalDossierData;
  attachmentPreviewData?: AttachmentPreview;
  reportModalData?: ModalReportData;
  financialMovementsData?: FinancialMovementsData;
};

export const initialState: ModalsReducer = {
  modalIdsStatus: Object.keys(MODAL_IDS).reduce((obj, o) => {
    obj[o as keyof typeof MODAL_IDS] = false;
    return obj;
  }, {} as ModalIDSStatus)
};

export const modalsReducer = (state: ModalsReducer = initialState, action: ModalsActionsTypes): ModalsReducer => {
  switch (action.type) {
    case ModalsActionNames.RESET_MODALS:
      return { ...state, modalIdsStatus: { ...initialState.modalIdsStatus } };
    case ModalsActionNames.OPEN_MODAL:
      return { ...state, modalIdsStatus: { ...state.modalIdsStatus, [action.payload]: true } };

    case ModalsActionNames.CLOSE_MODAL:
      return { ...state, modalIdsStatus: { ...state.modalIdsStatus, [action.payload]: false } };

    case ModalsActionNames.OPEN_NEW_DOSSIER_MODAL:
    case ModalsActionNames.OPEN_DOSSIER_MODAL: {
      const { id, data } = action.payload;
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [id]: true },
        dossierModalData: data
      };
    }

    case ModalsActionNames.CLOSE_DOSSIER_MODAL:
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [MODAL_IDS.SHOW_DOSSIER]: false },
        dossierModalData: undefined
      };
    case ModalsActionNames.CLOSE_NEW_DOSSIER_MODAL:
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [MODAL_IDS.SHOW_NEW_DOSSIER]: false },
        dossierModalData: undefined
      };

    case ModalsActionNames.OPEN_ATTACHMENT_PREVIEW_MODAL: {
      const { data } = action.payload;
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [MODAL_IDS.ATTACHMENT_PREVIEW]: true },
        attachmentPreviewData: data
      };
    }

    case ModalsActionNames.CLOSE_ATTACHMENT_PREVIEW_MODAL:
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [MODAL_IDS.ATTACHMENT_PREVIEW]: false },
        attachmentPreviewData: undefined
      };

    case ModalsActionNames.SET_ENTITY_DETAILS:
      return { ...state, entityDetails: action.payload };

    case ModalsActionNames.OPEN_REPORT_MODAL:
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [MODAL_IDS.SHOW_REPORT_MODAL]: true },
        reportModalData: { type: action.payload }
      };
    case ModalsActionNames.CLOSE_REPORT_MODAL:
      return {
        ...state,
        modalIdsStatus: { ...state.modalIdsStatus, [MODAL_IDS.SHOW_REPORT_MODAL]: false },
        reportModalData: undefined
      };

    case ModalsActionNames.SET_FINANCIAL_MOVEMENT:
      return { ...state, financialMovementsData: action.payload };

    default:
      return state;
  }
};
