import { EntityFilters, BaseItem } from 'types';
import { CondominioBase } from 'types/condominio';
import { CondominioActionNames, SetCurrentCondominio, SetCondominioFilters, SetCurrentEsercizio } from './types';

export const setCondominiFiltersAction = (data: EntityFilters): SetCondominioFilters => ({
  type: CondominioActionNames.SET_FILTERS,
  payload: data
});

export const setCurrentCondominioAction = (data: CondominioBase | undefined): SetCurrentCondominio => ({
  type: CondominioActionNames.SET_CURRENT_CONDOMINIO,
  payload: data
});

export const setCurrentEsercizioAction = (data: BaseItem | undefined): SetCurrentEsercizio => ({
  type: CondominioActionNames.SET_CURRENT_ESERCIZIO,
  payload: data
});
