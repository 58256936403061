import { connect, ConnectedProps } from 'react-redux';
import { setAccountAction } from 'actions/auth';
import { RootState } from 'reducers/rootReducer';
import { WithAPILoadingInjectedProps } from 'HOC/withAPILoading';
import { LocalStorageAuthData, RefreshToken } from 'types/account';
import { ComponentType } from 'react';
import { resetPrivateRoutesState } from 'actions/general';

const mapStateToProps = ({ authReducer }: RootState) => ({ authReducer });

const mapDispatchToProps = { setAccountAction, resetPrivateRoutesState };

export const connector = connect(mapStateToProps, mapDispatchToProps);

type ComponentProps = { component: ComponentType };
type ExternalProps = ConnectedProps<typeof connector> & WithAPILoadingInjectedProps;

export type Props = ExternalProps & ComponentProps;
export type State = { isAuth: boolean | undefined };

export type PrivateRouteCredentials = LocalStorageAuthData & RefreshToken;
