import React, { FC } from 'react';
import { Button, ButtonProps } from '@fattureincloud/fic-design-system';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';

type Props = { setFiles: (value: File[]) => void; btnConfig?: ButtonProps } & Pick<DropzoneProps, 'maxFiles'>;
const defaultBtnProps: ButtonProps = { text: 'Carica file', iconLeft: { icon: faPaperclip } };

export const UploadFileButton: FC<Props> = ({ setFiles, maxFiles, btnConfig = defaultBtnProps }) => (
  <Dropzone
    noDrag={true}
    onDrop={setFiles}
    disabled={btnConfig.isDisabled}
    maxFiles={maxFiles}
    multiple={Boolean(maxFiles !== 1)}
  >
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()}>
        <Button {...btnConfig} />
        <input {...getInputProps()} />
      </div>
    )}
  </Dropzone>
);
