import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import {
  CondominiumInfo,
  CreaRisorsa,
  RisorsaCondominio,
  RisorseCondominioResponseFull,
  RisorseCondominioResponseLight
} from 'types/condominio';

import * as fondo from './fondo';
import { GretchOptions } from 'gretchen';
import { BaseItem } from 'types';
export { fondo };

export const getRisorseLight = async (condGenID: CondominiumInfo['condGenID']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const searchParam = new URLSearchParams({ versione: '1' });
  const url = `/api/condominio/${condGenID}/risorsa?${searchParam}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<RisorseCondominioResponseLight>(obj);
};

export const getTipiRisorsa = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/tipoRisorsa';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<BaseItem[]>(obj);
};

export const getRisorseFull = async (condGenID: CondominiumInfo['condGenID']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const searchParam = new URLSearchParams({ versione: '2' });
  const url = `/api/condominio/${condGenID}/risorsa?${searchParam}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<RisorseCondominioResponseFull>(obj);
};

export const getRisorsa = async (condGenID: CondominiumInfo['condGenID'], id: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/risorsa/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<RisorsaCondominio>(obj);
};

export const addRisorsa = async (condGenID: number, risorsa: CreaRisorsa) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(risorsa) };
  const url = `/api/condominio/${condGenID}/risorsa`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<RisorsaCondominio>(obj);
};

export const editRisorsa = async (condGenID: number, risorsaID: number, risorsa: CreaRisorsa) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(risorsa) };
  const url = `/api/condominio/${condGenID}/risorsa/${risorsaID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<RisorsaCondominio>(obj);
};

export const removeRisorsa = async (condGenID: number, risorsaID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/risorsa/${risorsaID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
