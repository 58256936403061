import { CondominiReducer } from 'reducers/condominiReducer';
import { Id, BaseItem } from 'types';
import { DateTime } from 'types/utils';
import { CondominiumInfo } from '../condominio';

export type CurrentEsercizio = Required<Pick<CondominiReducer, 'currentEsercizio'>>;

export type Esercizio = Id &
  Pick<CondominiumInfo, 'condGenID'> & {
    descrizione?: string;
    tipo: EsercizioTypeEnum;
    dataApertura: DateTime;
    dataChiusura: DateTime;
  };

export type EsercizioDetails = Esercizio & {
  aperto: boolean;
  note?: string;
  contoCorrente?: BaseItem;
};

export enum StatusEserciziEnum {
  Tutti = 0,
  Aperti,
  Chiusi
}

export enum EsercizioTypeEnum {
  Ordinario = 0,
  Straordinario,
  Ausiliario
}

export type EsercizioDescr = Id & { descr?: string; coordinateBancarie?: string };

export enum EsercizioStatoAccountTSPayEnum {
  Errore,
  CondominioNonCollegato,
  AccountAttivo,
  AccountNonAttivo,
  AccountSospeso
}

export type EsercizioSaldoTSPay = {
  stato: EsercizioStatoAccountTSPayEnum;
  importoUltimoSaldo?: number;
  dataUltimoSaldo?: DateTime;
  descrError?: string;
  tsPayDashboardUrl?: string;
};
