import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';
import { FirebaseNotificationController } from './notification';
import 'firebase/messaging';
import { useFirebaseMobile, useFirebaseDesktop } from './hook';
import { HookProps } from './types';

export const FirebaseManager: FC = () => {
  const accessToken = useSelector(selectors.authSelector.accessToken);
  const refreshToken = useSelector(selectors.authSelector.refreshToken);
  const hookProps: HookProps = { accessToken, refreshToken };

  useFirebaseMobile(hookProps);
  const messagingInstance = useFirebaseDesktop(hookProps);

  return messagingInstance !== undefined ? <FirebaseNotificationController messaging={messagingInstance} /> : null;
};
