import { api } from 'api';
import { accountManager } from './accountManager';

export const removeCloudMessaggingToken = async (accessToken: string) => {
  const token = accountManager.cookie.firebaseToken.get();
  if (token && accessToken) {
    await api.firebase.removeDeviceToCloudMessagging(accessToken, token);
    accountManager.cookie.firebaseToken.remove();
  }
};
