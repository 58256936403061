import { DrawerDetailsWrapper } from 'components/drawerDetailsWrapper';
import { EntityDetailsProps } from 'components/drawerDetailsWrapper/types';
import { SuspenseWrapper } from 'components/suspenseWrapper';
import React, { FC, lazy } from 'react';
import { useSelector } from 'react-redux';
import { EntityTypeEnum } from 'types/entity';
import { selectors } from 'utils/reduxSelector';
import { FakeLoadingEntity } from './fakeLoadingEntity';

const LazyDetailsActivity = lazy(() => import('routes/activities/components/detailsActivity'));
const LazyDetailsProvider = lazy(() => import('routes/providers/components/detailsProvider'));
const LazyDetailsContact = lazy(() => import('routes/contacts/components/detailsContact'));
const LazyDetailsCondominio = lazy(() => import('routes/condomini/details'));
const LazyDetailsCommonParts = lazy(() => import('routes/commonParts/components/details'));
const LazyDetailsInstalments = lazy(() => import('routes/instalments/components/details'));

const renderDetails = (type: EntityTypeEnum | undefined) => {
  switch (type) {
    case EntityTypeEnum.activity:
      return LazyDetailsActivity;
    case EntityTypeEnum.contact:
      return LazyDetailsContact;
    case EntityTypeEnum.provider:
      return LazyDetailsProvider;
    case EntityTypeEnum['commonParts-Environment']:
    case EntityTypeEnum['commonParts-Implant']:
      return LazyDetailsCommonParts;
    case EntityTypeEnum.instalment:
      return LazyDetailsInstalments;
    case EntityTypeEnum.condomini:
      return LazyDetailsCondominio;
    case undefined:
      return FakeLoadingEntity;
  }
};
export const EntityDetails: FC<EntityDetailsProps> = ({ entityDetailsByProps }) => {
  const entityDetails = useSelector(selectors.modalSelector.entityDetails);
  const CurrentEntity = renderDetails(entityDetailsByProps?.customEntityDetails?.type ?? entityDetails?.type);
  return (
    <DrawerDetailsWrapper entityDetailsByProps={entityDetailsByProps}>
      {({ reloadData }) => (
        <SuspenseWrapper>
          <CurrentEntity reloadData={reloadData} />
        </SuspenseWrapper>
      )}
    </DrawerDetailsWrapper>
  );
};
