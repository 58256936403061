import { MODAL_IDS } from 'config/modals';
import { AttachmentPreview } from 'types/attachments';
import { ModalDossierData } from 'types/dossier';
import { EntityDetails } from 'types/entity';
import { FinancialMovementsData } from 'types/financialMovements';
import { ModalReportEnumType } from 'types/report';

/**
 * Action type names
 */

export enum ModalsActionNames {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  RESET_MODALS = 'RESET_MODALS',
  // Attachment preview
  OPEN_ATTACHMENT_PREVIEW_MODAL = 'OPEN_ATTACHMENT_PREVIEW_MODAL',
  CLOSE_ATTACHMENT_PREVIEW_MODAL = 'CLOSE_ATTACHMENT_PREVIEW_MODAL',
  // Dossier
  OPEN_DOSSIER_MODAL = 'OPEN_DOSSIER_MODAL',
  CLOSE_DOSSIER_MODAL = 'CLOSE_DOSSIER_MODAL',
  // New dossier
  OPEN_NEW_DOSSIER_MODAL = 'OPEN_NEW_DOSSIER_MODAL',
  CLOSE_NEW_DOSSIER_MODAL = 'CLOSE_NEW_DOSSIER_MODAL',
  // Report
  OPEN_REPORT_MODAL = 'OPEN_REPORT_MODAL',
  CLOSE_REPORT_MODAL = 'CLOSE_REPORT_MODAL',
  // Financial movement
  SET_FINANCIAL_MOVEMENT = 'SET_FINANCIAL_MOVEMENT',
  // General
  SET_ENTITY_DETAILS = 'SET_ENTITY_DETAILS',
  SET_CONTENT_STATUS = 'SET_CONTENT_STATUS',
  SET_CONFIRMATION_MODAL_DATA = 'SET_CONFIRMATION_MODAL_DATA'
}

/**
 * Action typings
 */
export type ResetModals = { type: ModalsActionNames.RESET_MODALS };

export type ManageModalOutput = {
  type: ModalsActionNames.OPEN_MODAL | ModalsActionNames.CLOSE_MODAL;
  payload: keyof typeof MODAL_IDS;
};

// Attachment preview
export type OpenAttachmentPreviewModal = {
  type: ModalsActionNames.OPEN_ATTACHMENT_PREVIEW_MODAL;
  payload: { data: AttachmentPreview };
};

export type CloseAttachmentPreviewModal = { type: ModalsActionNames.CLOSE_ATTACHMENT_PREVIEW_MODAL };

// dossier
export type OpenDossierModal = {
  type: ModalsActionNames.OPEN_DOSSIER_MODAL;
  payload: { id: keyof typeof MODAL_IDS; data: ModalDossierData };
};

export type CloseDossierModal = { type: ModalsActionNames.CLOSE_DOSSIER_MODAL };

// new dossier
export type OpenNewDossierModal = {
  type: ModalsActionNames.OPEN_NEW_DOSSIER_MODAL;
  payload: { id: keyof typeof MODAL_IDS; data: ModalDossierData };
};

export type CloseNewDossierModal = { type: ModalsActionNames.CLOSE_NEW_DOSSIER_MODAL };

// report
export type OpenReportModal = {
  type: ModalsActionNames.OPEN_REPORT_MODAL;
  payload: ModalReportEnumType;
};

export type CloseReportModal = { type: ModalsActionNames.CLOSE_REPORT_MODAL };

export type SetEntityDetails = {
  type: ModalsActionNames.SET_ENTITY_DETAILS;
  payload: EntityDetails | undefined;
};

export type SetFinancialMovement = {
  type: ModalsActionNames.SET_FINANCIAL_MOVEMENT;
  payload: FinancialMovementsData | undefined;
};

/**
 * Action output typings aggregator
 */

export type ModalsActionsTypes =
  | ResetModals
  | ManageModalOutput
  | SetEntityDetails
  | SetFinancialMovement
  | OpenDossierModal
  | CloseDossierModal
  | OpenNewDossierModal
  | CloseNewDossierModal
  | OpenReportModal
  | CloseReportModal
  | OpenAttachmentPreviewModal
  | CloseAttachmentPreviewModal;
