import { REFRESH_TOKEN_COOKIE } from 'config/cookie';
import moment from 'moment';
import { CookieSetOptions } from 'universal-cookie';
import { DEFAULT_SET_OPTIONS } from './constant';
import { cookies } from './index';

export const set = (value: string, keepMeLogged: boolean) => {
  const expirationsDays = 14;
  const options: CookieSetOptions = keepMeLogged
    ? DEFAULT_SET_OPTIONS
    : { expires: moment().add(expirationsDays, 'days').toDate(), ...DEFAULT_SET_OPTIONS };
  cookies.set(REFRESH_TOKEN_COOKIE, value, options);
};

export const get = () => {
  const refreshToken = cookies.get(REFRESH_TOKEN_COOKIE);
  return refreshToken;
};

export const remove = () => cookies.remove(REFRESH_TOKEN_COOKIE);
