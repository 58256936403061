import { Activity } from 'types/activity';
import { activityHeader } from './activityHeader';
import { personHeader } from './personHeader';
import { Contact } from 'types/contact';
import { Provider } from 'types/provider';
import { commonPartsHeader } from './commonPartsHeader';
import { EntityTypeEnum } from 'types/entity';
import { instalmentHeader } from './instalmentHeader';
import { condominiHeader } from './condominiHeader';
import { CondominioBase } from 'types/condominio';

export const renderHeaderPicker = (currentEntity: unknown, type?: EntityTypeEnum) => {
  switch (type) {
    case EntityTypeEnum['commonParts-Environment']:
      return () => commonPartsHeader(false);
    case EntityTypeEnum['commonParts-Implant']:
      return () => commonPartsHeader(true);
  }

  if (!currentEntity) {
    return undefined;
  }
  switch (type) {
    case EntityTypeEnum.activity:
      return activityHeader(currentEntity as Activity);
    case EntityTypeEnum.contact:
      return personHeader((currentEntity as Contact).descr ?? '', true);
    case EntityTypeEnum.condomini:
      return condominiHeader(currentEntity as CondominioBase);
    case EntityTypeEnum.provider:
      return personHeader((currentEntity as Provider).descr ?? '', false);
    case EntityTypeEnum.instalment:
      return () => instalmentHeader();
    case undefined:
      return undefined;
  }
};
