import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';
import { MODAL_IDS } from 'config/modals';

const getModalReducer = (state: RootState) => state.modalsReducer;

const getModalStatus = (id: MODAL_IDS) =>
  createSelector(
    (state: RootState) => state.modalsReducer.modalIdsStatus[id],
    (r) => r
  );

const getAttachmentPreviewData = createSelector(
  (state: RootState) => state.modalsReducer.attachmentPreviewData,
  (r) => r
);

const entityDetails = createSelector(getModalReducer, (m) => m.entityDetails);

const getFinancialMovementsData = createSelector(
  (state: RootState) => state.modalsReducer.financialMovementsData,
  (r) => r
);

export const modalSelector = {
  getModalReducer,
  getModalStatus,
  entityDetails,
  getAttachmentPreviewData,
  getFinancialMovementsData
};
