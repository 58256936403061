import { Modal } from '@fattureincloud/fic-design-system';
import { closeAttachmentPreviewModalAction } from 'actions/modals';
import { MODAL_IDS } from 'config/modals';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';
import AttachmentPreviewer from '.';

export const ModalAttachmentPreview: FC = () => {
  const show = useSelector(selectors.modalSelector.getModalStatus(MODAL_IDS.ATTACHMENT_PREVIEW));
  const data = useSelector(selectors.modalSelector.getAttachmentPreviewData);
  const dispatch = useDispatch();

  return show && data ? (
    <Modal
      className="DS_Modal__preview-modal"
      onActionClose={() => dispatch(closeAttachmentPreviewModalAction())}
      allowClose={false}
      title="Anteprima"
    >
      <div className="content">
        <AttachmentPreviewer source={data} />
      </div>
    </Modal>
  ) : null;
};
