import { SuspenseWrapper } from 'components/suspenseWrapper';
import React, { FC, lazy } from 'react';
import { Props, connector } from './types';

const LazyNewDossiersImport = lazy(() => import('./index'));

const LazyI: FC<Props> = (props) => (
  <SuspenseWrapper>{props.status ? <LazyNewDossiersImport {...props} /> : <></>}</SuspenseWrapper>
);

export const LazyNewDossiers = connector(LazyI);
