import { Toast } from '@fattureincloud/fic-design-system';
import { ScreenDetector } from 'components/screenDetector';
import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { Layout } from './design-component/layout';
import configureStore, { getInitialData } from './configureStore';
import './connectionListeners';
import { connectionListener } from './connectionListeners';
import { SuspenseWrapper } from 'components/suspenseWrapper';
import { ErrorBoundary } from 'components/errorBoundary';
import { FirebaseManager } from './firebase-notification';
import GlobalModals from 'components/globalModals';
import { Routes } from 'routes';
import { LazyDossiers } from 'components/dossiers/lazyImport';
import { LazyNewDossiers } from 'components/dossiers/newDossier/lazyImport';
import { initialiazeMomentConfig } from 'config/moment';
import { BrowserRouter } from 'react-router-dom';
import { RouteListener } from 'components/routeListener';

// Store setup
export const store = configureStore(getInitialData());

initialiazeMomentConfig();

connectionListener();

export const Router: FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Toast.Container position="bottom-right" />
      <ScreenDetector />
      <ErrorBoundary>
        <FirebaseManager />
        <SuspenseWrapper>
          <>
            <GlobalModals />
            <Layout>
              <RouteListener>
                <Routes />
              </RouteListener>
              {/* Componenti per la visualizzazione degli allegati */}
              <LazyDossiers />
              <LazyNewDossiers />
            </Layout>
          </>
        </SuspenseWrapper>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);
