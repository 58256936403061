import { Middleware } from 'redux';
import { AuthActionNames } from 'actions/auth/types';
import { accountManager } from 'utils/accountManager';
import { RootState } from 'reducers/rootReducer';
import { api } from 'api';
import { removeCloudMessaggingToken } from 'utils/firebase';

export const authListener: Middleware = (store) => (next) => (action) => {
  // when logout action is dispatched remove the tokens from the localStorage
  if (action.type === AuthActionNames.LOGOUT) {
    const { accessToken } = (store.getState() as RootState).authReducer;
    const refreshToken = accountManager.cookie.refreshToken.get();
    removeCloudMessaggingToken(accessToken);
    if (refreshToken) {
      api.authorization.invalidAccessToken(accessToken, refreshToken);
    }
    accountManager.removeAllLoginData();
  }

  /**
   * ########
   * Dispatch current action
   * ########
   */
  const result = next(action);
  return result;
};
