import React, { FC, Suspense } from 'react';
import { LoadingSpinner } from 'design-component/loadingSpinner';

type Props = { children: JSX.Element };

export const SuspenseWrapper: FC<Props> = ({ children }) => (
  <Suspense fallback={<LoadingSpinner position="fixed" />}>
    <>{children}</>
  </Suspense>
);
