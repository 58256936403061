import { GeneralActionNames } from 'actions/general/types';
import React from 'react';
import { Middleware } from 'redux';
import { translate } from '../translations';
import { Toast } from '@fattureincloud/fic-design-system';
import { resetPrivateRoutesState } from 'actions/general';
import { accountManager } from 'utils/accountManager';
import { faWifi, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';

export const generalListener: Middleware = (store) => (next) => (action) => {
  // Remove archive data into localStorage when DB is unset
  if (action.type === GeneralActionNames.UNSET_CURRENT_DB) {
    accountManager.localStorage.archive.remove();
    store.dispatch(resetPrivateRoutesState());
  }

  if (action.type === GeneralActionNames.SET_CONNECTION_STATUS) {
    if (action.payload === 'online') {
      Toast.dismiss(undefined);
      Toast.success(<span>{translate('toastMessageOnline')}</span>, {
        icon: { icon: faWifi }
      });
    } else {
      Toast.dismiss(undefined);
      Toast.info(<span>{translate('toastMessageOffline')}</span>, {
        icon: { icon: faWifiSlash }
      });
    }
  }

  const result = next(action);
  return result;
};
