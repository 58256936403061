import React, { Component, ErrorInfo } from 'react';
import { Icon } from 'types/designSystem';
import { ErrorFallback } from './errorFallback';

export type Props = { action?: { text: string; icon?: Icon; action: () => void } };
type State = { hasError: boolean };

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
  }

  render() {
    const { action } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback action={action} />;
    }

    return this.props.children;
  }
}
