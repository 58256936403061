import { ContactsActionNames, SetContactList, SetCurrentContact } from './types';
import { Contact } from 'types/contact';
import { EntityListWithRouteContext } from 'types/entity';
import { Filter } from 'types';

export const setContactListAction = (data: EntityListWithRouteContext<Contact>): SetContactList => ({
  type: ContactsActionNames.SET_CONTACT_LIST,
  payload: data
});

export const setCurrentContactAction = (contact: Contact): SetCurrentContact => ({
  type: ContactsActionNames.SET_CURRENT_CONTACT,
  payload: contact
});

export const setContactsAdvancedFilter = (filterKey: string, filter: Filter | undefined) => ({
  type: ContactsActionNames.SET_CONTACTS_ADVANCED_FILTER,
  payload: { key: filterKey, filter }
});

export const clearContactsAdvancedFilters = () => ({ type: ContactsActionNames.CLEAR_CONTACTS_ADVANCED_FILTERS });
