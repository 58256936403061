import { colors as defaultPalette } from 'palette/colors';
import { paletteColor } from '@fattureincloud/fic-design-system';
import { DropdownItemType } from '@fattureincloud/fic-design-system/dist/components/dropdown/types';
import { colorsPalette } from '@fattureincloud/fic-design-system/dist/common/types/colorsPalette';

interface ItemColors extends colorsPalette {
  hoverBackground: paletteColor;
}

type NotActiveItemPalette = {
  [k in DropdownItemType]: ItemColors;
};

interface ItemPalette {
  active: ItemColors;
  notActive: NotActiveItemPalette;
}

export interface DropdownPalette {
  items: ItemPalette;
}

export const dropdownPalette: DropdownPalette = {
  items: {
    active: {
      color: defaultPalette.black[100],
      background: defaultPalette.blue[50],
      hoverBackground: defaultPalette.grey[100]
    },
    notActive: {
      default: {
        color: defaultPalette.black[100],
        background: 'transparent',
        hoverBackground: defaultPalette.grey[100]
      },
      danger: {
        color: defaultPalette.red[500],
        background: 'transparent',
        hoverBackground: defaultPalette.red[50]
      },
      success: {
        color: defaultPalette.green[500],
        background: 'transparent',
        hoverBackground: defaultPalette.green[50]
      },
      warning: {
        color: defaultPalette.yellow[500],
        background: 'transparent',
        hoverBackground: defaultPalette.yellow[50]
      },
      link: {
        color: defaultPalette.blue[500],
        background: 'transparent',
        hoverBackground: defaultPalette.blue[50]
      }
    }
  }
};

export default dropdownPalette;
