import { Icon } from '@fattureincloud/fic-design-system';
import React, { FC } from 'react';
import { faFileAlt, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { IconWithBackground } from 'design-component/iconWithBackground';

type Props = { unsetAttachment: () => void; fileName: string };

export const LoadedAttachment: FC<Props> = ({ unsetAttachment, fileName }) => (
  <div className="d-flex my-2 align-items-center">
    <IconWithBackground icon={faFileAlt} color="blue" size="lg" />
    <p className="font--blue ellipsisText ml-2 mr-auto mr-lg-3">{fileName}</p>
    <Icon icon={faTimes} color="red" onClick={() => unsetAttachment()} size="lg" />
  </div>
);
