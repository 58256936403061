import React, { FC } from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconWithHoverEffect } from 'design-component/iconWithHoverEffect';
import styled, { css } from 'styled-components';
import { FontSize } from 'types/designSystem';
import { IsDisabled } from 'types/utils';

type Props = { onClick: () => void; size?: FontSize; relativePosition?: boolean } & Partial<IsDisabled>;

export const CloseAction: FC<Props> = ({ onClick, relativePosition, size = 'lg', disabled }) => (
  <StyledContainer relativePosition={relativePosition}>
    <IconWithHoverEffect icon={faTimes} size={size} onClick={disabled ? undefined : onClick} isDisabled={disabled} />
  </StyledContainer>
);

const StyledContainer = styled.div<Pick<Props, 'relativePosition'>>`
  ${({ relativePosition }) =>
    !relativePosition
      ? css`
          position: absolute;
          z-index: 1;
          top: 4px;
          right: 4px;
        `
      : css`
          position: relative;
        `}
`;
