import { HOME_PATH, privateRoutes, publicRoutes } from 'config/routesInMenu';
import React, { FC, lazy } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import { PrivateRoute } from 'components/privateRoute';

const LazyHome = lazy(() => import('routes/home'));

export const Routes: FC = () => (
  <Switch>
    {publicRoutes.map((route, i) => (
      <Route key={i} {...route} />
    ))}
    {privateRoutes.map((route, i) => (
      <Route key={i} {...route} />
    ))}
    <Route path={HOME_PATH} element={<PrivateRoute component={LazyHome} />} />
  </Switch>
);
