import { ArchiveInfo, PreferredArchiveUser } from 'types/archive';
import { LocalStorageKeys } from 'config/localStorageKeys';
import { Id } from 'types';
import { UtenteArchivio } from 'types/condominio';

const get = (): ArchiveInfo | undefined => {
  const data = localStorage.getItem(LocalStorageKeys.ARCHIVE_IN_USE);
  return data ? (JSON.parse(data) as ArchiveInfo) : undefined;
};

const set = (data: ArchiveInfo) => localStorage.setItem(LocalStorageKeys.ARCHIVE_IN_USE, JSON.stringify(data));

const remove = () => localStorage.removeItem(LocalStorageKeys.ARCHIVE_IN_USE);

// set the preferred user from archive

const getPreferredUserObj = () => {
  const data = localStorage.getItem(LocalStorageKeys.PREFERRED_ARCHIVE_USER);
  return data ? (JSON.parse(data) as PreferredArchiveUser) : undefined;
};

const getPreferredUser = (archive: Id['id']): number | undefined => {
  const data = getPreferredUserObj();
  if (data) {
    return data[archive];
  }
};

const setPreferredUser = (archive: Id['id'], user: UtenteArchivio['utenteID']) => {
  const data = getPreferredUserObj();
  if (data) {
    data[archive] = user;
    localStorage.setItem(LocalStorageKeys.PREFERRED_ARCHIVE_USER, JSON.stringify(data));
  } else {
    localStorage.setItem(LocalStorageKeys.PREFERRED_ARCHIVE_USER, JSON.stringify({ [archive]: user }));
  }
};

export const archive = { get, set, remove, getPreferredUser, setPreferredUser };
