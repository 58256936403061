import { APIManager } from 'apiManager';
import { UtenteArchivio } from 'types/condominio';
import { ArchiveInfo, ArchivePermission, DBStatusEnum, DBUpdateLaunchENUM, MioCondominioInfo } from 'types/archive';
import { GretchOptions } from 'gretchen';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';

export const getList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, headerConfig: { avoidArchiveId: true } };
  const url = '/api/db';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ArchiveInfo[]>(obj);
};

export const getUserManagementList = async (archiveId: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, headerConfig: { archiveId } };
  const url = '/api/db/userManagement/users';
  const obj: APIRequestObj = { url, requestConfig };
  const response = await APIManager<UtenteArchivio[]>(obj);
  return response;
};

export const loginArchiveWithPassword = async (archiveId: string, password: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidArchiveId: true, archiveId },
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ password }) };
  const url = '/api/db/login';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const loginArchiveWithUserManagement = async (archiveId: string, selectedUser: number, password: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidArchiveId: true, archiveId },
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ id: selectedUser, password: password }) };
  const url = '/api/db/userManagement/login';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const getArchivePermissions = async (archiveId: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.GET,
    headerConfig: { avoidArchiveId: true, archiveId }
  };
  const url = '/api/db/isProtected';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ArchivePermission>(obj);
};

export const updateLastAccessDb = async (archiveId: string, getInfo: boolean) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.GET,
    headerConfig: { avoidArchiveId: true, archiveId }
  };
  const search = new URLSearchParams({ ritornaInfo: getInfo.toString() });
  const url = `/api/db/open?${search}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<MioCondominioInfo>(obj);
};

export const checkDBVersion = async (archiveId: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.GET,
    headerConfig: { avoidArchiveId: true, archiveId }
  };
  const url = '/api/db/checkDBVersion';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<DBStatusEnum>(obj);
};

export const updateDB = async (archiveId: string, connectionID: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidArchiveId: true, archiveId }
  };
  const search = new URLSearchParams({ connectionID });
  const url = `/api/db/updateDB?${search}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<DBUpdateLaunchENUM>(obj);
};
