import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { BaseItem } from 'types';
import { AnagraficaIncarico, CreaIncaricoCondomino, EditIncaricoCondomino, IncaricoCondomino } from 'types/condominio';

export const getList = async (incaricoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${incaricoID}/incarico`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<IncaricoCondomino[]>(obj);
};

export const getDetails = async (condGenID: number, incaricoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/incarico/${incaricoID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<IncaricoCondomino>(obj);
};

export const add = async (condGenID: number, incarico: CreaIncaricoCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(incarico) };
  const url = `/api/condominio/${condGenID}/incarico`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<IncaricoCondomino>(obj);
};

export const edit = async (condGenID: number, incaricoID: number, incarico: EditIncaricoCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(incarico) };
  const url = `/api/condominio/${condGenID}/incarico/${incaricoID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<IncaricoCondomino>(obj);
};

export const remove = async (condGenID: number, incaricoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/incarico/${incaricoID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};

export const getTipoIncarico = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/tipoIncarico';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<BaseItem[]>(obj);
};

export const getAnagraficaIncarico = async (condGenID: number, searchText: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const searchParam = new URLSearchParams({ searchQuery: searchText });
  const url = `/api/condominio/${condGenID}/anagraficaIncarico?${searchParam}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<AnagraficaIncarico[]>(obj);
};
