export enum MODAL_IDS {
  // Provider
  ADD_PROVIDER = 'ADD_PROVIDER',
  // Condominio
  ADD_CONDOMINIO = 'ADD_CONDOMINIO',
  // Common parts
  ADD_IMPLANT = 'ADD_IMPLANT',
  ADD_ENVIRONMENT = 'ADD_ENVIRONMENT',
  // Contact
  ADD_CONTACT = 'ADD_CONTACT',
  // Instalment
  ADD_INSTALMENT = 'ADD_INSTALMENT',
  // Activity
  ADD_ACTIVITY = 'ADD_ACTIVITY',
  CLOSE_ACTIVITY = 'CLOSE_ACTIVITY',
  REOPEN_ACTIVITY = 'REOPEN_ACTIVITY',
  SUSPEND_ACTIVITY = 'SUSPEND_ACTIVITY',
  RESUME_ACTIVITY = 'RESUME_ACTIVITY',
  NEW_HIDDEN_ACTIVITY = 'NEW_HIDDEN_ACTIVITY',

  // Activity intervention
  CONFIRM_ADD_ACTIVITY_INTERVENTION = 'CONFIRM_ADD_ACTIVITY_INTERVENTION',
  CONFIRM_EDIT_ACTIVITY_INTERVENTION = 'CONFIRM_EDIT_ACTIVITY_INTERVENTION',
  CONFIRM_POSTPONE_ACTIVITY_INTERVENTION = 'CONFIRM_POSTPONE_ACTIVITY_INTERVENTION',

  // General
  DB_NOT_UPDATED = 'DB_NOT_UPDATED',
  PID_NOT_ENABLED = 'PID_NOT_ENABLED',
  // Attachments
  ATTACHMENT_PREVIEW = 'ATTACHMENT_PREVIEW',
  SHOW_DOSSIER = 'SHOW_DOSSIER',
  SHOW_NEW_DOSSIER = 'SHOW_NEW_DOSSIER',
  CONFIRM_DELETE_ATTACHMENT = 'CONFIRM_DELETE_ATTACHMENT',
  CONFIRM_EXIT_INCOMPLETE_UPLOAD = 'CONFIRM_EXIT_INCOMPLETE_UPLOAD',
  // Report
  SHOW_REPORT_MODAL = 'SHOW_REPORT_MODAL'
}

export enum DS_SECTION_ACTION {
  ADD = 'ADD',
  SHOW_DETAILS = 'SHOW_DETAILS',
  DELETE = 'DELETE'
}
