import { APIManager } from 'apiManager';
import { GretchOptions } from 'gretchen';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';

import {
  BodyResourceForInstalments,
  GruppedTypeEnum,
  InstalmentDetail,
  InstalmentDetailsBodyRequest,
  InstalmentOnRegistry,
  InstalmentOnUnity,
  PrintReceipt,
  PrintSituazioneRate,
  RataRegistrazioneRimborso,
  RataRegistrazioneVersamento,
  RataRipartizioneImportoVersamento,
  RataRipartizioneImportoVersamentoParameters,
  SurplusVersamentoRequest,
  SurplusVersamentoResponse,
  UnityRegistry
} from 'types/instalment';

export const getUnityRegistryList = async (esercizioID: number, searchQuery: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const queryString = new URLSearchParams({ EsercizioID: esercizioID.toString() });
  searchQuery.length && queryString.append('searchQuery', searchQuery);
  const url = `/api/unitaAnagrafica?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<UnityRegistry[]>(obj);
};

export const getInstalments = async (resources: BodyResourceForInstalments) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/rata';
  const additionalOpts: GretchOptions = { body: JSON.stringify(resources) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return resources.tipo === GruppedTypeEnum.GroupedByAnagr
    ? await APIManager<InstalmentOnRegistry[]>(obj)
    : await APIManager<InstalmentOnUnity[]>(obj);
};

export const getInstalmentDetails = async (resources: InstalmentDetailsBodyRequest) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/rata/dettaglio';
  const additionalOpts: GretchOptions = { body: JSON.stringify(resources) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<InstalmentDetail>(obj);
};

export const getPreviewFuturePayment = async (resources: RataRipartizioneImportoVersamentoParameters) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/rata/importiUnita';
  const additionalOpts: GretchOptions = { body: JSON.stringify(resources) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<RataRipartizioneImportoVersamento[]>(obj);
};

export const registerPayment = async (resources: RataRegistrazioneVersamento) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/rata/registerVersamento';
  const additionalOpts: GretchOptions = { body: JSON.stringify(resources) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<number[]>(obj);
};

export const registerRefound = async (resources: RataRegistrazioneRimborso) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/rata/registerRimborso';
  const additionalOpts: GretchOptions = { body: JSON.stringify(resources) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<number>(obj);
};

export const getSurplusVersamento = async (request: SurplusVersamentoRequest) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/rata/surplusVersamento';
  const additionalOpts: GretchOptions = { body: JSON.stringify(request) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<SurplusVersamentoResponse[]>(obj);
};

export const printSituazioneRate = async (data: PrintSituazioneRate) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const url = '/api/rata/printSituazioneRate';
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const printReceipt = async (data: PrintReceipt) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const url = '/api/rata/printReceipt';
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};
