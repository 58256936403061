import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { ContoLookup, TipoContoEnum } from 'types/conto';

export const getContiVersamenti = async (exercisesID: number[]) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const searchParam = new URLSearchParams({
    TipoConto: TipoContoEnum.SoloContiVersamenti.toString()
  });
  exercisesID.forEach((o) => searchParam.set('EserciziID', o.toString()));
  const url = `/api/conto/lookup?${searchParam}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ContoLookup[]>(obj);
};

export const getContiPersonali = async (exercisesID: number[]) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const searchParam = new URLSearchParams({
    TipoConto: TipoContoEnum.SoloContiPersonali.toString()
  });
  exercisesID.forEach((o) => searchParam.set('EserciziID', o.toString()));
  const url = `/api/conto/lookup?${searchParam}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ContoLookup[]>(obj);
};
