import {
  CondominioModalitaDistribuzioneVersamenti,
  GruppedTypeEnum,
  InstalmentDetailIDS,
  InstalmentDisplayData
} from 'types/instalment';

export const isOrderByUnity = (value: GruppedTypeEnum) => value === GruppedTypeEnum.GroupedByUnita;

export const getCurrentEntityIds = (instalment: InstalmentDisplayData): InstalmentDetailIDS => {
  const { tipo, id, subList } = instalment;
  if (isOrderByUnity(tipo)) {
    return { currentUnity: [id], currentRegistry: subList[0].id };
  }
  return { currentUnity: subList.map((o) => o.id), currentRegistry: id };
};

export const showSurplus = (
  modalitaDistribuzioneVersamenti: CondominioModalitaDistribuzioneVersamenti,
  currentImporto: number,
  importoSurplus: number
) =>
  Boolean(
    modalitaDistribuzioneVersamenti ===
      CondominioModalitaDistribuzioneVersamenti.distribuzioneVersamentiDataCompetenza &&
      importoSurplus < 0 &&
      currentImporto
  );
