import { IconInfo } from 'types/activity';
import { faHouseDamage, faQuestion, faTools, faCogs, faExclamation, faWrench } from '@fortawesome/pro-solid-svg-icons';

export const getAttivitaIcon = (reportingType: string): IconInfo => {
  switch (reportingType?.toLocaleLowerCase()) {
    case 'guasto':
      return { icon: faHouseDamage, color: 'red' };
    case 'rich. informazioni':
      return { icon: faQuestion, color: 'green' };
    case 'lavoro':
      return { icon: faTools, color: 'yellow' };
    case 'manutenzione':
      return { icon: faCogs, color: 'grey' };
    case 'problema':
      return { icon: faExclamation, color: 'grey' };
    default:
      return { icon: faWrench, color: 'grey' };
  }
};
