import {
  faHammer,
  faSuitcase,
  faAddressBook,
  faPuzzlePiece,
  faProjectDiagram,
  faBuildings,
  faQuestion
} from '@fortawesome/pro-light-svg-icons';
import { routesInMenu } from 'constants/routesInMenu';

export const getIconByRoute = (currentRoute: keyof typeof routesInMenu) => {
  switch (currentRoute) {
    case 'contacts':
      return faAddressBook;
    case 'providers':
      return faHammer;
    case 'commonParts':
      return faPuzzlePiece;
    case 'instalments':
      return faProjectDiagram;
    case 'condominiums':
      return faBuildings;
    case 'activities':
      return faSuitcase;
    default:
      return faQuestion;
  }
};
