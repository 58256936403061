import React, { FC, useCallback, useEffect, useState } from 'react';
import { HEADWAY_ID_DEV, HEADWAY_ID_BETA, HEADWAY_ID_PROD } from 'config/headway';
import { HeadwayWidgetConfig } from 'types/headway';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';
import { faFireFlame } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@fattureincloud/fic-design-system';
import { setChangelogAction } from 'actions/general';
import { Dispatch } from 'redux';
import { ConditionalWrapper } from 'components/conditionalWrapper';
import Tippy from '@tippyjs/react';
import style from '../topbar.module.scss';
import { isBetaVersion, isProductionVersion } from 'utils/route';

const SCRIPT_ID = 'headway-script';
const SELECTOR = 'headway-changelog';

const getHeadwayID = () =>
  isProductionVersion() ? HEADWAY_ID_PROD : isBetaVersion() ? HEADWAY_ID_BETA : HEADWAY_ID_DEV;

export const getHeadWayConfig = (dispatch: Dispatch): HeadwayWidgetConfig => ({
  selector: '#' + SELECTOR,
  account: getHeadwayID(),
  badgePosition: 'top-right',
  widgetPosition: 'bottom-right',
  translations: {
    title: 'Novità',
    readMore: 'Scopri tutte le novità →',
    footer: ''
  },
  callbacks: {
    onWidgetReady: () => {
      dispatch(setChangelogAction(true));
    }
  }
});

export const Headway: FC = () => {
  const isMobileScreen = useSelector(selectors.generalSelector.isMobileScreen);
  const changelogReady = useSelector(selectors.generalSelector.changelogReady);
  const localUserSettings = useSelector(selectors.generalSelector.localUserSettings);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  // tippy settings
  const [hovered, setHovered] = useState(false);
  const showTippy = () => setHovered(true);
  const hideTippy = () => setHovered(false);

  const { sidebarExpanded } = localUserSettings.appereance;
  const isExpanded = Boolean(!isMobileScreen && sidebarExpanded);

  const handleClick = () => {
    setShow(!show);
    window?.Headway?.[show ? 'hide' : 'show']();
  };

  const config: HeadwayWidgetConfig = getHeadWayConfig(dispatch);

  const initHeadway = useCallback(() => {
    if (window?.Headway) {
      window?.Headway?.init(config);
    }
  }, [config]);

  const loadScript = useCallback(() => {
    const element = document.getElementById(SCRIPT_ID);
    if (!element || !changelogReady) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.async = true;
      script.src = 'https://cdn.headwayapp.co/widget.js';
      document.head.appendChild(script);
      script.onload = function () {
        initHeadway();
      };
    }
  }, [changelogReady, initHeadway]);

  useEffect(() => {
    loadScript();
    return () => {
      // window?.Headway?.destroy();
      dispatch(setChangelogAction(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={`headway-container ${isExpanded ? 'expandend' : ''}`}>
      <div
        id={SELECTOR}
        onClick={handleClick}
        onMouseEnter={showTippy}
        onMouseLeave={hideTippy}
        className={isExpanded ? 'expandend' : undefined}
      >
        {changelogReady && (
          <div onClick={hideTippy}>
            <ConditionalWrapper
              condition={!isExpanded}
              wrapper={(children) => (
                <Tippy
                  content={'Novità'}
                  placement="right"
                  arrow={false}
                  zIndex={1}
                  className={style.tippy}
                  delay={0}
                  duration={0}
                  offset={[0, 12]}
                  visible={hovered}
                >
                  {children}
                </Tippy>
              )}
            >
              <div className="d-flex align-items-center gap-2">
                <Icon icon={faFireFlame} color="white" size="lg" className="icon" />
                {isExpanded && <p className="font--white">Novità</p>}
              </div>
            </ConditionalWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
