import React, { FC } from 'react';
import style from './headerDetailsDrawer.module.scss';
import { useSelector } from 'react-redux';
import { currentCondominium } from 'utils/reduxSelector/generalSelector';
import { commonPartsTypeToString } from 'routes/commonParts/utils';
import { commonPartsReducerSelector } from 'utils/reduxSelector/commonPartsSelector';
import { Service } from 'types/commonParts';
import { IconWithBackground } from 'design-component/iconWithBackground';
import { faLongArrowAltDown } from '@fortawesome/pro-light-svg-icons';
import { ConditionalWrapper } from 'components/conditionalWrapper';
import { ICON_PARTI_COMUNI, ICON_SERVIZIO_PARTE_COMUNE } from 'constants/icon';

type Props = { isImplant: boolean; currentService?: Service };

const CommonPartsHeader: FC<Props> = ({ isImplant, currentService }) => {
  const condominium = useSelector(currentCondominium);
  const data = useSelector(commonPartsReducerSelector)[isImplant ? 'implant' : 'environment'];
  if (data) {
    const { denominazione, tipo, categoria } = data;
    return (
      <div className={style.container}>
        {condominium && <p className="font--grey">{condominium.intestazione}</p>}
        <div className="d-flex align-items-center mt-2">
          <ConditionalWrapper
            condition={Boolean(currentService)}
            wrapper={(children) => <div className={style.iconBox}>{children}</div>}
          >
            {ICON_PARTI_COMUNI.partiComuniIconWithBackground(tipo, categoria, currentService ? 'xs' : '1x')}
          </ConditionalWrapper>
          <div className="d-flex flex-column ml-2">
            <p className="semibold">{denominazione}</p>
            {!currentService && <p className="caption font--grey">{commonPartsTypeToString(tipo)}</p>}
          </div>
        </div>
        {currentService && (
          <div className="d-flex flex-column">
            <IconWithBackground icon={faLongArrowAltDown} color="grey" size="1x" overrideBackgroundIntensity={50} />
            <div className="d-flex align-items-center">
              <IconWithBackground
                icon={ICON_SERVIZIO_PARTE_COMUNE}
                color="blue"
                size="1x"
                overrideBackgroundIntensity={100}
              />
              <p className="semibold ml-2">{`${currentService?.tipo?.tipo}${
                currentService.descrizione ? ' - ' + currentService.descrizione : ''
              }`}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <></>;
};

export const commonPartsHeader = (isImplant: boolean, currentService?: Service) => (
  <CommonPartsHeader isImplant={isImplant} currentService={currentService} />
);
