import React, { Component } from 'react';
import { renderHeaderPicker } from './components/header/';
import { LoadingSpinner } from '../../design-component/loadingSpinner';
import { withAPILoading } from 'HOC/withAPILoading';
import { Props, State, connector } from './types';
import { APIManagerResponse } from 'apiManager/types';
import { ReduxCleaner } from './components/reduxCleaner';
import { isEqual } from 'lodash';
import { ErrorWhileLoading } from 'components/detailsUtilsComponents/ErrorWhileLoading';
import { getAPICallByEntity } from './utils';
import { UnstyledDrawerProps } from '@fattureincloud/fic-design-system/dist/components/drawer/types';
import { Drawer } from '@fattureincloud/fic-design-system';
import { EntityTypeEnum } from 'types/entity';
import { getIconByEntity } from 'constants/icon';

/**
 * Show the details of an entity in a drawer
 */
class DrawerWrapperComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { APIError: false };
  }

  async componentDidMount() {
    await this.contentGetter();
  }

  async componentDidUpdate(prevProps: Props) {
    const { entityDetails, refreshEntityDetailsData } = this.props;
    if (entityDetails) {
      const isDrawerDetailsOpen = this.isDrawerDetailsOpen();
      if (isDrawerDetailsOpen && !isEqual(prevProps.entityDetails?.id, entityDetails.id)) {
        await this.contentGetter();
      }
      if (entityDetails.type && !prevProps?.entityDetails?.type) {
        this.enableListener();
      }
      if (isDrawerDetailsOpen && refreshEntityDetailsData !== prevProps.refreshEntityDetailsData) {
        await this.contentGetter();
      }
    }
  }

  private isDrawerDetailsOpen = () => {
    const { entityDetails } = this.props;
    return entityDetails !== undefined;
  };

  private contentGetter = async () => {
    const { entityDetails, handleAPICall, setCurrentEntity } = this.props;
    const isDrawerDetailsOpen = this.isDrawerDetailsOpen();
    if (isDrawerDetailsOpen && entityDetails && setCurrentEntity) {
      // in caso dei dettagli della sezione condomini, non fa nulla
      if (entityDetails.type === EntityTypeEnum.condomini) {
        return;
      }
      const call = getAPICallByEntity(entityDetails);
      if (call) {
        const { data, error } = await handleAPICall(call as () => Promise<APIManagerResponse<unknown>>);
        const castedAction = setCurrentEntity as (data: unknown) => unknown;
        error && this.setState({ APIError: true });
        if (data) {
          const { APIError } = this.state;
          APIError && this.setState({ APIError: false });
          castedAction(data);
        }
      }
    }
  };

  // Listener for close drawer by Escape key
  private enableListener = () => {
    const show = this.isDrawerDetailsOpen();
    if (show) {
      window.addEventListener('keydown', this.closeDetailsModalByEscKey);
    }
  };

  private closeDetailsModalByEscKey = (event: KeyboardEvent) => {
    const show = this.isDrawerDetailsOpen();
    if (event.key === 'Escape' && show) {
      this.closeDetailsModal();
    }
  };

  private closeDetailsModal = () => {
    const { setEntityDetailsAction, setCurrentEntity } = this.props;
    window.removeEventListener('keydown', this.closeDetailsModalByEscKey);
    setEntityDetailsAction(undefined);
    if (setCurrentEntity) {
      const castedAction = setCurrentEntity as (data: unknown) => unknown;
      castedAction(undefined);
    }
  };

  render = () => {
    const { children, isLoading, currentEntity, entityDetails } = this.props;
    const { APIError } = this.state;
    const show = this.isDrawerDetailsOpen();
    const drawerProps: UnstyledDrawerProps = {
      show,
      renderHeader: APIError || !show ? undefined : renderHeaderPicker(currentEntity, entityDetails?.type),
      actionClose: this.closeDetailsModal
    };
    return (
      <Drawer {...drawerProps}>
        {isLoading ? (
          <LoadingSpinner position="fixed" />
        ) : APIError && entityDetails ? (
          <ErrorWhileLoading icon={{ icon: getIconByEntity(entityDetails.type) }} action={() => this.contentGetter()} />
        ) : (
          children({ reloadData: this.contentGetter })
        )}
        {show ? <ReduxCleaner cleanup={this.closeDetailsModal} /> : null}
      </Drawer>
    );
  };
}

export const DrawerDetailsWrapper = withAPILoading(connector(DrawerWrapperComponent));
