import { APIManager } from 'apiManager';
import { GenericSelectInfo } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { CondominioReadViewModel, CondominiumService } from 'types/condominio';
import { Location } from 'types/entity';
import { ActivityReporting } from 'types/registry';
import { Applicant, ApplicantInfo } from 'types/activity';
import { ServiceType } from 'types/commonParts';
import { Id } from 'types';

// Nation
export const getNationList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/nazione';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<GenericSelectInfo[]>(obj);
};

export const addNation = async (nation: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ testo: nation }) };
  const url = '/api/nazione';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<GenericSelectInfo>(obj);
};

// Location
export const getLocationList = async (location: Location) => {
  const { cap, citta, prov } = location;
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/localita?cap=${cap}&prov=${prov}&citta=${citta}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Location[]>(obj);
};

// Titolo anagrafica
export const getRegistryTitleList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/titoloAnagrafica';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<GenericSelectInfo[]>(obj);
};

export const addRegistryTitle = async (newTitle: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ testo: newTitle }) };
  const url = '/api/titoloAnagrafica';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<GenericSelectInfo>(obj);
};

// Operatore
export const getActivityManagerList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/operatore';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<string[]>(obj);
};

// Condominium
export const getCondominiumList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/condominio';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<CondominioReadViewModel[]>(obj);
};

// CondominiumService
export const getCondominiumServiceList = async (condominioId: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condominioId}/servizio`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<CondominiumService[]>(obj);
};

// ActivityReporting
export const getActivityReportingList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/attivita/tipo';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ActivityReporting[]>(obj);
};

export const addActivityReporting = async (newValue: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ descr: newValue }) };
  const url = '/api/attivita/tipo';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Pick<ActivityReporting, 'descr'>>(obj);
};

// Provider - ActivityTyle
export const getActivityTypeList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/fornitore/tipoAttivita';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<GenericSelectInfo[]>(obj);
};

export const addProviderActivityType = async (newType: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ testo: newType }) };
  const url = '/api/fornitore/tipoAttivita';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<GenericSelectInfo>(obj);
};

// Activity- applicant
export const getActivityApplicantList = async (searchQuery: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const searchParams = searchQuery ? new URLSearchParams({ searchQuery }) : '';
  const url = `/api/attivita/richiedenti?${searchParams}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ApplicantInfo[]>(obj);
};

export const getActivityApplicantInfo = async (id: Id['id']) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/attivita/richiedenti/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Applicant>(obj);
};

// Service type in common parts
export const getServiceTypeList = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = '/api/tipoServizioParteComune';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ServiceType[]>(obj);
};
