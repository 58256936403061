import { ContextData } from 'routes/instalments/components/details/components/context';
import { FormPaymentData } from 'routes/instalments/components/details/components/contextActionsManager/components/registerPayment/types';
import { EntityFilters, Id } from 'types';
import { CondominiumInfo } from './condominio/condominio';
import { Description } from './entity';
import { FullPrintSettings, PrintTipoRicevuta } from './print';

export type InstalmentFilter = EntityFilters & {
  gruppedTypeFilter: GruppedTypeEnum;
  expiredImportFilter: boolean;
};

export type UnityRegistryRole = { descrizione?: string };

export type UnityRegistry = {
  unitaID: number;
  unitaDescr?: string;
  anagrID: number;
  anagrDescr?: string;
  listaRuoli?: UnityRegistryRole[];
};

export enum GruppedTypeEnum {
  GroupedByAnagr = 1,
  GroupedByUnita
}

export type UnityRegistryIDs = {
  unitaID: number;
  anagrID: number;
};

export type BodyResourceForInstalments = {
  searchQuery?: string;
  tipo: GruppedTypeEnum;
  unitaAnagrafiche: UnityRegistryIDs[];
} & CondominiumInfo;

export enum InstalmentTypeEnum {
  rtPreventivo = 0,
  rtStraordinaria,
  rtRimborso,
  rtSurplus
}

export type InstalmentCommonData = {
  dovuto: number;
  versato: number;
  saldo: number;
  importoScaduto: number;
  scaduta: boolean;
  tipoRata: InstalmentTypeEnum;
};

export type InstalmentOnRegistry = Pick<UnityRegistryIDs, 'anagrID'> & {
  listaUnitaID: number[];
} & InstalmentCommonData;

export type InstalmentOnUnity = Pick<UnityRegistryIDs, 'unitaID'> & {
  listaAnagrID: number[];
} & InstalmentCommonData;

export type DisplaySubList = Id & { name: string; roles: string[] };

export type InstalmentDisplayData = Id &
  Pick<BodyResourceForInstalments, 'tipo'> &
  InstalmentCommonData & {
    description: string;
    subList: DisplaySubList[];
  };

export type InstalmentDetailsBodyRequest = Pick<InstalmentOnRegistry, 'anagrID' | 'listaUnitaID'> & CondominiumInfo;

export type InstalmentImport = { unitaID: number; unitaDescr: number; saldo: number };

export type InstalmentSituation = Id &
  Description &
  InstalmentCommonData & { data?: Date; importi: InstalmentImport[] };
export type PaymentSituation = Id & Description & { dataPagamento?: string; numProgrStr?: string; importo?: number };
export enum CondominioModalitaDistribuzioneVersamenti {
  distribuzioneVersamentiRataVecchia,
  distribuzioneVersamentiDataCompetenza
}
export type InstalmentDetail = {
  rate?: InstalmentSituation[];
  movimenti?: PaymentSituation[];
  modalitaDistribuzioneVersamenti: CondominioModalitaDistribuzioneVersamenti;
};
export type InstalmentDetailIDS = { currentRegistry?: number; currentUnity?: number[] };

export type RipartizioneSituazioneRate = Pick<InstalmentSituation, 'id' | 'tipoRata' | 'data'> &
  Pick<UnityRegistryIDs, 'anagrID'> &
  Pick<CondominiumInfo, 'esercizioID'> & {
    selezionato: boolean;
    importi: InstalmentImport[];
  };

export type RataRipartizioneImportoVersamentoParameters = CondominiumInfo &
  Pick<FormPaymentData, 'importo' | 'dataPagamento' | 'usaRimborsoComeVersamento'> &
  Pick<ContextData, 'modalitaRimborso' | 'situazioneRate'> & {
    rateIDAssegnatarieSurplus: number[];
  };

export type RataRipartizioneImportoVersamento = Pick<CondominiumInfo, 'esercizioID'> &
  Pick<UnityRegistry, 'anagrID'> & {
    rataID?: number;
    dataRata?: Date;
    unita: { unitaID: number; importo: number }[];
  };

export type RataRegistrazioneExtra = {
  esercizioPrincipaleID: number;
  dataCompetenza: Date;
  sottocontoOperazionePrincipaleID: number;
  importiEserciziID: number[]; // per GO sarà un solo esercizio
  importiMovimenti: RataRipartizioneImportoVersamento[];
  checkVersamentiDifferiti: boolean; //solo nel versamento
};

type RataRegistrazioneBase = Pick<CondominiumInfo, 'condGenID'> &
  Omit<FormPaymentData, 'usaRimborsoComeVersamento'> &
  RataRegistrazioneExtra;

export type SpeseIncassoParameters = {
  contoIDSpeseIncasso: number;
  importoSpeseIncasso: number;
  fornitoreIDSpeseIncasso: number;
};

export type RataRegistrazioneVersamento = RataRegistrazioneBase &
  SpeseIncassoParameters & {
    // utilizzaDataScadenzaRate: true; è sempre a true
    // checkVersamentiDifferiti: boolean; spostato in RataRegistrazioneExtra
  };

export type RataRegistrazioneRimborso = RataRegistrazioneBase &
  Required<Pick<FormPaymentData, 'usaRimborsoComeVersamento'>>;

export type RateSurplusVersamentoRata = Pick<
  InstalmentSituation,
  'id' | 'descrizione' | 'data' | 'saldo' | 'tipoRata'
> &
  Pick<CondominiumInfo, 'esercizioID'>;

export type SurplusVersamentoRequest = {
  rate: RateSurplusVersamentoRata[];
  rateIDInRegistrazione: number[];
};

export type SurplusVersamentoResponse = Pick<InstalmentSituation, 'id' | 'descrizione' | 'data' | 'saldo'> & {
  esercizio?: string;
};

export type PrintVersamento = Pick<UnityRegistry, 'anagrID'> &
  PrintTipoRicevuta & {
    movID: number[];
    unitaID: number[];
    rimborso: boolean;
  };

export type SignalRPrintMessage = { S3Url: string };

export type PrintSituazioneRate = FullPrintSettings &
  Pick<CondominiumInfo, 'condGenID'> &
  Pick<UnityRegistryIDs, 'anagrID'> & {
    eserciziID: number[];
    unitaID: number[];
    mostraSoloRateScadute?: boolean;
    mostraSoloRateFinoAl?: boolean;
    dataLimiteScadenzaRate?: string;
  };

export type PrintReceipt = FullPrintSettings & PrintVersamento;
