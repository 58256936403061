import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { Id } from 'types';
import { ActivityEventNote } from 'types/activity';

export const add = async (id: Id['id'], newNote: ActivityEventNote) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newNote) };
  const url = `/api/attivita/${id}/eventi/nota`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const edit = async (id: Id['id'], idActivityEvent: number, newNote: ActivityEventNote) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newNote) };
  const url = `/api/attivita/${id}/eventi/nota/${idActivityEvent}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const editInitialDescription = async (id: Id['id'], newNote: ActivityEventNote) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify(newNote) };
  const url = `/api/attivita/${id}/eventi/descrizione`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

export const deleteEvent = async (id: Id['id'], idActivityEvent: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/attivita/${id}/eventi/nota/${idActivityEvent}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
