import { TagPalette } from '@fattureincloud/fic-design-system/dist/components/tag/tagPalette';
import { colors as defaultPalette } from 'palette/colors';

const tagPalette: TagPalette = {
  full: {
    standard: {
      color: defaultPalette.white[100],
      backgroundColor: defaultPalette.grey[600]
    },
    warning: {
      color: defaultPalette.white[100],
      backgroundColor: defaultPalette.yellow[500]
    },
    success: {
      color: defaultPalette.white[100],
      backgroundColor: defaultPalette.green[500]
    },
    info: {
      color: defaultPalette.white[100],
      backgroundColor: defaultPalette.blue[500]
    },
    error: {
      color: defaultPalette.white[100],
      backgroundColor: defaultPalette.red[500]
    }
  },
  soft: {
    standard: {
      color: defaultPalette.grey[900],
      backgroundColor: defaultPalette.grey[100]
    },
    warning: {
      color: defaultPalette.yellow[900],
      backgroundColor: defaultPalette.yellow[50]
    },
    success: {
      color: defaultPalette.green[900],
      backgroundColor: defaultPalette.green[50]
    },
    info: {
      color: defaultPalette.blue[900],
      backgroundColor: defaultPalette.blue[50]
    },
    error: {
      color: defaultPalette.red[900],
      backgroundColor: defaultPalette.red[50]
    }
  },
  text: {
    standard: {
      backgroundColor: 'transparent',
      color: defaultPalette.grey[800]
    },
    warning: {
      backgroundColor: 'transparent',
      color: defaultPalette.yellow[500]
    },
    success: {
      backgroundColor: 'transparent',
      color: defaultPalette.green[500]
    },
    info: {
      backgroundColor: 'transparent',
      color: defaultPalette.indigo[500]
    },
    error: {
      backgroundColor: 'transparent',
      color: defaultPalette.red[500]
    }
  }
};

export default tagPalette;
