import { api } from 'api';
import { RootState } from 'reducers/rootReducer';
import { store } from 'router';
import { EntityDetails, EntityTypeEnum } from 'types/entity';
import { InstalmentDetailsBodyRequest } from 'types/instalment';
import { DispatchProps } from './types';

export const getCurrentEntity = (type: EntityTypeEnum | undefined, state: RootState) => {
  switch (type) {
    case EntityTypeEnum.activity:
      return state.activitiesReducer.currentActivity;
    case EntityTypeEnum.contact:
      return state.contactsReducer.currentContact;
    case EntityTypeEnum.provider:
      return state.providersReducer.currentProvider;
    case EntityTypeEnum['commonParts-Environment']:
    case EntityTypeEnum['commonParts-Implant']:
      return state.commonPartsReducer.currentCommonParts;
    case EntityTypeEnum.instalment:
      return state.instalmentReducer.currentInstalment;
    case EntityTypeEnum.condomini:
      return state.condominiReducer.currentCondominio;
  }
};

export const getEntityAction = (type: EntityTypeEnum | undefined, actions: DispatchProps) => {
  switch (type) {
    case EntityTypeEnum.activity:
      return actions.setCurrentActivityAction;
    case EntityTypeEnum.contact:
      return actions.setCurrentContactAction;
    case EntityTypeEnum.provider:
      return actions.setCurrentProviderAction;
    case EntityTypeEnum['commonParts-Environment']:
      return actions.setCurrentEnvironmentAction;
    case EntityTypeEnum['commonParts-Implant']:
      return actions.setCurrentImplantAction;
    case EntityTypeEnum.instalment:
      return actions.setCurrentInstalmentAction;
    case EntityTypeEnum.condomini:
      return actions.setCurrentCondominioAction;
    case undefined:
      return () => null;
  }
};

export const getAPICallByEntity = (entity: EntityDetails) => {
  const { id, type } = entity;
  const { generalReducer, instalmentReducer } = store.getState() as RootState;
  const { currentCondominium, currentEsercizio } = generalReducer;

  switch (type) {
    case EntityTypeEnum.activity:
      return () => api.activity.getActivity(id);
    case EntityTypeEnum.contact:
      return () => api.contact.getContact(id);
    case EntityTypeEnum.provider:
      return () => api.provider.getProvider(id);
    case EntityTypeEnum['commonParts-Environment']:
      return () => api.commonParts.getEnvironment(currentCondominium?.id ?? 0, id);
    case EntityTypeEnum['commonParts-Implant']:
      return () => api.commonParts.getImplant(currentCondominium?.id ?? 0, id);
    case EntityTypeEnum.instalment: {
      const { currentInstalmentsIDS } = instalmentReducer;
      const request: InstalmentDetailsBodyRequest = {
        condGenID: currentCondominium?.id ?? 0,
        esercizioID: currentEsercizio?.id ?? 0,
        anagrID: currentInstalmentsIDS.currentRegistry ?? 0,
        listaUnitaID: currentInstalmentsIDS.currentUnity ?? [0]
      };
      return () => api.instalment.getInstalmentDetails(request);
    }
  }
};
