import { apiErrorHandler } from 'apiManager/apiErrorHandler';
import React, { Component as RComponent, ComponentType, isValidElement } from 'react';
import { APIManagerError, APIManagerResponse } from 'apiManager/types';
import { IsLoading, WithoutType } from 'types/utils';

export type APICall = <T>(
  APICall: () => Promise<APIManagerResponse<T>>,
  options?: APICallOptions<T>
) => Promise<APIManagerResponse<T>>;

export type WithAPILoadingInjectedProps = IsLoading & { handleAPICall: APICall };
export type APICallOptions<T> = {
  autoStart?: boolean;
  ignoreError?: boolean;
  errorToastMessage?: string;
  successCallback?: (data: T) => void;
  failureCallback?: (error: APIManagerError) => void;
};

export const withAPILoading = <P extends WithAPILoadingInjectedProps>(Component: ComponentType<P>) =>
  class WithLoading extends RComponent<WithoutType<P, WithAPILoadingInjectedProps>, IsLoading> {
    constructor(props: WithoutType<P, WithAPILoadingInjectedProps>) {
      super(props);
      this.state = { isLoading: false };
    }

    private handleError = (error: APIManagerError, errorToastMessage: string) => {
      apiErrorHandler(
        errorToastMessage ? { code: error.code, message: errorToastMessage, status: error.status } : error
      );
    };

    private handleAPICall: APICall = async (APICall, options) => {
      const { ignoreError } = options ?? {};
      this.setIsLoadingState(true);
      const result = await APICall();
      this.setIsLoadingState(false);
      if (!ignoreError && result?.error) {
        this.handleError(result.error, options?.errorToastMessage || '');
      }
      return result;
    };

    private setIsLoadingState = (value: boolean) => this.setState({ isLoading: value });

    private getAPI = (): WithAPILoadingInjectedProps => {
      const { isLoading } = this.state;
      return {
        handleAPICall: this.handleAPICall,
        isLoading
      };
    };

    render() {
      const { ...props } = this.props;
      const isValid = isValidElement(<Component {...(props as P)} {...this.getAPI()} />);
      return isValid ? <Component {...(props as P)} {...this.getAPI()} /> : null;
    }
  };
