import React, { FC } from 'react';
import style from './headerDetailsDrawer.module.scss';
import { useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';
import { InstalmentDisplayData } from 'types/instalment';
import { convertSubList } from 'routes/instalments/utils';
import { Icon } from '@fattureincloud/fic-design-system';
import { ICON_CONDOMINIO, ICON_ESERCIZIO } from 'constants/icon';

const InstalmentHeader: FC = () => {
  const { currentCondominium, currentEsercizio } = useSelector(selectors.generalSelector.generalReducer);
  const instalment = useSelector(selectors.modalSelector.entityDetails);

  if (instalment?.extrafields) {
    const currentInstalment = instalment?.extrafields as InstalmentDisplayData;
    return (
      <div className={style.container}>
        {currentCondominium && (
          <div className="d-flex align-items-center gap-2">
            <Icon icon={ICON_CONDOMINIO} color="black" size="xs" fixedWidth />
            <p className="font--grey ellipsisText">{currentCondominium.intestazione}</p>
          </div>
        )}
        {currentEsercizio && (
          <div className="d-flex align-items-center gap-2">
            <Icon icon={ICON_ESERCIZIO} color="black" size="xs" fixedWidth />
            <p className="font--grey ellipsisText">Esercizio {currentEsercizio.descrizione}</p>
          </div>
        )}
        <div className="d-flex align-items-center mt-2">
          <div className="d-flex flex-column">
            <p className="semibold">{currentInstalment.description}</p>
            <p className="caption font--grey ellipsisText">{convertSubList(currentInstalment.subList)}</p>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export const instalmentHeader = () => <InstalmentHeader />;
