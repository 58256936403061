import { Logout } from 'actions/auth/types';
import { ResetState } from 'actions/general/types';
import { EntityFilters } from 'types';
import { CommonParts, Environment, Implant } from 'types/commonParts';

export enum CommonPartsActionNames {
  SET_CURRENT_COMMON_PARTS = 'SET_CURRENT_COMMON_PARTS',
  SET_FILTERS = 'SET_FILTERS',
  SET_IMPLANT = '  SET_IMPLANT',
  SET_ENVIRONMENT = 'SET_ENVIRONMENT'
}

export type SetCurrentCommonParts = {
  type: typeof CommonPartsActionNames.SET_CURRENT_COMMON_PARTS;
  payload: CommonParts | undefined;
};

export type SetCommonPartsFilters = {
  type: typeof CommonPartsActionNames.SET_FILTERS;
  payload: EntityFilters;
};

export type SetCurrentImplant = {
  type: typeof CommonPartsActionNames.SET_IMPLANT;
  payload: Implant | undefined;
};

export type SetCurrentEnvironment = {
  type: typeof CommonPartsActionNames.SET_ENVIRONMENT;
  payload: Environment | undefined;
};

/**
 * Action output typings aggregator
 */
export type CommonPartsTypes =
  | ResetState
  | Logout
  | SetCurrentCommonParts
  | SetCommonPartsFilters
  | SetCurrentImplant
  | SetCurrentEnvironment;
