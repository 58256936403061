import {
  ModalsActionNames,
  OpenDossierModal,
  CloseDossierModal,
  CloseAttachmentPreviewModal,
  OpenAttachmentPreviewModal,
  OpenNewDossierModal,
  CloseNewDossierModal,
  OpenReportModal,
  CloseReportModal,
  SetEntityDetails,
  SetFinancialMovement
} from './types';
import { MODAL_IDS } from 'config/modals';
import { ModalDossierData } from 'types/dossier';
import { AttachmentPreview } from 'types/attachments';
import { ModalReportEnumType } from 'types/report';
import { EntityDetails } from 'types/entity';
import { FinancialMovementsData } from 'types/financialMovements';

export const resetModalsAction = () => ({ type: ModalsActionNames.RESET_MODALS });

export const manageModalAction = (actionType: ModalsActionNames, id: MODAL_IDS) => ({
  type: actionType,
  payload: id
});

export const openAttachmentPreviewModalAction = (data: AttachmentPreview): OpenAttachmentPreviewModal => ({
  type: ModalsActionNames.OPEN_ATTACHMENT_PREVIEW_MODAL,
  payload: { data }
});

export const closeAttachmentPreviewModalAction = (): CloseAttachmentPreviewModal => ({
  type: ModalsActionNames.CLOSE_ATTACHMENT_PREVIEW_MODAL
});

export const openDossierModalAction = (data: ModalDossierData): OpenDossierModal => ({
  type: ModalsActionNames.OPEN_DOSSIER_MODAL,
  payload: { id: MODAL_IDS.SHOW_DOSSIER, data }
});

export const closeDossierModalAction = (): CloseDossierModal => ({ type: ModalsActionNames.CLOSE_DOSSIER_MODAL });

export const openNewDossierModalAction = (data: ModalDossierData): OpenNewDossierModal => ({
  type: ModalsActionNames.OPEN_NEW_DOSSIER_MODAL,
  payload: { id: MODAL_IDS.SHOW_NEW_DOSSIER, data }
});

export const closeNewDossierModalAction = (): CloseNewDossierModal => ({
  type: ModalsActionNames.CLOSE_NEW_DOSSIER_MODAL
});

export const openReportModalAction = (data: ModalReportEnumType): OpenReportModal => ({
  type: ModalsActionNames.OPEN_REPORT_MODAL,
  payload: data
});

export const closeReportModalAction = (): CloseReportModal => ({ type: ModalsActionNames.CLOSE_REPORT_MODAL });

export const setEntityDetailsAction = (data: EntityDetails | undefined): SetEntityDetails => ({
  type: ModalsActionNames.SET_ENTITY_DETAILS,
  payload: data
});

export const setFinancialMovementAction = (data: FinancialMovementsData | undefined): SetFinancialMovement => ({
  type: ModalsActionNames.SET_FINANCIAL_MOVEMENT,
  payload: data
});
