import { TOPBAR_HEIGHT } from 'config/styleVariables';
import styled from 'styled-components';

export const StyledDesktopLayout = styled.div`
  height: calc(100vh - ${TOPBAR_HEIGHT}px);
  max-height: calc(100vh - ${TOPBAR_HEIGHT}px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
