import React from 'react';
import style from './headerDetailsDrawer.module.scss';
import { IconWithBackground } from 'design-component/iconWithBackground';
import { CondominioBase } from 'types/condominio';
import { ICON_CONDOMINIO, ICON_ESERCIZIO } from 'constants/icon';

export const condominiHeader = (currentCondominio: CondominioBase) => () => {
  return (
    <div className={style.container}>
      <div className="d-flex align-items-center">
        <IconWithBackground icon={ICON_CONDOMINIO} color="blue" size="1x" overrideBackgroundIntensity={100} />
        <p className="ml-2 font--grey">{currentCondominio.intestazione}</p>
      </div>
    </div>
  );
};

export const eserciziHeader = (descr: string) => () => {
  return (
    <div className={style.container}>
      <div className="d-flex align-items-center">
        <IconWithBackground icon={ICON_ESERCIZIO} color="blue" size="1x" overrideBackgroundIntensity={100} />
        <p className="ml-2 font--grey">{descr}</p>
      </div>
    </div>
  );
};
