import React, { FC, useContext } from 'react';
import { Dossier } from 'types/dossier';
import { DOSSIER_PER_PAGE } from '.';
import { DossierContext } from '../dossierContext';
import { DossierRow } from '../dossierRow';
import style from '../style.module.scss';

type Props = { data: Dossier[]; page: number };

export const List: FC<Props> = ({ data, page }) => {
  const { setCurrentDossier } = useContext(DossierContext);
  const pageIndex = (page - 1) * DOSSIER_PER_PAGE;

  return (
    <div className={style['dossier-list']}>
      {data.map((o, i) => (
        <DossierRow
          key={i}
          type="dossier"
          onClick={() => setCurrentDossier({ ...o, descrizione: o.descrizione || `Fascicolo nr. ${i + 1}` })}
          title={o.descrizione || `Fascicolo nr. ${pageIndex + i + 1}`}
          subtitle={o.dataCreazione}
          length={o.elencoFile?.length}
        />
      ))}
    </div>
  );
};
