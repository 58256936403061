import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { Id } from 'types';

export const sendSuggestion = async (email: string, messaggio: string, allegato?: File) => {
  const formData = new FormData();
  if (allegato) {
    formData.append('Allegato', allegato, allegato.name);
  }
  formData.append('Email', email);
  formData.append('Messaggio', messaggio);
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidContentTypeApplicationJson: true }
  };
  const url = '/api/segnalazioni/suggerimento';
  const additionalOpts: GretchOptions = { body: formData };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Id>(obj);
};

export const sendBug = async (email: string, messaggio: string, allegato?: File) => {
  const formData = new FormData();
  if (allegato) {
    formData.append('Allegato', allegato, allegato.name);
  }
  formData.append('Email', email);
  formData.append('Messaggio', messaggio);
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidContentTypeApplicationJson: true }
  };
  const url = '/api/segnalazioni/problema';
  const additionalOpts: GretchOptions = { body: formData };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Id>(obj);
};
