import { Modal } from '@fattureincloud/fic-design-system';
import { MODAL_IDS } from 'config/modals';
import React, { FC } from 'react';
import { ModalsReducer } from 'reducers/modalsReducer';
import { ModalContent } from './components/modalContent';

type Props = Pick<ModalsReducer, 'modalIdsStatus' | 'reportModalData'>;

export const ReportModal: FC<Props> = ({ modalIdsStatus, reportModalData }) => {
  return (
    <>
      {modalIdsStatus[MODAL_IDS.SHOW_REPORT_MODAL] && reportModalData ? (
        <Modal className="DS_Modal__no-padding">
          <ModalContent type={reportModalData.type} />
        </Modal>
      ) : null}
    </>
  );
};
