import { ManageModalOutput } from '../modals/types';
import { Contact } from 'types/contact';
import { ResetState } from 'actions/general/types';
import { EntityListWithRouteContext } from 'types/entity';
import { AdvancedFilterPayload } from 'types';
import { Logout } from 'actions/auth/types';

export enum ContactsActionNames {
  SET_CONTACT_LIST = 'SET_CONTACT_LIST',
  SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT',
  RESET_CONTACT_DETAILS = 'RESET_CONTACT_DETAILS',
  SET_CONTACTS_ADVANCED_FILTER = 'SET_CONTACTS_ADVANCED_FILTER',
  CLEAR_CONTACTS_ADVANCED_FILTERS = 'CLEAR_CONTACTS_ADVANCED_FILTERS'
}

export type SetContactList = {
  type: typeof ContactsActionNames.SET_CONTACT_LIST;
  payload: EntityListWithRouteContext<Contact>;
};

export type SetCurrentContact = { type: typeof ContactsActionNames.SET_CURRENT_CONTACT; payload: Contact };

// Reset contact
type ResetContactDetails = { type: typeof ContactsActionNames.RESET_CONTACT_DETAILS };

type SetContactsAdvancedFilter = {
  type: typeof ContactsActionNames.SET_CONTACTS_ADVANCED_FILTER;
  payload: AdvancedFilterPayload;
};

type ClearContactsAdvancedFilters = { type: typeof ContactsActionNames.CLEAR_CONTACTS_ADVANCED_FILTERS };

/**
 * Action output typings aggregator
 */
export type ContactsTypes =
  | ResetState
  | Logout
  | SetContactList
  | SetCurrentContact
  | ManageModalOutput
  | ResetContactDetails
  | SetContactsAdvancedFilter
  | ClearContactsAdvancedFilters;
