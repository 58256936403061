import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import {
  DatiCatastaliCondomino,
  ImmobileAccatastato,
  DomandaAccatastamento,
  CreaImmobileAccatastato,
  CreaDomandaAccatastamento
} from 'types/condominio';

export const getList = async (condGenID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/datiCatastali`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<DatiCatastaliCondomino[]>(obj);
};

export const getImmobileAccatastato = async (condGenID: number, datiCatastaliID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/datiCatastali/immobileAccatastato/${datiCatastaliID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<ImmobileAccatastato>(obj);
};

export const addImmobileAccatastato = async (condGenID: number, immobileAccatastato: CreaImmobileAccatastato) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(immobileAccatastato) };
  const url = `/api/condominio/${condGenID}/datiCatastali/immobileAccatastato`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<CreaImmobileAccatastato>(obj);
};

export const editImmobileAccatastato = async (
  condGenID: number,
  immobileAccatastatoID: number,
  immobileAccatastato: CreaImmobileAccatastato
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(immobileAccatastato) };
  const url = `/api/condominio/${condGenID}/datiCatastali/immobileAccatastato/${immobileAccatastatoID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<ImmobileAccatastato>(obj);
};

export const getDomandaAccatastamento = async (condGenID: number, datiCatastaliID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/datiCatastali/domandaAccatastamento/${datiCatastaliID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<DomandaAccatastamento>(obj);
};

export const addDomandaAccatastamento = async (condGenID: number, domandaAccatastamento: CreaDomandaAccatastamento) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(domandaAccatastamento) };
  const url = `/api/condominio/${condGenID}/datiCatastali/domandaAccatastamento`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<CreaImmobileAccatastato>(obj);
};

export const editDomandaAccatastamento = async (
  condGenID: number,
  domandaAccatastamentoID: number,
  domandaAccatastamento: CreaDomandaAccatastamento
) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(domandaAccatastamento) };
  const url = `/api/condominio/${condGenID}/datiCatastali/domandaAccatastamento/${domandaAccatastamentoID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<DomandaAccatastamento>(obj);
};

export const removeDatoCatastale = async (condGenID: number, id: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/datiCatastali/${id}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
