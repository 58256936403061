import { Id } from 'types';
import { Note } from 'types/entity';
import { DateTime } from 'types/utils';

export enum DatiCatastaliTipo {
  'Immobile accatastato' = 1,
  'Domanda di accatastamento'
}

export type DatiCatastaliCondomino = Id &
  Partial<Note> & {
    descrizioneDatiCatastali?: string;
    tipo: DatiCatastaliTipo;
  };

export type CreaImmobileAccatastato = Partial<Note> & {
  codiceComune?: string;
  tipoCatasto?: string;
  parteImmobile?: string;
  sezioneComune?: string;
  foglio?: string;
  particellaParte1?: string;
  particellaParte2?: string;
  tipoParticella?: string;
  subalterno?: string;
};

export type ImmobileAccatastato = DatiCatastaliCondomino &
  Omit<CreaImmobileAccatastato, 'particellaParte1' | 'particellaParte2'> & {
    particella?: string;
  };

export type DomandaAccatastamento = DatiCatastaliCondomino & {
  domandaAccDataPres?: DateTime;
  domandaAccNumero?: string;
  domandaAccProvincia?: string;
};

export type CreaDomandaAccatastamento = Partial<Note> & {
  domandaAccDataPres?: DateTime;
  domandaAccNumero?: string;
  domandaAccProvincia?: string;
};
