import { CommonPartsCategoryEnum, CommonPartsTypeEnum } from 'types/commonParts';

export const isImplant = (category: CommonPartsCategoryEnum) => category === CommonPartsCategoryEnum.Impianto;

export const commonPartsTypeToString = (type: CommonPartsTypeEnum) => {
  if (type === CommonPartsTypeEnum.AM_GENERICO) {
    return 'Ambiente generico';
  }
  if (type === CommonPartsTypeEnum.IM_GENERICO) {
    return 'Impianto generico';
  }
  return CommonPartsTypeEnum[type];
};
