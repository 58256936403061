import { Palette } from '@fattureincloud/fic-design-system';
import { black } from './black';
import { blue } from './blue';
import { cyan } from './cyan';
import { fuchsia } from './fuchsia';
import { green } from './green';
import { grey } from './grey';
import { indigo } from './indigo';
import { orange } from './orange';
import { pink } from './pink';
import { red } from './red';
import { white } from './white';
import { yellow } from './yellow';

export const colors: Palette = {
  black,
  white,
  grey,
  blue,
  indigo,
  yellow,
  orange,
  red,
  green,
  fuchsia,
  pink,
  cyan,
  primary: blue
};
