import { isImplant } from 'routes/commonParts/utils';
import { Id, ObjVersion } from 'types';
import { Provider, ProviderBasicData } from './provider';
import { Location, Note } from './entity';
import { CondominioReadViewModel } from './condominio/condominio';
import { DateTime } from './utils';

type ImplantProviderID = { costruttoreID?: number; installatoreID?: number };

type CreateImplant = ImplantProviderID & {
  modello?: string;
  dataInstallazione?: DateTime;
  codiceUtente?: string;
  matricola?: string;
};

export type CreateCommonParts = {
  denominazione: string;
  ubicazione: string;
  tipo: CommonPartsTypeEnum;
  categoria: CommonPartsCategoryEnum;
} & Note &
  CreateImplant;

export type FreeField = {
  numProgr: number; //minimum: 1  - maximum: 15
  descrizione: string;
  testo?: string;
};
export type UpdateImplant = CreateImplant &
  Note &
  Pick<CommonParts, 'objVersion' | 'tipo' | 'denominazione' | 'ubicazione' | 'campiLiberi'>;

export type CommonParts = Id &
  Pick<ObjVersion, 'objVersion'> &
  Omit<CreateCommonParts, keyof ImplantProviderID> & {
    campiLiberi: FreeField[];
  };

export enum CommonPartsTypeEnum {
  IM_GENERICO = 1,
  Ascensore = 2,
  Riscaldamento = 4,
  'Impianto elettrico' = 5,
  Addolcitore = 6,
  Autoclave = 7,
  Raffrescamento = 8,
  'Pompa sommersa' = 9,
  Antincendio = 10,
  Piscina = 11,
  'Antenna tv' = 12,
  'Antenna sat' = 13,
  Citofono = 14,
  Fognatura = 15,
  'Rete gas-metano' = 16,
  'Rete acqua' = 17,
  'Pannelli fotovoltaici' = 18,
  'Pannelli solari' = 19,
  'Scarico verticale' = 20,
  'Canna fumaria' = 21,
  'Cancello/accesso' = 22,
  'Sistema anticaduta' = 23,
  Giardino = 101,
  Scale = 102,
  Portineria = 103,
  AM_GENERICO = 104,
  Edificio = 105
}

export enum CommonPartsCategoryEnum {
  Impianto = 0,
  Ambiente = 1
}

export const emptyNewCommonParts = (category: CommonPartsCategoryEnum): CreateCommonParts => ({
  denominazione: '',
  ubicazione: '',
  tipo: isImplant(category) ? CommonPartsTypeEnum.IM_GENERICO : CommonPartsTypeEnum.AM_GENERICO,
  categoria: category,
  note: ''
});

export type ImplantProvider = Id &
  Location &
  ProviderBasicData &
  Pick<Provider, 'descr' | 'indirizzo' | 'codFisc' | 'piva' | 'omonimoOn' | 'omonimoProgr'>;

export enum ServiceTypeEnum {
  Manutenzione = 'Manutenzione',
  Revisione = 'Revisione',
  Verifica = 'Verifica',
  Riparazione = 'Riparazione',
  Fornitura = 'Fornitura',
  Installazione = 'Installazione',
  Dipendente = 'Dipendente',
  'Incarico professionale' = 'Incarico professionale',
  Generico = 'Generico',
  Assistenza = 'Assistenza'
}

export type ServiceType = Id & { tipo: string };

export type ContractService = {
  contrattoOn: boolean;
  contrattoInizio?: string;
  contrattoNumero?: string;
  contrattoScadenza?: string;
  contrattoTermineDisdetta?: string;
};

export type PeriodicInterventionService = {
  interventoPeriodicoOn: boolean;
  dataUltimoIntervento?: string;
  interventoPeriodoRinnovo?: number;
  interventoProssimoIntervento?: string;
};

export type Service = Id &
  Pick<ObjVersion, 'objVersion'> &
  ContractService &
  PeriodicInterventionService &
  Note & {
    tipo: ServiceType;
    descrizione: string;
    manutentore?: ImplantProvider;
    protocollo?: number;
    pubblicazioneMioCondominioOn?: boolean;
  };

export type CreateService = ContractService &
  PeriodicInterventionService &
  Pick<Service, 'descrizione' | 'protocollo'> & { tipoID: number; manutentoreID?: number };

export type Environment = CommonParts & { note: string; servizi: Service[] };

export type Implant = Environment & {
  costruttore?: ImplantProvider;
  modello?: string;
  installatore?: ImplantProvider;
  dataInstallazione?: string;
  codiceUtente?: string;
  matricola?: string;
};

export type IsImplant = { isImplant: boolean };

export type CommonPartsIds = {
  condominium: CondominioReadViewModel;
  commonPartsId: Id['id'];
};
