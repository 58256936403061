import { PageEmptySet } from '@fattureincloud/fic-design-system';
import React, { FC } from 'react';
import { translate } from 'translations';
import { Props } from './index';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';

export const ErrorFallback: FC<Props> = ({ action }) => (
  <div className="h-100 w-100 pos-r d-flex justify-content-center align-items-center">
    <PageEmptySet
      title={translate('error')}
      text={translate('somethingWentWrong')}
      icon={{ icon: faExclamation }}
      action={action}
    />
  </div>
);
