import { ConnectionStatus } from 'reducers/generalReducer';
import { ScreenState } from 'types/mobile';
import { iPhoneModels } from 'utils/phoneDetection';
import { ArchiveInfo, MioCondominioInfo } from 'types/archive';
import { LocalUserSettings } from 'types/localUserSettings';
import { ContactsActionNames } from 'actions/contacts/types';
import { ProvidersActionNames } from 'actions/providers/types';
import { ActivitiesActionNames } from 'actions/activities/types';
import { CondominioReadViewModel, Esercizio } from 'types/condominio';
import { Logout } from 'actions/auth/types';

/**
 * Action type names
 */

export enum GeneralActionNames {
  SET_CURRENT_DB = 'SET_CURRENT_DB',
  UNSET_CURRENT_DB = 'UNSET_CURRENT_DB',
  SET_CURRENT_CONDOMINIUM = 'SET_CURRENT_CONDOMINIUM',
  SET_CURRENT_ESERCIZIO = 'SET_CURRENT_ESERCIZIO',
  UPDATE_DB_LAST_ACCESS = 'UPDATE_DB_LAST_ACCESS',
  SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS',
  SET_PHONE_MODEL = 'SET_PHONE_MODEL',
  SCREEN_RESIZE = 'SCREEN_RESIZE',
  REFRESH_ROUTE_DATA = 'REFRESH_ROUTE_DATA',
  REFRESH_ENTITY_DETAILS_DATA = 'REFRESH_ENTITY_DETAILS_DATA',
  RESET_STATE = 'RESET_STATE',
  SET_LOCAL_USER_SETTINGS = 'SET_LOCAL_USER_SETTINGS',
  SET_CHANGELOG = 'SET_CHANGELOG'
}

/**
 * Action typings
 */
// SET DB
type SetDbOutput = {
  type: typeof GeneralActionNames.SET_CURRENT_DB;
  payload: ArchiveInfo;
};
// UNSET CURRENT DB
type UnsetCurrentDb = {
  type: typeof GeneralActionNames.UNSET_CURRENT_DB;
};

// SET Condominium
type SetCondominium = {
  type: typeof GeneralActionNames.SET_CURRENT_CONDOMINIUM;
  payload: CondominioReadViewModel | undefined;
};

// SET fiscal year
export type SetEsercizio = {
  type: typeof GeneralActionNames.SET_CURRENT_ESERCIZIO;
  payload: Esercizio | undefined;
};

// UPDATE DB LAST ACCESS
type UpdateDbLastAccess = {
  type: typeof GeneralActionNames.UPDATE_DB_LAST_ACCESS;
  payload: MioCondominioInfo;
};

// SET CONNECTION STATUS
type SetConnectionStatusAction = {
  type: typeof GeneralActionNames.SET_CONNECTION_STATUS;
  payload: ConnectionStatus;
};

// SET CONNECTION STATUS
type SetPhoneModelAction = {
  type: typeof GeneralActionNames.SET_PHONE_MODEL;
  payload: iPhoneModels;
};

type ScreenResizeAction = {
  type: typeof GeneralActionNames.SCREEN_RESIZE;
  payload: ScreenState;
};

type RefreshRouteData = { type: typeof GeneralActionNames.REFRESH_ROUTE_DATA };

type RefreshEntityDetailsData = { type: typeof GeneralActionNames.REFRESH_ENTITY_DETAILS_DATA };

// RESET PRIVATE ROUTE STATES
export type ResetState = { type: typeof GeneralActionNames.RESET_STATE };

// RESET PRIVATE ROUTE STATES
export type EmitMiddlewareAction = {
  type: GeneralActionNames | ContactsActionNames | ProvidersActionNames | ActivitiesActionNames;
};

// SET CONNECTION STATUS
export type SetLocalUserSettings = {
  type: typeof GeneralActionNames.SET_LOCAL_USER_SETTINGS;
  payload: LocalUserSettings;
};

// SET CHANGELOG STATUS
export type SetChangelog = {
  type: typeof GeneralActionNames.SET_CHANGELOG;
  payload: boolean;
};

/**
 * Action output typings aggregator
 */

export type GeneralActionsTypes =
  | Logout
  | SetDbOutput
  | UnsetCurrentDb
  | SetCondominium
  | SetEsercizio
  | UpdateDbLastAccess
  | SetConnectionStatusAction
  | SetPhoneModelAction
  | ScreenResizeAction
  | RefreshRouteData
  | RefreshEntityDetailsData
  | ResetState
  | SetLocalUserSettings
  | SetChangelog;
