import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const green: color = {
  50: '#e0f1eb',
  100: '#b3dbce',
  200: '#80c3ad',
  300: '#4dab8c',
  400: '#269973',
  500: '#00875a',
  600: '#007f52',
  700: '#007448',
  800: '#006a3f',
  900: '#00572e'
};
