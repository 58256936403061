import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';

export const addDeviceToCloudMessagging = async (token: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidArchiveId: true },
    justResponse: true
  };
  const url = '/api/notifiche/addDevice';
  const additionalOpts: GretchOptions = { body: JSON.stringify({ token, device: 'web' }) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const { data } = await APIManager<Response>(obj);
  return Boolean(data?.ok);
};

export const removeDeviceToCloudMessagging = async (accessToken: string, token: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { accessToken, avoidArchiveId: true },
    justResponse: true
  };
  const url = '/api/notifiche/removeDevice';
  const additionalOpts: GretchOptions = { body: JSON.stringify({ token, device: 'web' }) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const { data } = await APIManager<Response>(obj);
  return Boolean(data?.ok);
};
