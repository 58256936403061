import React, { FC } from 'react';
import { ActionSettings, Props } from './types';
import { IconWithHoverEffect } from 'design-component/iconWithHoverEffect';
import { getAttachmentIcon } from './utils';
import { IconWithBackground } from 'design-component/iconWithBackground';
import moment from 'moment';
import { Tooltip } from '@fattureincloud/fic-design-system';

const defaultActionSettings: ActionSettings = { size: '1x', color: 'grey', overrideBackgroundIntensity: 100 };

export const AttachmentData: FC<Props> = ({ attachment, actions, actionSettings = defaultActionSettings }) => {
  const { nomeFile, dataOra } = attachment;
  const { size, color, overrideBackgroundIntensity } = actionSettings;
  return (
    <div className="d-flex align-items-center w-100 py-2">
      <IconWithBackground icon={getAttachmentIcon(nomeFile)} size="1x" color="blue" />
      <div className="d-flex flex-column ml-2 ellipsisText">
        <p className="ellipsisText">{nomeFile}</p>
        {dataOra && <p className="caption font--grey">{moment(dataOra).format('DD/MM/YYYY HH:mm')}</p>}
      </div>
      <div className="d-inline-flex ml-auto px-1">
        {actions.map((a, i) => (
          <Tooltip key={i} type="light" message={a.tooltip} placement={'top'}>
            <IconWithHoverEffect
              icon={a.icon}
              onClick={a.onClick}
              size={size}
              color={color}
              overrideBackgroundIntensity={overrideBackgroundIntensity}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
