import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { SpinnerProps, Spinner } from './spinner';

type Position = { position: 'fixed' | 'absolute' };
type OverlayProps = Position & { transparentBackground?: boolean };
type Props = Partial<SpinnerProps> & OverlayProps & Position;

const Overlay = styled.div<OverlayProps>`
  ${({ transparentBackground, position }) => css`
    background-color: ${transparentBackground ? 'transparent' : 'rgba(0, 0, 0, 0.14)'};
    position: ${position};
  `}
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const Container = styled.div<SpinnerProps>`
  ${({ size }) => css`
    min-width: ${size === 'small' ? '64px' : '96px'};
    min-height: ${size === 'small' ? '64px' : '96px'};
  `};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: auto;
  background-color: transparent;
`;

const DefaultContainer = styled.div<SpinnerProps>`
  ${({ size }) => css`
    min-width: ${size === 'small' ? '64px' : '96px'};
    min-height: ${size === 'small' ? '64px' : '96px'};
  `};
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
`;

export const LoadingSpinner: FC<Props> = ({ position, size = 'medium', transparentBackground }) => (
  <Overlay position={position} transparentBackground={transparentBackground}>
    <Container size={size}>
      <Spinner size={size} />
    </Container>
  </Overlay>
);

export default LoadingSpinner;

export const LoadingSpinnerWithContainer: FC<Props> = ({ position, size = 'medium', transparentBackground }) => (
  <DefaultContainer size={size}>
    <LoadingSpinner position={position} size={size} transparentBackground={transparentBackground} />
  </DefaultContainer>
);
