import React, { FC, lazy } from 'react';
import style from './layout.module.scss';
import { canShowSidebar, isLoginContextRoute } from 'utils/route';
import { StyledMobileLayout } from './components/styledMobileLayout';
import { StyledDesktopLayout } from './components/styledDesktopLayout';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectors } from 'utils/reduxSelector';

const LazyMobileBottombar = lazy(() => import('design-component/layout/components/mobileBottomBar'));
const LazySidebar = lazy(() => import('design-component/layout/components/sidebar'));
const LazyTopbar = lazy(() => import('design-component/layout/components/topbar'));

export const Layout: FC = ({ children }) => {
  const location = useLocation();
  const isLoginContext = isLoginContextRoute(location?.pathname);
  const isMobileScreen = useSelector(selectors.generalSelector.isMobileScreen);
  const authReducer = useSelector(selectors.authSelector.authReducer);

  if (isLoginContext) {
    return <div className={style['login-layout']}>{children}</div>;
  }

  const showSidebar = canShowSidebar(location?.pathname, authReducer);
  const showMobileBottombar = isMobileScreen && showSidebar;
  return (
    <div className={`pos-r d-flex flex-column`}>
      <LazyTopbar showProfilePicker={!isLoginContext} />
      {isMobileScreen ? (
        <div className="mx-4 overflow-y-auto">
          <StyledMobileLayout showMobileBottombar={showMobileBottombar}>{children}</StyledMobileLayout>
        </div>
      ) : (
        <StyledDesktopLayout>
          {showSidebar && <LazySidebar />}
          <div className={style.container}>
            <div className={style.content}>{children}</div>
          </div>
        </StyledDesktopLayout>
      )}

      {showMobileBottombar ? <LazyMobileBottombar /> : null}
      {/* On mobile main elements are using position fixed, the app needs to display content outside theyr stack limitation
      reference https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context */}
    </div>
  );
};
