import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { StatusEserciziEnum, Esercizio, EsercizioDetails, EsercizioSaldoTSPay } from 'types/condominio';

import * as palazzina from './palazzina';
export { palazzina };

export const getList = async (condGenID: number, statusEsercizi: StatusEserciziEnum) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const queryString = new URLSearchParams();
  if (statusEsercizi !== StatusEserciziEnum.Tutti) {
    queryString.set('EserciziAperti', statusEsercizi === StatusEserciziEnum.Aperti ? 'true' : 'false');
  }
  const url = `/api/condominio/${condGenID}/esercizio?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Esercizio[]>(obj);
};

export const getDetails = async (condGenID: number, esercizioID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<EsercizioDetails>(obj);
};

export const getSaldoTsPay = async (condGenID: number, esercizioID: number, forzaAggiornamento: boolean) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const queryString = new URLSearchParams({ forzaAggiornamento: forzaAggiornamento.toString() });
  const url = `/api/condominio/${condGenID}/esercizio/${esercizioID}/saldoTsPay?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<EsercizioSaldoTSPay>(obj);
};
