import { FIREBASE_TOKEN_COOKIE } from 'config/cookie';
import { DEFAULT_SET_OPTIONS } from './constant';
import { cookies } from './index';

export const set = (value: string) => cookies.set(FIREBASE_TOKEN_COOKIE, value, DEFAULT_SET_OPTIONS);

export const get = () => {
  const firebaseToken = cookies.get(FIREBASE_TOKEN_COOKIE);
  return firebaseToken;
};

export const remove = () => cookies.remove(FIREBASE_TOKEN_COOKIE);
