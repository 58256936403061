/**
 * Le icone hanno come stile di default il regular
 */
import {
  faBuilding,
  faCashRegister,
  faUser,
  faHammer,
  faWrench,
  faSuitcase,
  faPuzzlePiece,
  faProjectDiagram
} from '@fortawesome/pro-regular-svg-icons';
import { faBuilding as faBuildingSolid } from '@fortawesome/pro-solid-svg-icons';
import { EntityTypeEnum } from 'types/entity';

export const ICON_ATTIVITA = faSuitcase;
export const ICON_PERSONA = faUser;
export const ICON_FORNITORE = faHammer;
export const ICON_RATA = faProjectDiagram;
export const ICON_CONDOMINIO = faBuilding;
export const ICON_ESERCIZIO = faCashRegister;
export const ICON_PARTE_COMUNE = faWrench;
export const ICON_SERVIZIO_PARTE_COMUNE = faPuzzlePiece;

// solid weight
export const ICON_CONDOMINIO_SOLID = faBuildingSolid;

export const getIconByEntity = (entity: EntityTypeEnum) => {
  switch (entity) {
    case EntityTypeEnum.activity:
      return ICON_ATTIVITA;
    case EntityTypeEnum.provider:
      return ICON_FORNITORE;
    case EntityTypeEnum['commonParts-Environment']:
    case EntityTypeEnum['commonParts-Implant']:
      return ICON_PARTE_COMUNE;
    case EntityTypeEnum.instalment:
      return faProjectDiagram;
    case EntityTypeEnum.contact:
      return ICON_PERSONA;
    case EntityTypeEnum.condomini:
      return ICON_CONDOMINIO;
  }
};
