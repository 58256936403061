import { ActivitiesActionNames, ActivitiesTypes } from 'actions/activities/types';
import { GeneralActionNames } from 'actions/general/types';
import { ActivitiesReducer, initialState } from './types';
import { clearedEntityAdvancedFilters, setEntityAdvancedFilters } from 'utils/entityFilters';
import { AuthActionNames } from 'actions/auth/types';

export const activitiesReducer = (
  state: ActivitiesReducer = initialState,
  action: ActivitiesTypes
): ActivitiesReducer => {
  switch (action.type) {
    case ActivitiesActionNames.SET_ACTIVITY_LIST: {
      return {
        ...state,
        ...action.payload,
        pageContext: { ...action.payload.pageContext },
        interventionMessagesStatus: { ...action.payload.interventionMessagesStatus }
      };
    }

    case ActivitiesActionNames.SET_CURRENT_ACTIVITY: {
      return { ...state, currentActivity: action.payload };
    }

    case ActivitiesActionNames.SET_ACTIVITIES_FILTER_STATUS:
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, activityStatus: action.payload }
        }
      };

    case ActivitiesActionNames.SET_ACTIVITIES_ADVANCED_FILTER: {
      const advanced = setEntityAdvancedFilters(state.pageContext.filters.advanced, action.payload);
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, advanced }
        }
      };
    }

    case ActivitiesActionNames.CLEAR_ACTIVITIES_ADVANCED_FILTERS: {
      const advanced = clearedEntityAdvancedFilters(state.pageContext.filters.advanced);
      return {
        ...state,
        pageContext: {
          ...state.pageContext,
          filters: { ...state.pageContext.filters, advanced }
        }
      };
    }

    case ActivitiesActionNames.RESET_ACTIVITY_DETAILS:
      return { ...state, currentActivity: undefined };

    case ActivitiesActionNames.UPDATE_LAST_ACTIVITY_CREATED: {
      return { ...state, lastActivityCreated: action.payload };
    }

    case ActivitiesActionNames.SET_SYNC_INTERVENTIONS_MESSAGES:
      return { ...state, interventionMessagesStatus: { ...action.payload } };

    case GeneralActionNames.RESET_STATE:
    case AuthActionNames.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
