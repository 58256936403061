import { Provider } from 'types/provider';
import { ManageModalOutput } from '../modals/types';
import { ResetState } from 'actions/general/types';
import { EntityListWithRouteContext } from 'types/entity';
import { AdvancedFilterPayload } from 'types';
import { Logout } from 'actions/auth/types';

/**
 * Action type names
 */
export enum ProvidersActionNames {
  SET_PROVIDER_LIST = 'SET_PROVIDER_LIST',
  SET_CURRENT_PROVIDER = 'SET_CURRENT_PROVIDER',
  RESET_PROVIDER_DETAILS = 'RESET_PROVIDER_DETAILS',
  SET_PROVIDERS_ADVANCED_FILTER = 'SET_PROVIDERS_ADVANCED_FILTER',
  CLEAR_PROVIDERS_ADVANCED_FILTERS = 'CLEAR_PROVIDERS_ADVANCED_FILTERS'
}

/**
 * Action typings
 */
export type SetProviderList = {
  type: typeof ProvidersActionNames.SET_PROVIDER_LIST;
  payload: EntityListWithRouteContext<Provider>;
};

export type SetCurrentProvider = { type: typeof ProvidersActionNames.SET_CURRENT_PROVIDER; payload: Provider };

// Reset provider
type ResetProviderDetails = { type: typeof ProvidersActionNames.RESET_PROVIDER_DETAILS };

type SetProvidersAdvancedFilter = {
  type: typeof ProvidersActionNames.SET_PROVIDERS_ADVANCED_FILTER;
  payload: AdvancedFilterPayload;
};

type ClearProvidersAdvancedFilters = { type: typeof ProvidersActionNames.CLEAR_PROVIDERS_ADVANCED_FILTERS };

/**
 * Action output typings aggregator
 */
export type ProvidersTypes =
  | ResetState
  | Logout
  | ManageModalOutput
  | ResetProviderDetails
  | SetProviderList
  | SetCurrentProvider
  | SetProvidersAdvancedFilter
  | ClearProvidersAdvancedFilters;
