import { WebViewProps } from 'react-native-webview';

export type PlatformOSType = 'ios' | 'android' | 'macos' | 'windows' | 'web' | 'native';

export type NativeApp = { platform: PlatformOSType };

export type PostMessage = { postMessage: (value: string) => void };

export type RNWebview = WebViewProps & PostMessage;

export type RNFileDowloadRequest = {
  filename: string;
  url: string;
};

export enum AppFeaturesListEnum {
  attachmentsWithLinking,
  downloadAttachment
}

export type AppFeaturesList = {
  [key in AppFeaturesListEnum]?: boolean;
};

export type RNInfo = {
  platform: PlatformOSType;
  features: AppFeaturesList;
};

// set windows type
declare global {
  interface Window {
    nativeInfo?: RNInfo;
    ReactNativeWebView: RNWebview | undefined;
  }
}

export enum APIEnvironmentEnum {
  local = 'local',
  alfa = 'alfa',
  beta = 'beta',
  prod = 'prod'
}

type TVersion = string;

export type WebViewMessageObject = {
  accessToken: string;
  attachmentURL: string;
  authToken: string;
  environment: APIEnvironmentEnum;
  fileDowloadRequest: RNFileDowloadRequest;
  navigationStateChange: boolean;
  printPdfUrl: string;
  refreshToken: string;
  sessionID: string;
  version: TVersion; // if missing then app version is <= 1.1.6
};
