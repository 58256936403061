import { deployEnvironment, DeployEnvironmentEnum } from 'config/deploy';
import { HOME_PATH, publicRoutesNames } from 'config/routesInMenu';
import { AuthReducer } from 'reducers/authReducer/types';
import { UseAppCheck } from 'types';
import { AccountTypeEnum } from 'types/account';

// from Native app
enum APIEnvironmentEnum {
  alfa = 'alfa',
  beta = 'beta',
  prod = 'prod'
}

export const isLoginContextRoute = (path: string): boolean =>
  path === publicRoutesNames.login ||
  path === publicRoutesNames.mobileLogin ||
  path === publicRoutesNames.forgotPassword;
// path === publicRoutesNames.signup ||

export const isBetaVersion = () => Boolean(deployEnvironment === DeployEnvironmentEnum.Beta);

export const isProductionVersion = () => deployEnvironment === DeployEnvironmentEnum.Production;

export const getEnvironment = () =>
  isProductionVersion() ? APIEnvironmentEnum.prod : isBetaVersion() ? APIEnvironmentEnum.beta : APIEnvironmentEnum.alfa;

export const versionCheck = (version: AuthReducer['versione']) => Boolean(version && version === AccountTypeEnum.pro);
export const permissionCheck = (
  gestioneAccessi: AuthReducer['gestioneAccessi'],
  permissions: AuthReducer['permissions']
) => !gestioneAccessi || (gestioneAccessi && !permissions?.utenteDisattivato);

export const isAllowedToUseApp = ({ versione, permissions, gestioneAccessi }: AuthReducer): UseAppCheck => {
  const vCheck = versionCheck(versione);
  const pCheck = permissionCheck(gestioneAccessi, permissions);
  return {
    allowed: vCheck && pCheck,
    versionCheck: vCheck,
    permissionCheck: pCheck
  };
};

// just in production || beta
export const enableProductionServices = () => isBetaVersion() || isProductionVersion();

export const isDevelopmentVersion = () => !enableProductionServices();

export const canShowSidebar = (path: string, authReducer: AuthReducer): boolean => {
  const routeCondition = !isLoginContextRoute(path) && path !== HOME_PATH;
  const useCheck = isAllowedToUseApp(authReducer);
  return routeCondition && useCheck.allowed;
};
