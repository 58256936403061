import { updateLastAccessDbAction } from 'actions/general';
import { setEntityDetailsAction } from 'actions/modals';
import { api } from 'api';
import { useFetch } from 'hook/useFetch';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MioCondominioInfo } from 'types/archive';
import { selectors } from 'utils/reduxSelector';
import { getHeadWayConfig } from 'design-component/layout/components/topbar/components/headway';
import { HOME_PATH, privateRoutesNames } from 'config/routesInMenu';
import LoadingSpinner from 'design-component/loadingSpinner';

export const RouteListener: FC = ({ children }) => {
  const { pathname } = useLocation();
  const entityDetails = useSelector(selectors.modalSelector.entityDetails);
  const { currentDb, changelogReady } = useSelector(selectors.generalSelector.generalReducer);
  const dispatch = useDispatch();
  const getInfo = pathname === privateRoutesNames.activities;

  const successCallback = useCallback(
    (data: MioCondominioInfo) => {
      dispatch(updateLastAccessDbAction(data));
      if (changelogReady) {
        window?.Headway?.init(getHeadWayConfig(dispatch));
      }
    },
    [dispatch, changelogReady]
  );

  const { launch, isLoading } = useFetch(api.archive.updateLastAccessDb, [currentDb?.id || '', getInfo], {
    autoStart: false,
    successCallback
  });

  const updateArchiveAccess = useCallback(() => {
    if (currentDb?.id && pathname !== HOME_PATH) {
      launch([currentDb.id, getInfo]);
    }
  }, [currentDb, getInfo, launch, pathname]);

  const handleChangePath = useCallback(
    () => {
      updateArchiveAccess();
      entityDetails && dispatch(setEntityDetailsAction(undefined));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, updateArchiveAccess]
  );

  useEffect(() => {
    handleChangePath();
  }, [pathname, handleChangePath]);

  return isLoading ? <LoadingSpinner position="fixed" /> : <>{children}</>;
};
