import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { CreaFondoCondomino, FondoCondomino } from 'types/condominio';

export const getDetails = async (condGenID: number, risorsaID: number, fondoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/risorsa/${risorsaID}/fondo/${fondoID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<FondoCondomino>(obj);
};

export const add = async (condGenID: number, risorsaID: number, fondo: CreaFondoCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(fondo) };
  const url = `/api/condominio/${condGenID}/risorsa/${risorsaID}/fondo`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<FondoCondomino>(obj);
};

export const edit = async (condGenID: number, risorsaID: number, fondoID: number, fondo: CreaFondoCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(fondo) };
  const url = `/api/condominio/${condGenID}/risorsa/${risorsaID}/fondo/${fondoID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<FondoCondomino>(obj);
};

export const remove = async (condGenID: number, risorsaID: number, fondoID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/risorsa/${risorsaID}/fondo/${fondoID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
