import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { PrintGetDefaultSettingsParameters, BasePrintSettings } from 'types/print';

export const getPrintSettings = async (settings: PrintGetDefaultSettingsParameters) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const url = '/api/stampa/impostazioni';
  const additionalOpts: GretchOptions = { body: JSON.stringify(settings) };
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<BasePrintSettings>(obj);
};
