import { Logout } from 'actions/auth/types';
import { ResetState } from 'actions/general/types';
import { InstalmentDetail, InstalmentDetailIDS, InstalmentFilter } from 'types/instalment';

export enum InstalmentActionNames {
  SET_FILTERS = 'SET_FILTERS',
  SET_CURRENT_INSTALMENT = 'SET_CURRENT_INSTALMENT',
  SET_CURRENT_INSTALMENT_IDS = 'SET_CURRENT_INSTALMENT_IDS'
}

export type SetInstalmentFilters = {
  type: typeof InstalmentActionNames.SET_FILTERS;
  payload: InstalmentFilter;
};

export type SetCurrentInstalment = {
  type: typeof InstalmentActionNames.SET_CURRENT_INSTALMENT;
  payload: InstalmentDetail | undefined;
};

export type SetCurrentInstalmentID = {
  type: typeof InstalmentActionNames.SET_CURRENT_INSTALMENT_IDS;
  payload: InstalmentDetailIDS;
};

export type InstalmentTypes =
  | ResetState
  | Logout
  | SetInstalmentFilters
  | SetCurrentInstalment
  | SetCurrentInstalmentID;
