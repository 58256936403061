import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const grey: color = {
  50: '#fafbfc',
  100: '#ebecf0',
  200: '#c1c7d0',
  300: '#a5adba',
  400: '#7a869a',
  500: '#6b778c',
  600: '#5e6c84',
  700: '#505f79',
  800: '#253858',
  900: '#091e42'
};
