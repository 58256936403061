import { BaseItem, PageContext } from 'types';
import { CondominioActionNames, CondominiTypes } from 'actions/condomini/types';
import { AuthActionNames } from 'actions/auth/types';
import { CondominioBase } from 'types/condominio';
import { GeneralActionNames } from 'actions/general/types';
import { CONDOMINI_FILTER_KEYS } from 'constants/filters';

export type CondominiReducer = {
  currentCondominio?: CondominioBase;
  currentEsercizio?: BaseItem;
} & Pick<PageContext, 'filters'>;

export const initialState: CondominiReducer = {
  filters: { searchText: '', advanced: { [CONDOMINI_FILTER_KEYS[1]]: { value: 'true', label: 'Condomìni gestiti' } } }
};

export const condominiReducer = (state: CondominiReducer = initialState, action: CondominiTypes): CondominiReducer => {
  switch (action.type) {
    case CondominioActionNames.SET_CURRENT_CONDOMINIO:
      return { ...state, currentCondominio: action.payload };
    case CondominioActionNames.SET_CURRENT_ESERCIZIO:
      return { ...state, currentEsercizio: action.payload };
    case CondominioActionNames.SET_FILTERS:
      return { ...state, filters: action.payload };
    case GeneralActionNames.RESET_STATE:
    case AuthActionNames.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
