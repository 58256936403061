import { EntityFilters } from 'types';
import { PaginationType } from 'types/helpers';
import { showErrorToast } from 'utils/toast';

export const getPersonPageContextFromResponse = (res: Response, filters: EntityFilters) => {
  const pagination = res.headers.get('x-pagination');
  const paginationObj: PaginationType | null = pagination ? JSON.parse(pagination) : null;
  const totalCount = paginationObj ? paginationObj.totalCount : 0;
  return {
    totalCount,
    currentPage: paginationObj?.currentPage || 1,
    filters
  };
};

export const parsePagination = (res: Response) => {
  const pagination = res.headers.get('x-pagination');
  let result: PaginationType | undefined = undefined;
  if (pagination) {
    try {
      result = JSON.parse(pagination);
      return result;
    } catch (e) {
      showErrorToast('Errore nel recupero della paginazione');
    } finally {
      return result;
    }
  }
};
