import { paletteColor } from '@fattureincloud/fic-design-system';
import { stepStatusType } from '@fattureincloud/fic-design-system/dist/components/stepper/types';
import { colors as defaultPalette } from 'palette/colors';

export interface ItemStatusStyles {
  color: paletteColor;
  circleBackground: paletteColor;
  circleTextColor: paletteColor;
}

type ItemPalette = {
  [k in stepStatusType]: ItemStatusStyles;
};

export interface StepperPalette {
  item: ItemPalette;
}

const stepperPalette: StepperPalette = {
  item: {
    normal: {
      color: defaultPalette.grey[500],
      circleBackground: defaultPalette.grey[500],
      circleTextColor: defaultPalette.white[100]
    },
    active: {
      color: defaultPalette.blue[500],
      circleBackground: defaultPalette.blue[500],
      circleTextColor: defaultPalette.white[100]
    },
    completed: {
      color: defaultPalette.grey[500],
      circleBackground: defaultPalette.green[500],
      circleTextColor: defaultPalette.white[100]
    }
  }
};

export default stepperPalette;
