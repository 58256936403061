import { EntityFilters } from 'types';
import { CommonParts, Environment, Implant } from 'types/commonParts';
import {
  CommonPartsActionNames,
  SetCommonPartsFilters,
  SetCurrentCommonParts,
  SetCurrentEnvironment,
  SetCurrentImplant
} from './types';

export const setCommonPartsFiltersAction = (data: EntityFilters): SetCommonPartsFilters => ({
  type: CommonPartsActionNames.SET_FILTERS,
  payload: data
});

export const setCurrentCommonPartsAction = (data: CommonParts | undefined): SetCurrentCommonParts => ({
  type: CommonPartsActionNames.SET_CURRENT_COMMON_PARTS,
  payload: data
});

export const setCurrentImplantAction = (data: Implant | undefined): SetCurrentImplant => ({
  type: CommonPartsActionNames.SET_IMPLANT,
  payload: data
});
export const setCurrentEnvironmentAction = (data: Environment | undefined): SetCurrentEnvironment => ({
  type: CommonPartsActionNames.SET_ENVIRONMENT,
  payload: data
});
