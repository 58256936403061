import { MODAL_IDS } from 'config/modals';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { closeNewDossierModalAction, openDossierModalAction } from 'actions/modals';

const mapStateToProps = ({ generalReducer, modalsReducer }: RootState) => ({
  isMobileScreen: generalReducer.viewportInfo.isMobileScreen,
  status: modalsReducer.modalIdsStatus[MODAL_IDS.SHOW_NEW_DOSSIER],
  data: modalsReducer.dossierModalData
});

export const connector = connect(mapStateToProps, { closeNewDossierModalAction, openDossierModalAction });
type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux;

export type CloseAction = () => void;
