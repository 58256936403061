import { useCallback, useEffect, useState } from 'react';
import { APIManagerError, APIManagerResponse } from 'apiManager/types';
import { apiErrorHandler } from 'apiManager/apiErrorHandler';
import { APICallOptions } from 'HOC/withAPILoading';
import { PaginationType } from 'types/helpers';

export function useFetch<T, P extends unknown[]>(
  call: (...args: P) => Promise<APIManagerResponse<T>>,
  args: P,
  options?: APICallOptions<T>
) {
  const [data, setData] = useState<APIManagerResponse<T>['data']>(undefined);
  const [error, setError] = useState<APIManagerError | undefined>(undefined);
  const [pagination, setPagination] = useState<PaginationType | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = (error: APIManagerError, { errorToastMessage }: APICallOptions<T>) => {
    apiErrorHandler(errorToastMessage ? { code: error.code, message: errorToastMessage, status: error.status } : error);
  };

  const launch = useCallback(
    async (updatedArgs: P) => {
      const { ignoreError, successCallback, failureCallback } = options ?? {};
      setIsLoading(true);
      const result = await call(...updatedArgs);
      const { data, error, pagination } = result;
      if (data) {
        setData(data);
        successCallback && successCallback(data);
      }
      if (error) {
        setError(error);
        !ignoreError && handleError(error, options ?? { errorToastMessage: '' });
        failureCallback && failureCallback(error);
      }
      setPagination(pagination);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call]
  );

  useEffect(() => {
    const enableCall = options?.autoStart ?? true;
    enableCall && launch(args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launch]);

  return { data, error, isLoading, pagination, launch };
}
