import { Icon, IconProps } from '@fattureincloud/fic-design-system';
import React, { FC, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { convertSize } from './utils';
import { ColorsList, ColorValues, FontSize } from 'types/designSystem';
import { defaultPalette } from 'palette';
import { IsDisabled } from 'types/utils';

type StyleProps = {
  size: FontSize;
  color: ColorsList;
  overrideBackground?: ColorsList;
  overrideBackgroundIntensity?: ColorValues;
} & Partial<IsDisabled>;

type Props = Omit<IconProps, 'isDisabled'> & StyleProps;

const ContentWrapper = styled.div<StyleProps>`
  ${({ size, color, overrideBackground, overrideBackgroundIntensity }) => {
    const convertedSize = convertSize(size);
    return css`
      background-color: ${defaultPalette[overrideBackground ?? color][overrideBackgroundIntensity ?? 50]};
      min-height: calc(2 * ${convertedSize});
      min-width: calc(2 * ${convertedSize});
      height: calc(2 * ${convertedSize});
      width: calc(2 * ${convertedSize});
    `;
  }}
  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${defaultPalette['grey'][50]};
          cursor: not-allowed;
          & > * {
            color: ${defaultPalette['grey'][500]};
          }
        `
      : undefined}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const IconWithBackground: FC<Props> = ({
  overrideBackground,
  overrideBackgroundIntensity,
  disabled,
  onClick,
  ...props
}) => (
  <ContentWrapper
    size={props.size}
    color={props.color}
    overrideBackground={overrideBackground}
    overrideBackgroundIntensity={overrideBackgroundIntensity}
    disabled={disabled}
    onClick={disabled ? undefined : (onClick as unknown as MouseEventHandler<HTMLDivElement>)}
  >
    <Icon {...props} isDisabled={disabled} />
  </ContentWrapper>
);
