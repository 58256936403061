import { MODAL_IDS } from 'config/modals';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { closeDossierModalAction } from 'actions/modals';
import { ModalDossierData } from 'types/dossier';
import { isAttachmentsEnabled } from 'utils/permissions';

const mapStateToProps = ({ authReducer, generalReducer, modalsReducer }: RootState) => {
  const { gestioneAccessi, permissions } = authReducer;
  const isAllowed = isAttachmentsEnabled(gestioneAccessi, permissions);
  const show = modalsReducer.modalIdsStatus[MODAL_IDS.SHOW_DOSSIER];
  return {
    isMobileScreen: generalReducer.viewportInfo.isMobileScreen,
    status: show && isAllowed,
    data: modalsReducer.dossierModalData
  };
};

export const connector = connect(mapStateToProps, { closeDossierModalAction });
type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux;

export type CommonViewProps = { data: ModalDossierData };
