import { Activity } from 'types/activity';
import React from 'react';
import { translate } from 'translations';
import style from './headerDetailsDrawer.module.scss';
import { IconWithBackground } from 'design-component/iconWithBackground';
import { getAttivitaIcon } from 'constants/icon';

export const activityHeader = (currentActivity: Activity) => () => {
  const { intestazioneCondominio, numeroPratica, titolo, stato, gestore, tipoSegnalazDescr } = currentActivity;

  const getActivityStatusString = () => {
    switch (stato) {
      case 0:
        return translate('ActivityStateClosed');
      case 1:
        return translate('ActivityStateOpen');
      case 2:
        return translate('ActivityStateSuspended');
      case 3:
      default:
        return translate('ActivityStateClosed');
    }
  };

  const ActivityStatusString = getActivityStatusString();
  const iconData = getAttivitaIcon(tipoSegnalazDescr);

  return (
    <div className={style.container}>
      <div className="d-flex align-items-center">
        <IconWithBackground
          icon={iconData.icon}
          color={iconData.color}
          size="1x"
          overrideBackground="grey"
          overrideBackgroundIntensity={100}
        />

        <p className="ml-2 font--grey">{intestazioneCondominio?.replace('Condominio', '')}</p>
      </div>
      <p className={style.object}>
        {numeroPratica} - {titolo}
      </p>
      <p
        className={`${style.open_date} ${style[`open_date__${stato}`]}`}
        dangerouslySetInnerHTML={{
          __html: translate('openDayHourFrom', ActivityStatusString, gestore)
        }}
      />
    </div>
  );
};
