import { ACTIVITY_INITIAL_PAGE_CONTEXT } from 'constants/page';
import { EntityFilters, PageContext } from 'types';
import { Activity, PFInterventionMessage, StatusEnum } from 'types/activity';

type ActivityStatus = { activityStatus: StatusEnum };

export type ActivityFilter = EntityFilters & ActivityStatus;

export type ActivityPageContext = PageContext<ActivityFilter> & {
  paginationTotalCount: number;
  totalCleanCount?: number;
  totalWarningsCount?: number;
  totalOpenCount: number;
  totalSuspendedCount: number;
  totalClosedCount: number;
};

export type ActivitySyncMessages = {
  dataUltimoAggiornamentoInterventi: string | undefined;
  interventiPersi: PFInterventionMessage[] | undefined;
};

export type ActivitiesReducer = {
  activitiesList: Activity[];
  currentActivity?: Activity;
  pageContext: ActivityPageContext;
  // When a new activity is created the returning object is stored here until is useful
  lastActivityCreated?: Activity;
  interventionMessagesStatus: ActivitySyncMessages;
};

export const initialState: ActivitiesReducer = {
  activitiesList: [],
  pageContext: ACTIVITY_INITIAL_PAGE_CONTEXT,
  interventionMessagesStatus: { dataUltimoAggiornamentoInterventi: undefined, interventiPersi: [] }
};
