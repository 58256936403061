import { AuthActionNames, AuthActionTypes } from '../../actions/auth/types';
import { AccountTypeEnum } from 'types/account';
import { AuthReducer } from './types';

export const authorizationInitialState = (accessToken = '', refreshToken = '', username = ''): AuthReducer => ({
  accessToken,
  refreshToken,
  username,
  versione: AccountTypeEnum.one,
  gestioneAccessi: false
});

export const authReducer = (state: AuthReducer = authorizationInitialState(), action: AuthActionTypes): AuthReducer => {
  switch (action.type) {
    case AuthActionNames.SET_AUTHORIZATION_DATA:
      return { ...state, ...action.payload };

    case AuthActionNames.SET_LOGIN_TOKEN:
      return { ...state, ...action.payload };

    case AuthActionNames.SET_ACCOUNT: {
      const { versione, gestioneAccessi, gestioneAccessiData } = action.payload;
      return { ...state, versione, gestioneAccessi, permissions: gestioneAccessiData };
    }

    case AuthActionNames.RESET_LOGIN_STATE:
    case AuthActionNames.LOGOUT:
      return { ...authorizationInitialState() };

    default:
      return state;
  }
};
