import { FontSize } from 'types/designSystem';

export const convertSize = (size: FontSize) => {
  switch (size) {
    case 'xs':
      return '0.75em';
    case 'sm':
      return '0.875em';
    case 'lg':
      return '1.33em';
    case '1x':
      return '1em';
    case '2x':
      return '2em';
    case '3x':
      return '3em';
    case '4x':
      return '4em';
    case '5x':
      return '5em';
    case '6x':
      return '6em';
    case '7x':
      return '7em';
    case '8x':
      return '8em';
    case '9x':
      return '9em';
    case '10x':
      return '10em';
  }
};
