import { SuspenseWrapper } from 'components/suspenseWrapper';
import React, { FC, lazy } from 'react';
import { Props, connector } from './types';

const LazyDossiersImport = lazy(() => import('./index'));

const LazyI: FC<Props> = (props) => (
  <SuspenseWrapper>{props.status ? <LazyDossiersImport {...props} /> : <></>}</SuspenseWrapper>
);

export const LazyDossiers = connector(LazyI);
