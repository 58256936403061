import { IconBackgroundPalette } from '@fattureincloud/fic-design-system';
import { colors as defaultPalette } from 'palette/colors';

const iconBackgroundPalette: IconBackgroundPalette = {
  blue: defaultPalette.blue[50],
  indigo: defaultPalette.indigo[50],
  yellow: defaultPalette.yellow[50],
  orange: defaultPalette.orange[50],
  red: defaultPalette.red[50],
  green: defaultPalette.green[50],
  fuchsia: defaultPalette.fuchsia[50],
  pink: defaultPalette.pink[50],
  cyan: defaultPalette.cyan[50],
  primary: defaultPalette.primary[50],
  grey: defaultPalette.grey[100],
  white: defaultPalette.white[16],
  black: defaultPalette.black[16]
};

export default iconBackgroundPalette;
