import { APIManager } from 'apiManager';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'apiManager/types';
import { FinancialMovement, FinancialMovementsData, ReferenceTypeEnum } from 'types/financialMovements';

export const getInvoiceList = async ({ id, referenceType }: FinancialMovementsData) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const queryStringParam = new URLSearchParams();
  queryStringParam.set('ID', id.toString());
  queryStringParam.set('TipoRiferimento', ReferenceTypeEnum[referenceType]);
  const url = `/api/movimenti?${queryStringParam}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<FinancialMovement[]>(obj);
};
