import React, { createContext, FC, useState } from 'react';
import { Dossier, DossierOrigin, NewDossierData } from 'types/dossier';
import { SetData } from 'types/utils';

export type TDossierContext = {
  currentOrigin?: DossierOrigin;
  currentDossier?: Dossier;
  setCurrentOrigin: SetData<DossierOrigin>;
  setCurrentDossier: SetData<Dossier>;
  isNewDossier: boolean;
};

const defaultValue = (newDossierData?: NewDossierData): TDossierContext => ({
  currentOrigin: newDossierData?.origin,
  currentDossier: newDossierData?.dossier,
  setCurrentOrigin: () => undefined,
  setCurrentDossier: () => undefined,
  isNewDossier: false
});

export const DossierContext = createContext<TDossierContext>(defaultValue());

type Props = { newDossierData?: NewDossierData };
export const DossierContextProvider: FC<Props> = ({ newDossierData, children }) => {
  const initialValue = defaultValue(newDossierData);
  const [currentOrigin, setCurrentOrigin] = useState<TDossierContext['currentOrigin']>(initialValue.currentOrigin);
  const [currentDossier, setCurrentDossier] = useState<TDossierContext['currentDossier']>(initialValue.currentDossier);

  return (
    <DossierContext.Provider
      value={{
        currentOrigin,
        setCurrentOrigin,
        currentDossier,
        setCurrentDossier,
        isNewDossier: Boolean(newDossierData)
      }}
    >
      {children}
    </DossierContext.Provider>
  );
};
