import { ButtonPalette } from '@fattureincloud/fic-design-system/dist/components/buttons/button/buttonPalette';
import { colors as defaultPalette } from 'palette/colors';

const buttonPalette: ButtonPalette = {
  primary: {
    blue: {
      normal: defaultPalette.blue[500],
      hover: defaultPalette.blue[700],
      active: defaultPalette.blue[900],
      text: defaultPalette.white[100],
      textHover: defaultPalette.grey[100]
    },
    red: {
      normal: defaultPalette.red[500],
      hover: defaultPalette.red[700],
      active: defaultPalette.red[900],
      text: defaultPalette.white[100],
      textHover: defaultPalette.grey[100]
    },
    green: {
      normal: defaultPalette.green[500],
      hover: defaultPalette.green[700],
      active: defaultPalette.green[900],
      text: defaultPalette.white[100],
      textHover: defaultPalette.grey[100]
    },
    grey: {
      normal: defaultPalette.white[100],
      hover: defaultPalette.grey[100],
      active: defaultPalette.grey[200],
      text: defaultPalette.black[100],
      textHover: defaultPalette.black[100]
    },
    yellow: {
      normal: defaultPalette.yellow[500],
      hover: defaultPalette.yellow[700],
      active: defaultPalette.yellow[900],
      text: defaultPalette.white[100],
      textHover: defaultPalette.grey[100]
    },
    disabled: {
      normal: defaultPalette.grey[50],
      hover: defaultPalette.grey[50],
      active: defaultPalette.grey[50],
      text: defaultPalette.grey[200],
      textHover: defaultPalette.grey[200]
    }
  },
  secondary: {
    blue: {
      normal: defaultPalette.blue[50],
      hover: defaultPalette.blue[100],
      active: defaultPalette.blue[200],
      text: defaultPalette.blue[500],
      textHover: defaultPalette.blue[500]
    },
    red: {
      normal: defaultPalette.red[50],
      hover: defaultPalette.red[100],
      active: defaultPalette.red[200],
      text: defaultPalette.red[500],
      textHover: defaultPalette.red[500]
    },
    green: {
      normal: defaultPalette.green[50],
      hover: defaultPalette.green[100],
      active: defaultPalette.green[200],
      text: defaultPalette.green[500],
      textHover: defaultPalette.green[500]
    },
    yellow: {
      normal: defaultPalette.yellow[50],
      hover: defaultPalette.yellow[100],
      active: defaultPalette.yellow[200],
      text: defaultPalette.yellow[500],
      textHover: defaultPalette.yellow[500]
    },
    grey: {
      normal: defaultPalette.grey[50],
      hover: defaultPalette.grey[100],
      active: defaultPalette.grey[200],
      text: defaultPalette.black[100],
      textHover: defaultPalette.black[100]
    },
    disabled: {
      normal: defaultPalette.grey[50],
      hover: defaultPalette.grey[50],
      active: defaultPalette.grey[50],
      text: defaultPalette.grey[200],
      textHover: defaultPalette.grey[200]
    }
  },
  text: {
    blue: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      text: defaultPalette.blue[500],
      textHover: defaultPalette.blue[500]
    },
    red: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      text: defaultPalette.red[500],
      textHover: defaultPalette.red[500]
    },
    green: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      text: defaultPalette.green[500],
      textHover: defaultPalette.green[500]
    },
    grey: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      text: defaultPalette.grey[500],
      textHover: defaultPalette.grey[500]
    },
    yellow: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      text: defaultPalette.yellow[500],
      textHover: defaultPalette.yellow[500]
    },
    disabled: {
      normal: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      text: defaultPalette.grey[200],
      textHover: defaultPalette.grey[200]
    }
  },
  defaultType: 'primary',
  defaultColor: 'blue',
  defaultSize: 'medium'
};

export default buttonPalette;
