import { FIREBASE_NOTIFICATION_COOKIE } from 'config/cookie';
import moment from 'moment';
import { DEFAULT_SET_OPTIONS } from './constant';
import { cookies } from './index';

export const set = (days: number) => {
  cookies.set(FIREBASE_NOTIFICATION_COOKIE, 'enabled', {
    expires: moment().add(days, 'days').toDate(),
    ...DEFAULT_SET_OPTIONS
  });
};

export const get = () => {
  const firebaseToken = cookies.get(FIREBASE_NOTIFICATION_COOKIE);
  return firebaseToken;
};

export const remove = () => cookies.remove(FIREBASE_NOTIFICATION_COOKIE);
