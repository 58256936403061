import * as activity from './activity';
import * as archive from './archive';
import * as attachment from './attachment';
import * as authorization from './authorization';
import * as contact from './contact';
import * as conto from './conto';
import * as commonParts from './commonParts';
import * as condominio from './condominio';
import * as dropdown from './dropdown';
import * as financialMovements from './financialMovements';
import * as firebase from './firebase';
import * as general from './general';
import * as instalment from './instalment';
import * as print from './print';
import * as provider from './provider';
import * as report from './report';

export const api = {
  activity,
  archive,
  attachment,
  authorization,
  contact,
  conto,
  commonParts,
  condominio,
  dropdown,
  financialMovements,
  firebase,
  general,
  instalment,
  print,
  provider,
  report
};
