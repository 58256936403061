import {
  DisplaySubList,
  GruppedTypeEnum,
  InstalmentDisplayData,
  InstalmentOnRegistry,
  InstalmentOnUnity
} from 'types/instalment';
import { isOrderByUnity } from 'utils/instalments';
import { RefinedUnityRegistryList } from './components/dataProvider/types';

export const convertToInstalmentGeneralList = (
  data: InstalmentOnRegistry[] | InstalmentOnUnity[],
  refinedUnityRegistryList: RefinedUnityRegistryList,
  type: GruppedTypeEnum
): InstalmentDisplayData[] => {
  const isUnityOrder = isOrderByUnity(type);
  const noEmptyArray = refinedUnityRegistryList.filter((o) => o.length);
  const list: InstalmentDisplayData[] = [];
  noEmptyArray.forEach((unityRegistry) => {
    const firstElement = unityRegistry[0];
    const id = isUnityOrder ? firstElement.unitaID : firstElement.anagrID;
    const tipo = type;
    const description = isUnityOrder ? `Unità ${firstElement.unitaDescr}` || '' : firstElement.anagrDescr || '';
    const subList: DisplaySubList[] = [];
    unityRegistry.forEach((el) => {
      const id = isUnityOrder ? el.anagrID : el.unitaID;
      const name = isUnityOrder ? el.anagrDescr || '' : `Unità ${el.unitaDescr}` || 'Unità';
      const roles = el.listaRuoli ? el.listaRuoli.map((o) => o.descrizione ?? '').filter((o) => o.length) : [];
      subList.push({ id, name, roles });
    });
    const element = isUnityOrder
      ? (data as InstalmentOnUnity[]).find((o) => o.unitaID === id)
      : (data as InstalmentOnRegistry[]).find((o) => o.anagrID === id);
    if (element) {
      const { dovuto, versato, saldo, importoScaduto, scaduta, tipoRata } = element;
      const item: InstalmentDisplayData = {
        id,
        tipo,
        description,
        subList,
        dovuto,
        versato,
        saldo,
        importoScaduto,
        scaduta,
        tipoRata
      };
      list.push(item);
    }
  });

  return list;
};

export const convertSubList = (subList: DisplaySubList[]) =>
  subList.map((o) => `${o.name} ${o.roles.length ? `(${o.roles.join(', ')})` : ''}`).join(', ');
