import { Id, ObjVersion } from 'types';
import { Operator, Pid } from './account';
import { CondominioReadViewModel } from 'types/condominio';
import { Icon, ColorsList } from './designSystem';
import { faCrown, faUser, faHammer } from '@fortawesome/pro-regular-svg-icons';
import { Emails } from './registry';
import { DateTime } from './utils';

export enum StatusEnum {
  Nessuno = 0,
  Aperta = 1,
  Sospesa = 2,
  Chiusa = 3
}

export enum SorgenteEnum {
  System = 0,
  User = 1,
  Miocondominio = 2
}

export enum PriorityEnum {
  Bassa = 1,
  Media = 2,
  Alta = 3,
  Urgente = 4
}

export enum EventTypeEnum {
  Stato = 0,
  Gestore = 1,
  Nota = 2,
  Fornitore = 3,
  Referente = 4,
  'Descrizione iniziale' = 5
}

export const priorityRadioButtonOptions: Array<{ key: number; label: string }> = [
  { key: 1, label: PriorityEnum[1] },
  { key: 2, label: PriorityEnum[2] },
  { key: 3, label: PriorityEnum[3] },
  { key: 4, label: PriorityEnum[4] }
];

/**
 * Applicant
 */
export const ApplicantTypeArray = [-1, 0, 1] as const;
export type ApplicantType = typeof ApplicantTypeArray[number];

export type IconInfo = Icon & { color: ColorsList };

type ApplicantTypeIconMap = {
  [key in ApplicantType]: IconInfo;
};

export const applicantTypeIconMap: ApplicantTypeIconMap = {
  '-1': { icon: faCrown, color: 'yellow' },
  '0': { icon: faUser, color: 'blue' },
  '1': { icon: faHammer, color: 'red' }
};

export type ApplicantInfo = Id & { descr: string; tipo: ApplicantType };
export type Applicant = ApplicantInfo & { condomini: CondominioReadViewModel[] };

/**
 * Event
 */
export type ActivityEventNote = { operatore: string; dataAgenda?: DateTime; descrizione: string };

export enum EventSourceEnum {
  'sistema' = 0,
  'utente' = 1,
  'MioCondominio' = 2
}

export type ActivityEvent = Id &
  ActivityEventNote & {
    attivitaID: number;
    sorgente: EventSourceEnum;
    tipo: EventTypeEnum;
    data: DateTime;
    editabile: boolean;
    nomeFile: string | null;
    linkAllegato: string | null;
  };

/**
 * Intervention
 */
export enum PFStateEnum {
  NonInviato = 0,
  DaAccettare = 1,
  Accettato = 2,
  Rifiutato = 3,
  Annullato = 4,
  ConclusoSuccesso = 5,
  ConclusoFallimento = 6
}

type PortaleFornitori = { pfOnlineToken?: string; pfOnlineID?: number; pfOnlineStato: PFStateEnum };

export type AssignmentApplicant = { attivitaReferente?: string; attivitaReferenteContatto?: string };

export type ActivityIntervention = Id &
  PortaleFornitori &
  AssignmentApplicant & {
    servizioID?: number;
    servizioDescrizione?: string;
    note?: string;
    operatore?: string;
    dataPrevista?: DateTime;
    dataAgenda?: DateTime;
    attivitaID: number;
    fornitoreID: number;
    data: DateTime;
    dataEsecuzione?: DateTime;
    causaUltimoCambioData?: string;
  };

export type InterventionChangeDataInfo = Pick<ActivityIntervention, 'dataPrevista'> &
  Required<Pick<ActivityIntervention, 'operatore'>> & {
    causaCambioData?: string;
  };

export type TelephoneContact = { descrizione: string; numero: string };

export type ActivityProviderContact = {
  tel1: TelephoneContact | null;
  tel2: TelephoneContact | null;
  tel3: TelephoneContact | null;
  email: Emails;
  pec: Emails;
};

export type ActivityProvider = Id & {
  descrizione: string;
  tipoAttivita: string;
  interventi: ActivityIntervention[];
  contatti: ActivityProviderContact;
};

/**
 * Activity
 */
type CommonActivityProperty = {
  condGenID?: number;
  intestazioneCondominio: string;
  richiedenteID?: number;
  gestore: string;
  tipoSegnalazID: number;
  tipoSegnalazDescr: string;
  titolo: string;
  priorita: PriorityEnum;
  dataApertura: DateTime;
  dataConcludereEntro: DateTime;
};

export type Activity = Id &
  ObjVersion &
  CommonActivityProperty & {
    richiedenteNome: string;
    richiedenteTipo: ApplicantType;
    dataChiusura: DateTime;
    dataPresentazioneProblema: DateTime;
    numeroPratica: number;
    numPrLegGest: number;
    numSinistroGest: number;
    stato: StatusEnum;
    eventi?: ActivityEvent[];
    fornitori?: ActivityProvider[];
    avviso: string;
    pubblWebPubblOn: boolean;
    pubblWebAnagraficaID: number;
    pubblWebAnagraficaNome: string;
    pubblWebGiorniPermanenza: number;
    pubblWebNote: string;
    pubblWebIntegrazioneUtenti: string;
    mioCondominioID: string;
    operatoreUltimoStato: string;
    dataUltimoStato: string;
    modificheNonLette: boolean;
  };

export type CreateActivity = CommonActivityProperty & Operator & { descrizione: string };

/**
 * Activity header info
 */
export type ActivityCountingInfo = {
  totalWarnings: number;
  totalClean: number;
  totalOpen: number;
  totalSuspended: number;
  totalClosed: number;
};

// Assignment
export type SendAssignment = { titolo: string; note: string };

export type SendAssignmentRequestBody = {
  interventoID: number;
  attivitaID: number;
  emailFornitore: string;
} & SendAssignment &
  Pid &
  Operator &
  AssignmentApplicant;

export type FakeAssignentResponse = SendAssignmentRequestBody;

export enum InterventionMessageTypeEnum {
  Accept = 1,
  Reject = 2,
  Postpone = 3,
  Message = 4,
  CloseFailure = 5,
  CloseSuccess = 6,
  Cancel = 7,
  Remind = 8,
  Reopen = 9
}

export type PFInterventionMessage = Pid & {
  interventoID: number;
  messaggioID: number;
  dataCreazione: string;
  mittente: string | undefined;
  destinatario: 'amministratore' | 'fornitore';
  tipo: InterventionMessageTypeEnum;
  contenuto: { contenuto: string; allegato: string };
  token: string | undefined;
};
