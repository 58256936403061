import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const fuchsia: color = {
  50: '#f7e9fb',
  100: '#efd2f7',
  200: '#e6b6f2',
  300: '#db94ec',
  400: '#cb67e4',
  500: '#ad0dd5',
  600: '#9c0bc0',
  700: '#880aa8',
  800: '#71088b',
  900: '#500663'
};
