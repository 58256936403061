import { LOWER_BOUND_BIRTH_YEARS_DIFFERENCE } from 'config/date';
import { translate } from 'translations';
import {
  capRegExp,
  fiscCodeRegExp,
  mailRegExp,
  provinceAcronymRegExp,
  urlRegExp,
  vatNumberRegExp,
  providerFiscCodeRegExp,
  onlyNumberRegExp,
  codiceComuneRegexp
} from './regExp';
import { Validator } from './types';
import validateField from './validateField';
import moment from 'moment';
import { EMAIL_CHARACHTER_LIMIT } from 'config/emailCharacterLimit';

/**
 * Validation functions
 */

/**
 * Email validator
 * @param email string
 */
const isValidEmail: Validator = (email) => {
  if (!mailRegExp.test(email)) {
    return { status: 'error', message: translate('errorMessageEmail') };
  }
  return undefined;
};

// the emails must be piped
const isValidEmailCharacterLimit: Validator = (pipedEmails) => {
  if (pipedEmails.length > EMAIL_CHARACHTER_LIMIT) {
    return { status: 'error', message: translate('errorMessageEmailLenght') };
  }
  return undefined;
};
/**
 * PEC validator
 * @param email string
 */
const isValidPEC: Validator = (email) => {
  if (!mailRegExp.test(email)) {
    return { status: 'error', message: translate('errorMessagePEC') };
  }
  return undefined;
};
/**
 * Url validator
 * @param url string
 */
const isValidUrl: Validator = (url) => {
  if (!urlRegExp.test(url)) {
    return { status: 'error', message: translate('errorMessageUrl') };
  }
  return undefined;
};
/**
 * CID validator
 * @param cid string
 */
const isValidCID: Validator = (cid) => {
  if (!onlyNumberRegExp.test(cid)) {
    return { status: 'error', message: translate('errorMessageCID') };
  }
  return undefined;
};
/**
 * Vat number validator
 * @param vat string
 */
const isValidVATNumber: Validator = (vat) => {
  if (!vatNumberRegExp.test(vat)) {
    return { status: 'error', message: translate('errorMessageVatNumber') };
  }
  return undefined;
};
/**
 * Fiscal code validator
 * @param ficCode string
 */
const isValidFiscalCode: Validator = (ficCode) => {
  if (!fiscCodeRegExp.test(ficCode)) {
    return { status: 'error', message: translate('errorMessageFiscCode') };
  }
  return undefined;
};
/**
 * CAP validator
 * @param cap string
 */
const isValidCAP: Validator = (cap) => {
  if (!capRegExp.test(cap)) {
    return { status: 'error', message: translate('errorMessageCAP') };
  }
  return undefined;
};
/**
 * Province validator
 * @param province string
 */
const isValidProvinceAcronym: Validator = (province) => {
  if (!provinceAcronymRegExp.test(province)) {
    return { status: 'error', message: translate('errorMessageProvinceAcronym') };
  }
  return undefined;
};

/**
 * Textarea word count validator
 * @param maxLength number
 * @param value string
 */
type TMaxCharacterValidator = (maxLength: number) => Validator;
const maxLength: TMaxCharacterValidator = (maxLength) => (value) => {
  if (maxLength && value.length > maxLength) {
    return { status: 'error', message: translate('errorMessageMaxLength', [maxLength]) };
  }
  return undefined;
};

/**
 * Provider fiscal code validator (could be also like vat number )
 * @param ficCode string
 */
const isValidProviderFiscalCode: Validator = (ficCode) => {
  if (!providerFiscCodeRegExp.test(ficCode)) {
    return { status: 'error', message: translate('errorMessageFiscCode') };
  }
  return undefined;
};

/**
 * Durc Validator
 *
 * @durcPipedString this is string is piped in this way : "durc | dataRichiestaDurc | dataScadenzaDurc"
 */
const isValidDurc: Validator = (durcPipedString) => {
  const values = durcPipedString.split(' | ');
  const statoDurc = parseInt(values[0]);
  if (statoDurc === 1) {
    return undefined;
  }
  if (statoDurc === 2) {
    const dataRichiestaDurc = values[1];
    return dataRichiestaDurc && moment(dataRichiestaDurc).isValid()
      ? undefined
      : { status: 'error', message: translate('error') };
  }
  if (statoDurc === 3) {
    const dataScadenzaDurc = values[2];
    return dataScadenzaDurc && moment(dataScadenzaDurc).isValid()
      ? undefined
      : { status: 'error', message: translate('error') };
  }
};

/**
 * Date Validator
 */
const isValidDate: Validator = (stringifyErrorDate) =>
  stringifyErrorDate.length ? { status: 'error', message: translate('error') } : undefined;

/**
 * Check if the date is not less than 120 years
 */
const isValidDateBound: Validator = (stringDataValue) => {
  const today = moment().startOf('day');
  const dataToCheck = moment(stringDataValue).startOf('day');
  const difference = dataToCheck.diff(today, 'years');
  return Math.abs(difference) > LOWER_BOUND_BIRTH_YEARS_DIFFERENCE
    ? { status: 'error', message: translate('error') }
    : undefined;
};
/**
 * Check if the date is not less than 120 years
 */
const isValidCodiceComune: Validator = (codiceComune) => {
  if (!codiceComuneRegexp.test(codiceComune)) {
    return { status: 'error', message: 'Codice comune non valido, deve iniziare con una lettera, seguito da 3 cifre' };
  }
  return undefined;
};

export const validators = {
  validateField,
  isValidEmail,
  isValidEmailCharacterLimit,
  isValidPEC,
  isValidUrl,
  isValidCID,
  isValidVATNumber,
  isValidFiscalCode,
  isValidCAP,
  isValidProvinceAcronym,
  maxLength,
  isValidProviderFiscalCode,
  isValidDate,
  isValidDateBound,
  isValidDurc,
  isValidCodiceComune
};
