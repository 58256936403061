import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const indigo: color = {
  50: '#e8ecfb',
  100: '#d0d7f6',
  200: '#b3bff1',
  300: '#90a3eb',
  400: '#637ce3',
  500: '#0d35d5',
  600: '#0b2fbf',
  700: '#0a29a6',
  800: '#082188',
  900: '#05175e'
};
