import { AdvancedFilterPayload, AdvancedFilters, EntityFilters } from 'types';

export const setEntityAdvancedFilters = (advancedFilters: AdvancedFilters, payload: AdvancedFilterPayload) => {
  const { key, filter } = payload;
  const newFilters = { ...advancedFilters, [key]: filter };
  return newFilters;
};

export const clearedEntityAdvancedFilters = (advancedFilters: AdvancedFilters) => {
  const clearedActivitiesAdvancedFilters = { ...advancedFilters };
  Object.keys(clearedActivitiesAdvancedFilters).forEach(
    (filter) => (clearedActivitiesAdvancedFilters[filter as keyof typeof clearedActivitiesAdvancedFilters] = undefined)
  );
  return clearedActivitiesAdvancedFilters;
};

export const checkAdvancedFilterPresence = (keys: string[], advancedFilters: AdvancedFilters): boolean => {
  let advancedFiltersPresence = false;
  keys.forEach((key) => {
    if (advancedFilters[key]) {
      advancedFiltersPresence = true;
    }
  });

  return advancedFiltersPresence;
};

export const showEmptyEntity = ({ searchText, advanced }: EntityFilters): boolean => {
  const filterPresence = Object(advanced).length;
  return searchText.length === 0 && filterPresence === 0;
};
