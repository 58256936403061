import { APIManager } from 'apiManager';
import { AccessToken, AccountData, FullCredential, LoginCredential, MobileLoginData } from 'types/account';
import { GretchOptions } from 'gretchen';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { genericAPIErrorNotify } from 'apiManager/apiErrorHandler';
import { DOMUSTUDIOAPI_BASE_PATH } from 'config/api';

export const checkIsLogged = async (accessToken: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.GET,
    headerConfig: { accessToken }
  };
  const url = '/user/islogged';
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<AccountData>(obj);
};

export const invalidAccessToken = async (accessToken: string, refreshToken: string) => {
  const response = await fetch(`${DOMUSTUDIOAPI_BASE_PATH}/user/logout`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({ refreshToken })
  });
  return response;
};

export const passwordRecovery = async (email: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidAuthorization: true, avoidArchiveId: true },
    justResponse: true
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ email }) };
  const url = '/user/passwordRecovery';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const result = await APIManager<Response>(obj);
  return result;
};

export const getNewAccessTokenByRefreshToken = async (refreshToken: string): Promise<string | undefined> => {
  const response = await fetch(`${DOMUSTUDIOAPI_BASE_PATH}/user/refresh`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(refreshToken)
  });

  if (response.ok) {
    let result = undefined;
    try {
      result = ((await response.json()) as AccessToken)?.accessToken;
    } catch (e) {
      genericAPIErrorNotify();
      return;
    } finally {
      return result;
    }
  }
  return undefined;
};

export const loginByUrlToken = async (token: string, sessionID: string) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidAuthorization: true, avoidArchiveId: true }
  };
  const queryString = new URLSearchParams(sessionID ? { portalToken: token, sessionID } : { portalToken: token });
  const url = `/user/unattended?${queryString}`;
  const obj: APIRequestObj = { url, requestConfig };
  const result = await APIManager<FullCredential>(obj);
  return result;
};

export const internalLogin = async (credential: LoginCredential) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidAuthorization: true, avoidArchiveId: true }
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify(credential) };
  const url = '/user/login';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const result = await APIManager<FullCredential>(obj);
  return result;
};

export const mobileLogin = async (credential: LoginCredential) => {
  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidAuthorization: true, avoidArchiveId: true }
  };
  const additionalOpts: GretchOptions = { body: JSON.stringify(credential) };
  const url = '/user/loginMobile';
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const result = await APIManager<MobileLoginData>(obj);
  return result;
};
