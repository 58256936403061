import { PageEmptySet, Pagination } from '@fattureincloud/fic-design-system';
import { api } from 'api';
import { CommonViewProps } from 'components/dossiers/types';
import LoadingSpinner from 'design-component/loadingSpinner';
import { withAPILoading, WithAPILoadingInjectedProps } from 'HOC/withAPILoading';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Dossier } from 'types/dossier';
import { DossierContext, TDossierContext } from '../dossierContext';
import { List } from './list';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import style from '../style.module.scss';
import { PaginationProps } from '@fattureincloud/fic-design-system/dist/components/pagination/types';

export const DOSSIER_PER_PAGE = 10;
type Pagination = Pick<PaginationProps, 'currentPage' | 'listSize'>;
type Props = CommonViewProps & WithAPILoadingInjectedProps & Required<Pick<TDossierContext, 'currentOrigin'>>;

const DossierListImpl: FC<Props> = (props) => {
  const [fetchedData, setFetchedData] = useState<Dossier[] | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    listSize: props.currentOrigin.numeroAllegati
  });
  const { setCurrentOrigin } = useContext(DossierContext);
  const { handleAPICall, data, currentOrigin, isLoading } = props;
  const { id, type } = data;

  const getData = useCallback(async () => {
    const call = () => api.attachment.getDossiers(id, type, currentOrigin.tipoOrigine, pagination.currentPage);
    const { data } = await handleAPICall(call);
    setFetchedData(data === undefined ? [] : data);
  }, [currentOrigin.tipoOrigine, handleAPICall, id, pagination.currentPage, type]);

  useEffect(() => {
    getData();
  }, [getData]);

  // Pagination
  const handleChangePage = async (page: number) => {
    setPagination({ ...pagination, currentPage: page });
    await getData();
  };

  const handleChangePageDirection = (direction: 'next' | 'prev') => {
    const { currentPage } = pagination;
    const newPageNumber = direction === 'next' ? currentPage + 1 : currentPage - 1;
    handleChangePage(newPageNumber);
  };

  const isEmptyFetchData = Boolean(fetchedData && !fetchedData.length);
  return (
    <div className="pos-r d-flex flex-column h-100">
      {isLoading ? (
        <LoadingSpinner position="absolute" size="medium" transparentBackground />
      ) : isEmptyFetchData ? (
        <div className={style['page-empty__container']}>
          <PageEmptySet
            icon={{ icon: faFolder }}
            title="L’origine selezionata non ha fascicoli"
            action={{ text: 'Ritorna alla lista origini', action: () => setCurrentOrigin(undefined) }}
          />
        </div>
      ) : fetchedData ? (
        <>
          <List data={fetchedData} page={pagination.currentPage} />
          <div className="mt-auto mx-auto pb-2">
            <Pagination
              currentPage={pagination.currentPage}
              listSize={pagination.listSize}
              pageSize={DOSSIER_PER_PAGE}
              changePageAction={handleChangePage}
              nextPageAction={() => handleChangePageDirection('next')}
              prevPageAction={() => handleChangePageDirection('prev')}
              prevText="Indietro"
              nextText="Avanti"
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export const DossierList = withAPILoading(DossierListImpl);
