import { GretchOptions } from 'gretchen';
import { APIRequestConfig } from 'apiManager/types';
import { DOMUSTUDIOAPI_BASE_PATH, getAuthorizationHeader } from './api';

// If headerConfig is undefined it picks all data from redux
export const getGretchOptions = (config: APIRequestConfig): GretchOptions => ({
  baseURL: DOMUSTUDIOAPI_BASE_PATH,
  method: config.method,
  credentials: 'include',
  headers: getAuthorizationHeader(config.headerConfig ?? {}),
  timeout: 120000, // 120 sec
  hooks: undefined
});
