import { APIManager } from 'apiManager';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'apiManager/types';
import { GretchOptions } from 'gretchen';
import { AssicurazioneCondomino, CreaAssicurazioneCondomino } from 'types/condominio';

export const getList = async (condGenID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/assicurazione`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<AssicurazioneCondomino[]>(obj);
};

export const getDetails = async (condGenID: number, assicurazioneID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET };
  const url = `/api/condominio/${condGenID}/assicurazione/${assicurazioneID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<AssicurazioneCondomino>(obj);
};

export const add = async (condGenID: number, assicurazione: CreaAssicurazioneCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST };
  const additionalOpts: GretchOptions = { body: JSON.stringify(assicurazione) };
  const url = `/api/condominio/${condGenID}/assicurazione`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<AssicurazioneCondomino>(obj);
};

export const edit = async (condGenID: number, assicurazioneID: number, assicurazione: CreaAssicurazioneCondomino) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT };
  const additionalOpts: GretchOptions = { body: JSON.stringify(assicurazione) };
  const url = `/api/condominio/${condGenID}/assicurazione/${assicurazioneID}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<AssicurazioneCondomino>(obj);
};

export const remove = async (condGenID: number, assicurazioneID: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.DELETE, justResponse: true };
  const url = `/api/condominio/${condGenID}/assicurazione/${assicurazioneID}`;
  const obj: APIRequestObj = { url, requestConfig };
  return await APIManager<Response>(obj);
};
