import { api } from 'api';
import { APICall } from 'HOC/withAPILoading';
import { translate } from 'translations';
import { Id } from 'types';
import { showErrorToast } from './toast';
import { store } from 'router';
import { openAttachmentPreviewModalAction } from 'actions/modals';
import { IS_NATIVE_APP } from './mobile';
import { AppFeaturesListEnum, RNFileDowloadRequest } from 'types/native';
import { getFeatures, reactNativePostMessage } from './native';

export const isValidHttpsUrl = (value: string) => {
  let url;
  try {
    url = new URL(value);
  } catch (e) {
    return false;
  }
  return url.protocol === 'https:';
};

export const showAttachmentPreview = async (id: Id['id'], downloadKey: string, handleAPICall: APICall) => {
  const call = () => api.attachment.getPreviewLink(id, downloadKey, false);
  const { data } = await handleAPICall(call);
  if (data) {
    if (IS_NATIVE_APP) {
      const features = getFeatures();
      if (features?.[AppFeaturesListEnum.attachmentsWithLinking]) {
        reactNativePostMessage({ attachmentURL: data.link });
      } else {
        store.dispatch(openAttachmentPreviewModalAction(data));
      }
    } else {
      window.open(data.link, '_blank');
    }
  } else {
    showErrorToast(translate('toastErrorPreviewAttachment'));
  }
};

/**
 * Extension file
 */
export const WORD_DOC_EXTENSIONS = ['.docx', '.docm', '.dotx', 'dotm', 'docb', '.doc', '.dot', '.wbk'];
export const EXCEL_DOC_EXTENSIONS = [
  '.xlsx',
  '.xlsm',
  '.xlsb',
  '.xltx',
  '.xltm',
  '.xls',
  '.xlt',
  '.xml',
  '.xlam',
  '.xla',
  '.xlw',
  '.xlr'
];
export const POWER_POINT_DOC_EXTENSIONS = [
  '.pptx',
  '.pptm',
  '.ppt',
  '.potx',
  '.potm',
  '.pot',
  '.ppsx',
  '.ppsm',
  '.pps',
  '.ppam',
  '.ppa'
];

export const PDF_DOC_EXTENSION = ['.pdf'];
export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.bmp', '.tif', '.tiff'];
export const HTML_EXTENSIONS = ['.htm', '.html'];

export function downloadURI(url: string, filename: string) {
  const features = getFeatures();
  if (features?.[AppFeaturesListEnum.downloadAttachment]) {
    const data: RNFileDowloadRequest = { url, filename };
    reactNativePostMessage({ fileDowloadRequest: data });
  } else {
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  }
}
