import { InputHelperPalette } from '@fattureincloud/fic-design-system';
import { colors as defaultPalette } from 'palette/colors';
import { faCheckCircle, faExclamationTriangle, faInfoCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

const inputHelperPalette: InputHelperPalette = {
  normal: {
    color: defaultPalette.black[100],
    background: defaultPalette.grey[100],
    defaultIcon: null
  },
  error: {
    color: defaultPalette.red[900],
    background: defaultPalette.red[50],
    defaultIcon: { icon: faTimesCircle }
  },
  warning: {
    color: defaultPalette.yellow[900],
    background: defaultPalette.yellow[50],
    defaultIcon: { icon: faExclamationTriangle }
  },
  info: {
    color: defaultPalette.blue[900],
    background: defaultPalette.blue[50],
    defaultIcon: { icon: faInfoCircle }
  },
  success: {
    color: defaultPalette.green[900],
    background: defaultPalette.green[50],
    defaultIcon: { icon: faCheckCircle }
  }
};

export default inputHelperPalette;
