import { Logout } from 'actions/auth/types';
import { ResetState } from 'actions/general/types';
import { EntityFilters, BaseItem } from 'types';
import { CondominioBase } from 'types/condominio';

export enum CondominioActionNames {
  SET_CURRENT_CONDOMINIO = 'SET_CURRENT_CONDOMINIO',
  SET_CURRENT_ESERCIZIO = 'SET_CURRENT_ESERCIZIO',
  SET_FILTERS = 'SET_FILTERS'
}

export type SetCurrentCondominio = {
  type: typeof CondominioActionNames.SET_CURRENT_CONDOMINIO;
  payload: CondominioBase | undefined;
};

export type SetCurrentEsercizio = {
  type: typeof CondominioActionNames.SET_CURRENT_ESERCIZIO;
  payload: BaseItem | undefined;
};

export type SetCondominioFilters = {
  type: typeof CondominioActionNames.SET_FILTERS;
  payload: EntityFilters;
};

/**
 * Action output typings aggregator
 */
export type CondominiTypes = ResetState | Logout | SetCurrentCondominio | SetCurrentEsercizio | SetCondominioFilters;
